import React, {
  Dispatch,
  SetStateAction,
  ChangeEvent,
  MouseEvent,
  MouseEventHandler,
  useMemo,
  useState,
  useCallback,
} from "react";
import styled from "@emotion/styled";
import debounce from "lodash.debounce";
import { darken, rgba } from "polished";
import {
  Box,
  Button,
  TableContainer,
  Table as MuiTable,
  Toolbar,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Collapse,
  MenuList,
  Divider,
  Grid,
} from "@mui/material";
import { ViewWeek as ViewWeekIcon } from "@mui/icons-material";
import {
  Download as ExportIcon,
  Search as SearchIcon,
  Trash2 as DeleteIcon,
  FileText as FileIcon,
} from "react-feather";
import moment from "moment";
import PanelTrack from "./PanelTrack";

import LightTooltip from "../../../components/LightTooltip";

import { TableProps, TableColumn, ColumnConfig, ExportDataConfig, TableExpandProps } from "../../../components/table";

import { DateRangePicker } from "../../../components/DateRangePicker";

import { formatDateISO } from "../../../utils";

import API from "../../../utils/API";

import { useApp } from "../../../contexts/AppContext";

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 100%;
`;

const ExpandTableCell = styled(TableCell)`
  background: ${(props) => rgba(props.theme.palette.primary.main, 0.125)};
  padding: 0;
`;

const Search = styled.div`
  border-radius: 4px;
  background-color: ${(props) => props.theme.header.background};
  display: none;
  position: relative;
  width: 200px;
  margin-right: 8px;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;

const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)};
    padding-right: ${(props) => props.theme.spacing(2.5)};
    padding-bottom: ${(props) => props.theme.spacing(2.5)};
    padding-left: ${(props) => props.theme.spacing(12)};
    width: 160px;
  }
`;

const ButtonAction = styled(Button)`
  /* background-color: ${(props) => (props.color === "primary" ? props.theme.palette.primary.light : "")}; */
  margin: 0 2px;
  box-shadow: none;
  min-width: 0;
  padding: 5px;
`;

export const getCellContent = (row: Record<string, any>, column: TableColumn) => {
  var obj: any;
  if (column.dataIndex) {
    if (typeof column.dataIndex === "string") {
      obj = row[column.dataIndex];
    } else {
      obj = row;
      for (let i = 0; i < column.dataIndex.length; i++) {
        if (!obj) {
          obj = "";
          break;
        }
        obj = obj[column.dataIndex[i]];
      }
    }
  } else {
    obj = row[column.id];
  }

  if (column.render) {
    return column.render(obj, row);
  } else {
    return obj;
  }
};

interface TableSOProps extends TableProps {
  setData: Dispatch<SetStateAction<Record<string, any>[]>>;
  // params: any;
  // setParams: any;
}

const Table: React.FC<TableSOProps> = ({
  data,
  setData,
  columnConfig,
  subTableColumnConfig,
  exportData,
  exportMultipleFormat,
  title,
  loading,
  expand,
  size,
  hover,
  dateRangeChanged,
  searchKeywords,
  onKeywordsChanged,
  toolButtons,
}) => {
  const [filterAnchorEl, setFilterAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [exportAnchorEl, setExportAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [openDate, setOpenDate] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<{ startDate: Date | null; endDate: Date | null; label?: string }>({
    startDate: null,
    endDate: null,
    label: "",
  });
  const XLSX = window.XLSX;
  const toggleFilter: MouseEventHandler = (e: MouseEvent<HTMLButtonElement>) => {
    setFilterAnchorEl(e.currentTarget);
  };

  const closeFilter = () => {
    setFilterAnchorEl(null);
  };

  const closeExportMenu = () => {
    setExportAnchorEl(null);
  };

  const checkboxChangedHandler =
    (columnConfig: ColumnConfig, displayedColumn: TableColumn[]) => (event: ChangeEvent<HTMLInputElement>) => {
      if (columnConfig.handleColumnSettingChanged) {
        var setting: string[] = displayedColumn
          .filter((c) => columnConfig.availableColumn.includes(c))
          .map((c) => c.id);
        if (event.target.checked) {
          if (!setting.includes(event.target.name)) {
            setting.push(event.target.name);
          }
        } else {
          setting = setting.filter((c) => c !== event.target.name);
        }
        columnConfig.handleColumnSettingChanged(setting);
      }
    };

  const getDisplayColumn = (columnConfig: ColumnConfig) => {
    let setting =
      columnConfig.columnSetting && columnConfig.columnSetting.length > 0
        ? columnConfig.columnSetting
        : columnConfig.defaultSetting;
    let resColumn = columnConfig.availableColumn.filter((col) => col.id && setting.includes(col.id));

    if (columnConfig.postFixedColumn) {
      resColumn = resColumn.concat(columnConfig.postFixedColumn);
    }
    return resColumn;
  };

  const displayedColumn: TableColumn[] = useMemo(() => getDisplayColumn(columnConfig), [columnConfig.columnSetting]);

  const subTableDisplayedColumn: TableColumn[] = useMemo(
    () => (subTableColumnConfig ? getDisplayColumn(subTableColumnConfig) : []),
    [subTableColumnConfig]
  );

  const handleExportClick: MouseEventHandler = (e: MouseEvent<HTMLButtonElement>) => {
    if (exportData) {
      ExportXLSX(exportData, Boolean(subTableColumnConfig), title || "");
    } else if (exportMultipleFormat) {
      setExportAnchorEl(e.currentTarget);
    }
  };

  const handleExportMenuClick = (expConf: ExportDataConfig) => () => {
    ExportXLSX(expConf.getData, expConf.includeSubTableColumn, title + " " + expConf.menuTitle);
    setExportAnchorEl(null);
  };

  const ExportXLSX = (
    getData: () => Promise<Record<string, any>[]>,
    includeSubTableColumn: boolean,
    filename: string
  ) => {
    if (!getData) {
      return;
    }
    getData()
      .then((dataList) => {
        const xlsxData: string[][] = [];

        const doIncludeSubTable = includeSubTableColumn && subTableColumnConfig;

        // sheet column headers
        var xlsxRow: string[] = [];
        columnConfig.availableColumn.forEach((column) => {
          let xlsxHeader = typeof column.header === "string" ? column.header : column.id;
          xlsxRow.push(xlsxHeader);
        });
        if (doIncludeSubTable) {
          subTableColumnConfig.availableColumn.forEach((column) => {
            let xlsxHeader = typeof column.header === "string" ? column.header : column.id;
            xlsxRow.push(xlsxHeader);
          });
        }
        xlsxData.push(xlsxRow);

        // sheet content
        dataList.forEach((obj) => {
          const xlsxRow: string[] = [];
          columnConfig.availableColumn.forEach((column) => {
            let cellContent = column.exportFn ? column.exportFn(obj) : getCellContent(obj, column);
            xlsxRow.push(cellContent);
          });

          if (doIncludeSubTable) {
            (obj[subTableColumnConfig.subDataIndex] as Record<string, any>[]).forEach((Detailobj) => {
              let xlsxDetailRow = [...xlsxRow];
              subTableColumnConfig.availableColumn.forEach((column) => {
                let cellContent = column.exportFn ? column.exportFn(Detailobj) : getCellContent(Detailobj, column);
                xlsxDetailRow.push(cellContent);
              });
              xlsxData.push(xlsxDetailRow);
            });
          }

          if (!doIncludeSubTable) {
            xlsxData.push(xlsxRow);
          }
        });
        var worksheet = XLSX.utils.aoa_to_sheet(xlsxData);
        var new_workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(new_workbook, worksheet, "sheet1");
        XLSX.writeFile(new_workbook, filename + " " + moment().format("YYYYMMDD") + ".xlsx");
      })
      .catch((e) => {
        alert("An error has occured.");
      });
  };

  const debouncedChangeHandler = useCallback(
    debounce((e) => {
      onKeywordsChanged && onKeywordsChanged(e.target.value);
    }, 300),
    []
  );
  return (
    <Box p={2}>
      {(title || exportData || exportMultipleFormat || columnConfig.handleColumnSettingChanged) && (
        <Toolbar>
          {onKeywordsChanged && (
            <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
              <Search>
                <SearchIconWrapper>
                  <SearchIcon style={{ color: "#9e9e9e" }} />
                </SearchIconWrapper>
                <Input placeholder={searchKeywords?.join(", ")} onChange={debouncedChangeHandler} />
              </Search>
            </div>
          )}
          {dateRangeChanged && (
            <div>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => setOpenDate(true)}
                sx={{ minWidth: "210px", margin: 2 }}
              >
                {dateRange && dateRange.label ? (
                  dateRange.label
                ) : dateRange.startDate && dateRange.endDate ? (
                  <Grid container justifyContent="space-between">
                    <Grid item>{formatDateISO(dateRange.startDate)}</Grid>
                    <Grid item>to</Grid>
                    <Grid item>{formatDateISO(dateRange.endDate)}</Grid>
                  </Grid>
                ) : (
                  "All"
                )}
              </Button>
              <DateRangePicker
                open={openDate}
                toggle={() => setOpenDate(!openDate)}
                onChange={(range) => {
                  dateRangeChanged(range);
                  setDateRange(range);
                  setOpenDate(false);
                }}
              />
            </div>
          )}
          {toolButtons &&
            toolButtons.map((button, index) => (
              <Button
                key={`button_${button.label.replace(/\s/, "")}_${index}}`}
                sx={{ minWidth: "133px", margin: 2 }}
                variant="contained"
                startIcon={button.icon}
                onClick={button.onClick}
              >
                {button.label}
              </Button>
            ))}

          <Spacer />

          {(exportData || exportMultipleFormat) && (
            <React.Fragment>
              <LightTooltip title="Export" placement="top">
                <IconButton onClick={handleExportClick}>
                  <ExportIcon />
                </IconButton>
              </LightTooltip>
              <Menu anchorEl={exportAnchorEl} open={Boolean(exportAnchorEl)} onClose={closeExportMenu}>
                <MenuList>
                  {exportMultipleFormat &&
                    exportMultipleFormat.map((expConfig) => (
                      <MenuItem key={expConfig.menuTitle} onClick={handleExportMenuClick(expConfig)}>
                        {expConfig.menuTitle}
                      </MenuItem>
                    ))}
                </MenuList>
              </Menu>
            </React.Fragment>
          )}
          {columnConfig.handleColumnSettingChanged && (
            <React.Fragment>
              <LightTooltip title="Column Filter" placement="top">
                <IconButton onClick={toggleFilter}>
                  <ViewWeekIcon />
                </IconButton>
              </LightTooltip>
              <Menu anchorEl={filterAnchorEl} open={Boolean(filterAnchorEl)} onClose={closeFilter}>
                <MenuList>
                  {columnConfig.availableColumn.map((c, i) => (
                    <MenuItem key={c.id}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={displayedColumn.map((c) => c.id).includes(c.id)}
                            color="default"
                            onChange={checkboxChangedHandler(columnConfig, displayedColumn)}
                            name={c.id}
                          />
                        }
                        label={c.header || c.id}
                      />
                    </MenuItem>
                  ))}
                </MenuList>
                {subTableColumnConfig && <Divider />}
                {subTableColumnConfig && (
                  <MenuList>
                    {subTableColumnConfig.availableColumn.map((c, i) => (
                      <MenuItem key={c.id}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={subTableDisplayedColumn.map((c) => c.id).includes(c.id)}
                              color="default"
                              onChange={checkboxChangedHandler(subTableColumnConfig, subTableDisplayedColumn)}
                              name={c.id}
                            />
                          }
                          label={c.header || c.id}
                        />
                      </MenuItem>
                    ))}
                  </MenuList>
                )}
              </Menu>
            </React.Fragment>
          )}
        </Toolbar>
      )}
      {loading ? (
        <LoaderContainer>
          <CircularProgress />
        </LoaderContainer>
      ) : (
        <TableContainer>
          <MuiTable aria-labelledby="tableTitle" size={size} aria-label="enhanced table">
            <TableHead>
              <TableRow>
                {displayedColumn.map((column) => (
                  <TableCell key={column.id} align={column.dataAlign}>
                    {column.header || column.dataIndex}
                  </TableCell>
                ))}
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {data.map((row, index) => (
                <Row
                  key={index}
                  row={row}
                  displayedColumn={displayedColumn}
                  hover={hover}
                  expand={expand}
                  setData={setData}
                />
              ))}
            </TableBody>
          </MuiTable>
        </TableContainer>
      )}
    </Box>
  );
};

const Row = ({
  row,
  displayedColumn,
  hover,
  expand,
  setData,
}: {
  row: Record<string, any>;
  displayedColumn: TableColumn[];
  hover: boolean | undefined;
  expand: React.FC<TableExpandProps> | undefined;
  setData: Dispatch<SetStateAction<Record<string, any>[]>>;
}) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const { setMessageBox, currentUser } = useApp();

  const Expand: React.FC<TableExpandProps> = (props) => {
    return expand ? expand(props) : <></>;
  };

  return (
    <React.Fragment>
      <TableRow
        hover={hover}
        onClick={(ev) => {
          console.log(ev);
          if (!expand) return;
          if (ev.target instanceof Element) {
            let target: Element | null = ev.target;
            while (target != null && target.nodeName !== "TD" && target.parentNode instanceof Element)
              target = target.parentNode;
            if (target && target.className && !/action-column/g.test(target.className)) {
              setOpen(!open);
            }
          }
        }}
      >
        {displayedColumn.map((column) => (
          <TableCell key={column.id} align={column.dataAlign} sx={{ ...column.style }}>
            {getCellContent(row, column)}
          </TableCell>
        ))}

        <TableCell className="action-column">
          {row.id_Status === 1 && (
            <LightTooltip title="Delete">
              <ButtonAction
                color="error"
                onClick={(ev) => {
                  ev.stopPropagation();
                  setMessageBox({
                    msg: `Delete ${row.SalesOrderId}?`,
                    title: "Warning",
                    open: true,
                    onConfirm: () => {
                      API.delete(`/SalesOrder/CancelTPLSalesOrderV2?OrderSN=${row.id}`).then((res) => {
                        if (res.data && res.data.Code === 0) {
                          setData((data) =>
                            data.map((e) => {
                              if (e.id === row.id) {
                                e.SalesOrderId = e.SalesOrderId + "_Cancel";
                                e.id_Status = -1;
                                e.Status = "Deleted";
                              }
                              return e;
                            })
                          );
                        } else {
                          console.log("delete sales order FAIL!");
                        }
                      });
                    },
                    confirmText: "Yes",
                    cancelText: "No",
                  });
                }}
              >
                <DeleteIcon size={22} />
              </ButtonAction>
            </LightTooltip>
          )}
          {row.id_Status >= 20 && (
            <LightTooltip title="Packing list">
              <ButtonAction
                onClick={() => {
                  window.open(API.API_URL + `/SalesOrder/GetPackingList/${row.id}?token=${currentUser?.Token}`);
                }}
              >
                <FileIcon size={22} />
              </ButtonAction>
            </LightTooltip>
          )}
        </TableCell>
      </TableRow>
      {expand && (
        <TableRow>
          <ExpandTableCell colSpan={displayedColumn.length + 1}>
            <Collapse in={open} timeout="auto" mountOnEnter unmountOnExit>
              {(row.DispatchTime || row.TransitTime || row.DeliveringTime || row.FinishTime) && (
                <PanelTrack row={row} />
              )}

              <Box sx={{ margin: 2 }}>
                <Expand row={row} />
              </Box>
            </Collapse>
          </ExpandTableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
};

export default Table;
