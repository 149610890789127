import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import {
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TextField as MuiTextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import LoadingButton from "@mui/lab/LoadingButton";
import { Upload as UploadIcon } from "react-feather";
import API from "../../../utils/API";
import { formatDateISO } from "../../../utils";

const TextField = styled(MuiTextField)`
  margin: 8px;
`;

type ModalUploadType = {
  open: boolean;
  onClose: (refresh: boolean) => void;
};

const ModalUpload = (props: ModalUploadType) => {
  const { open, onClose } = props;
  const [details, setDetails] = useState<Record<string, any>[]>([]);
  const [uploading, setUploading] = useState(false);
  const [errors, setErrors] = useState<Record<string, any>[]>([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [postData, setPostData] = useState<Record<string, any>>({});

  const handleFile = (ev: React.ChangeEvent<HTMLInputElement>) => {
    if (ev.target.files && ev.target.files[0]) {
      setUploading(true);
      const file = ev.target.files[0];
      API.uploadFileByMultipart(`/AdvancedShipmentNotice/ImportExcel?id_User=0`, file).then((res) => {
        setUploading(false);
        if (res.data.Code === 0 && res.data.Errors.length === 0) {
          setDetails(res.data.ASNList[0].Details);
          setPostData({ ETA: formatDateISO(res.data.ASNList[0].ETA), ASNNo: res.data.ASNList[0].ASNNo, Type: "" });
        } else {
          setErrors(res.data.Errors);
        }
      });
    }
  };

  const handleSubmit = (data: Record<string, any>) => {
    let submitErrors = [];

    if (!data.ASNNo || data.ASNNo.length === 0) {
      submitErrors.push({ Line: 0, ErrorCode: 991, ErrorMsgEn: "ASN No. is invalid." });
    }
    if (!/^\d{4}-[0,1]\d-[0,1,2,3]\d$/.test(data.ETA)) {
      submitErrors.push({ Line: 0, ErrorCode: 992, ErrorMsgEn: "ETA is invalid." });
    }

    if (!data.Type || data.Type.length === 0) {
      submitErrors.push({ Line: 0, ErrorCode: 993, ErrorMsgEn: "Container type is invalid." });
    }
    if (submitErrors.length > 0) {
      setErrors(submitErrors);
      return;
    }

    data.Details = details.map((e) => {
      return {
        WholesaleOrder: e.WholesaleOrder,
        id_Product: e.id_Product,
        Qty: e.Qty,
        LargeUnitQty: e.LargeUnitQty,
        Marks: e.Marks,
        PalletNo: e.PalletNo,
        PalletLargeUnitQty: e.PalletLargeUnitQty,
        Notes: e.Notes,
        BoxType: e.BoxType,
        BoxTypeQty: e.BoxTypeQty,
        RecommendWHCode: e.RecommendWHCode,
      };
    });
    setUploading(true);
    API.post(`/AdvancedShipmentNotice/Create`, data).then((res) => {
      setUploading(false);
      if (res.data.Code === 0) {
        handleClose(true);
      } else {
        // TODO Error handling
      }
    });
  };

  const handleClose = (refresh: boolean = false) => {
    setUploading(false);
    setDetails([]);
    setPostData({ ETA: "", ASNNo: "", Type: "", Notes: "" });
    setCurrentStep(0);
    onClose(refresh);
  };

  return (
    <>
      <Dialog open={open} onClose={() => handleClose()} maxWidth={details.length === 0 ? "sm" : "md"} fullWidth>
        <input
          id="input_file"
          type="file"
          onChange={handleFile}
          onClick={(ev: React.MouseEvent<HTMLInputElement>) => {
            (ev.target as HTMLInputElement).value = "";
          }}
          style={{ display: "none" }}
        />
        <DialogTitle>Upload Advanced Shipment Notice</DialogTitle>
        {currentStep === 1 ? (
          <StepTwo details={details} postData={postData} setPostData={setPostData} />
        ) : (
          <StepOne uploading={uploading} details={details} />
        )}

        {details.length > 0 && (
          <DialogActions sx={{ pl: 10 }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                if (currentStep === 1) {
                  setCurrentStep(0);
                } else {
                  setDetails([]);
                }
              }}
            >
              {currentStep === 1 ? "Previous" : "Start over"}
            </Button>
            <LoadingButton
              variant="contained"
              color="primary"
              loading={uploading}
              onClick={() => {
                if (currentStep === 1) {
                  handleSubmit(postData);
                } else {
                  setCurrentStep(1);
                }
              }}
            >
              {currentStep === 1 ? "Submit" : "Next"}
            </LoadingButton>
          </DialogActions>
        )}
      </Dialog>
      <Dialog open={errors.length > 0}>
        <DialogTitle>
          <Typography variant="h4">Errors</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="subtitle2" color="error" gutterBottom>
            There are errors in uploaded file. Please fix them and try again.
          </Typography>
          {errors.map((e) => (
            <Typography variant="body1" key={`error_${e.Line}_${e.ErrorCode}`}>
              {(e.Line > 0 ? `Line ${e.Line} -` : "") + `${e.ErrorMsgEn}` + (e.Extra ? ` - ${e.Extra}` : "")}
            </Typography>
          ))}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setErrors([])}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

type StepOneProps = {
  uploading: boolean;
  details: Record<string, any>[];
};

type StepTwoProps = {
  details: Record<string, any>[];
  postData: Record<string, any>;
  setPostData: (data: Record<string, any>) => void;
};

const StepOne = (props: StepOneProps) => {
  const { details, uploading } = props;
  return (
    <DialogContent
      sx={{
        overflowY: "hide",
        scrollbarGutter: "stable",
        "::-webkit-scrollbar": {
          width: 6,
          background: "rgba(33,33,33,0.1)",
        },
        "::-webkit-scrollbar-thumb": {
          borderRadius: "10px",
          background: (theme) => theme.palette.secondary.light,
        },
        ":hover": {
          overflowY: "auto",
        },
      }}
    >
      <Grid container spacing={3} justifyContent="center">
        <Grid item sx={{ textAlign: "center" }}>
          {details.length === 0 && (
            <>
              <LoadingButton
                variant="contained"
                startIcon={<UploadIcon />}
                loading={uploading}
                loadingPosition="start"
                onClick={(ev) => document.getElementById("input_file")?.click()}
              >
                Select file...
              </LoadingButton>
              <Grid item sx={{ textAlign: "center" }}>
                <Button href="/download/ASN_ImportTemplate_v20240807.xlsx">Download template</Button>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      {details.length > 0 && (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell align="left">SKU</TableCell>
                <TableCell align="left">Barcode</TableCell>
                <TableCell align="left">Name</TableCell>
                <TableCell align="center">Package</TableCell>
                <TableCell align="center">Qty</TableCell>
                <TableCell align="center">Pieces</TableCell>
                <TableCell align="center" sx={{ minWidth: "80px" }}>
                  Pallet No.
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {details.map((row) => (
                <TableRow
                  key={`${row.Product.SKU}_${row.PalletNo}`}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  hover
                >
                  <TableCell component="th" scope="row">
                    {row.Product.SKU}
                  </TableCell>
                  <TableCell align="left">{row.Product.Barcode}</TableCell>
                  <TableCell
                    align="left"
                    sx={{ maxWidth: 270, overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}
                  >
                    {row.Product.Name}
                  </TableCell>
                  <TableCell align="center">{row.BoxType}</TableCell>
                  <TableCell align="center">{row.BoxTypeQty}</TableCell>
                  <TableCell align="center">{row.Qty}</TableCell>
                  <TableCell align="center">{row.PalletNo}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </DialogContent>
  );
};

const StepTwo = (props: StepTwoProps) => {
  const { details, setPostData, postData } = props;
  const [errors, setErrors] = useState<Record<string, boolean>>({
    asn: false,
    eta: false,
    type: false,
  });

  useEffect(() => {
    setErrors({
      asn: postData.ASNNo && postData.ASNNo.length === 0,
      eta: !/^\d{4}-[0,1]\d-[0,1,2,3]\d$/.test(postData.ETA),
      type: !postData.Type || postData.Type.length === 0,
    });
  }, [postData]);

  return (
    <DialogContent>
      <Grid container justifyContent="center" spacing={5}>
        <Grid item sm={12} sx={{ textAlign: "center" }}>
          <Typography variant="subtitle2">Please input and confirm following details</Typography>
        </Grid>
        <Grid item sm={4}>
          <Card variant="outlined" sx={{ margin: "8px", width: "100%" }}>
            <CardContent>
              <Grid container>
                <Grid item xs={9}>
                  <Typography variant="body2" gutterBottom>
                    Total Pallets:
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body2" style={{ float: "right" }}>
                    {[...new Set(details.map((el) => el.PalletNo))].length}
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="body2" gutterBottom>
                    Total SKUs:
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body2" style={{ float: "right" }}>
                    {[...new Set(details.map((el) => el.SKU))].length}
                  </Typography>
                </Grid>

                <Grid item xs={9}>
                  <Typography variant="body2" gutterBottom>
                    Total Qty:
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body2" style={{ float: "right" }}>
                    {details.reduce((sum, el) => sum + parseInt(el.BoxTypeQty), 0)}
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="body2" gutterBottom>
                    Total Pieces:
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body2" style={{ float: "right" }}>
                    {details.reduce((sum, el) => sum + parseInt(el.Qty), 0)}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <TextField
            fullWidth
            type="text"
            autoComplete="new-password"
            value={postData.ASNNo}
            error={errors.asn}
            label="ASN No."
            onChange={(ev) => {
              setPostData((data: Record<string, any>) => {
                return { ...data, ASNNo: ev.target.value };
              });
            }}
          />
          <DatePicker
            label="ETA"
            value={postData.ETA}
            inputFormat={"yyyy-MM-DD"}
            onChange={(date) => {
              setPostData((data: Record<string, any>) => {
                return { ...data, ETA: date.format("YYYY-MM-DD") };
              });
            }}
            renderInput={(params) => <TextField fullWidth error={errors.eta} {...params} />}
          />
          <FormControl fullWidth sx={{ margin: "8px" }}>
            <InputLabel id="demo-select-small">Container Type</InputLabel>
            <Select
              fullWidth
              labelId="demo-select-small"
              error={errors.type}
              label="Container Type"
              onChange={(ev) =>
                setPostData((data: Record<string, any>) => {
                  return { ...data, Type: ev.target.value };
                })
              }
            >
              <MenuItem value={`20'`} sx={{ minWidth: "110px", textAlign: "center" }}>
                20'
              </MenuItem>
              <MenuItem value={`40'`} sx={{ minWidth: "110px", textAlign: "center" }}>
                40'
              </MenuItem>
              <MenuItem value={`Air Freight`} sx={{ minWidth: "110px", textAlign: "center" }}>
                Air Freight
              </MenuItem>
              <MenuItem value={`Stock Adjust`} sx={{ minWidth: "110px", textAlign: "center" }}>
                Stock Adjust
              </MenuItem>
              <MenuItem value={`Return`} sx={{ minWidth: "110px", textAlign: "center" }}>
                Return
              </MenuItem>
              <MenuItem value={`Outbound Cancel`} sx={{ minWidth: "110px", textAlign: "center" }}>
                Outbound Cancel
              </MenuItem>
              <MenuItem value={`Other`} sx={{ minWidth: "110px", textAlign: "center" }}>
                Other
              </MenuItem>
            </Select>
          </FormControl>
          <TextField
            fullWidth
            type="text"
            multiline
            rows={4}
            autoComplete="new-password"
            value={postData.Notes}
            label="Notes"
            onChange={(ev) => {
              setPostData((data: Record<string, any>) => {
                return { ...data, Notes: ev.target.value };
              });
            }}
          />
        </Grid>
      </Grid>
    </DialogContent>
  );
};

export default ModalUpload;
