import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  DialogTitle,
  Grid,
  Typography,
  InputBase,
  TablePagination,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { darken } from "polished";
import moment from "moment";
import LightTooltip from "../../../components/LightTooltip";
import ModalRowDetail from "./ModalRowDetail";
import API from "../../../utils/API";
import { QueryParams, TableColumn } from "../../../components/table";
import { Search as SearchIcon } from "react-feather";
import TableLocalDelivery from "../ldComponents/TableLocalDelivery";
import { AVAILABLE_COLUMN_CONSIGNMENT, DEFAULT_COLUMN_SETTING_CONSIGNMENT } from "./tableColumn";

export enum DynamicColumns {
  E = "E",
  Created = "Created",
  Confirmed = "Confirmed",
  Collected = "Collected",
  InTransit = "InTransit",
  OutForDelivery = "OutForDelivery",
  Delivered = "Delivered",
}

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 100%;
`;

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  // "&:last-child td, &:last-child th": {
  //   border: 0,
  // },
}));

const Search = styled.div`
  border-radius: 4px;
  background-color: ${(props) => props.theme.header.background};
  display: none;
  position: relative;
  width: 200px;
  margin-right: 8px;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;

const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)};
    padding-right: ${(props) => props.theme.spacing(2.5)};
    padding-bottom: ${(props) => props.theme.spacing(2.5)};
    padding-left: ${(props) => props.theme.spacing(12)};
    width: 160px;
  }
`;

interface ModalSearchOrderProps {
  open: boolean;
  setOpen: Function;
}
const ModalSearchOrder = (props: ModalSearchOrderProps) => {
  const { open, setOpen } = props;
  const [dataList, setDataList] = useState<Record<string, any>[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [totalCnt, setTotalCnt] = useState<number>(0);
  const [keyword, setKeyword] = useState("");
  const [params, setParams] = useState<QueryParams>({
    PageSize: "20",
    PageNum: "1", //the page sequence number
    id_Status: "0",
  });

  const getDataList = () => {
    setLoading(true);
    API.get("/DeliveryOrder/QueryOrder", {
      ...params,
    })
      .then((res) => {
        setDataList(res.data.OrderList);
        setTotalCnt(res.data.TotalCnt);
      })
      .finally(() => setLoading(false));
  };

  const onSearchBtnClick = () => {
    setParams((param) => ({
      ...param,
      PageNum: "1",
      Keyword: keyword,
    }));
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(getDataList, [params]);

  return (
    <>
      <Dialog open={open} onClose={() => handleClose()} scroll="paper" fullScreen>
        <DialogTitle sx={{ margin: "auto", width: "100%" }}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h3" sx={{ display: "inline-block" }}>
                Search All Orders
              </Typography>
              &nbsp;&nbsp;&nbsp;
              <div style={{ display: "inline-block", justifyContent: "flex-start", alignItems: "center" }}>
                <Search>
                  <SearchIconWrapper>
                    <SearchIcon style={{ color: "#9e9e9e" }} />
                  </SearchIconWrapper>
                  <Input
                    placeholder="Order Id, Ref, Manifest"
                    value={keyword}
                    onChange={(e) => {
                      setKeyword(e.target.value);
                    }}
                    onKeyDown={(ev) => {
                      if (ev.key === "Enter") {
                        onSearchBtnClick();
                        ev.preventDefault();
                      }
                    }}
                  />
                </Search>
              </div>
              &nbsp;&nbsp;&nbsp;
              <Button variant="contained" color="secondary" onClick={onSearchBtnClick}>
                Search
              </Button>
            </Grid>
            <Grid item>
              <Button variant="outlined" color="primary" onClick={handleClose} sx={{ float: "right" }}>
                <CloseIcon />
              </Button>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <TableLocalDelivery
            data={dataList}
            setData={setDataList}
            columnConfig={{
              availableColumn: AVAILABLE_COLUMN_CONSIGNMENT,
              // columnSetting: uiSetting.columnConsignment,
              defaultSetting: DEFAULT_COLUMN_SETTING_CONSIGNMENT,
              // handleColumnSettingChanged: (setting) => {
              //   setUISetting({ columnConsignment: setting });
              // },
            }}
            title="Delivery Orders"
            loading={loading}
            // dateRangeChanged={dateRangeChanged}
            // searchKeywords={["Order Id, Ref, Manifest"]}
            // onKeywordsChanged={(keyword) =>
            //   setParams((param) => ({
            //     ...param,
            //     PageNum: "1",
            //     Keyword: keyword,
            //   }))
            // }
            params={params}
            setParams={setParams}
            showActionColumn={false}
            hover
          />
          <TablePagination
            rowsPerPageOptions={[20, 50, 100, 250]}
            component="div"
            count={totalCnt}
            rowsPerPage={parseInt(params["PageSize"] || "10")}
            page={parseInt(params["PageNum"] || "1") - 1}
            onPageChange={(event, page) =>
              setParams((params) => {
                return {
                  ...params,
                  PageNum: (page + 1).toString(),
                };
              })
            }
            onRowsPerPageChange={(event) =>
              setParams((params) => {
                return {
                  ...params,
                  PageSize: event.target.value,
                  PageNum: "1",
                };
              })
            }
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ModalSearchOrder;
