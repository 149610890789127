import { API_URL } from "../constants";

interface AueResponse extends Response {
  responseBody: string;
  data: any;
}

interface LoginCredential {
  memberId: string;
  password: string;
}

const signOut = () => {
  localStorage.removeItem("user");
  window.open("/auth/sign-in", "_self");
};

const getToken = () => {
  const auth = localStorage.getItem("user");
  if (auth === null) {
    return null;
  } else {
    const authObj = JSON.parse(auth);
    if (authObj) {
      return authObj.Token;
    }
  }
};

const get = (path: string, params?: Record<string, any> | null) => {
  let result: AueResponse;

  if (params && Object.keys(params).length > 0) {
    const paramsArr = Object.keys(params).map((key) => key + "=" + (params ? params[key] : ""));
    path = path + "?" + paramsArr.join("&");
  }
  return fetch(`${path}`, {
    method: "GET",
    // credentials: "include",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    }),
  })
    .then((res: Response) => {
      if (res.status === 401) {
        signOut();
      }
      result = { ...res, responseBody: "", data: {} };
      return res.text();
    })
    .then((data) => {
      result.responseBody = data;
      try {
        result.data = JSON.parse(data);
      } catch (error) {
        console.log("not JSON data, cannot parse");
        result.data = data;
      }
      return result;
    });
};

const getRaw = (path: string, params: Record<string, any> | undefined | null) => {
  if (params && Object.keys(params).length > 0) {
    const paramsArr = Object.keys(params).map((key) => key + "=" + (params ? params[key] : ""));
    path = path + "?" + paramsArr.join("&");
  }
  return fetch(`${path}`, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    }),
  });
};

const post = (path: string, data: any) => {
  let result: AueResponse;
  return fetch(`${path}`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    }),
  })
    .then((res) => {
      result = { ...res, responseBody: "", data: {} };
      return res.text();
    })
    .then((data) => {
      result.responseBody = data;
      result.data = JSON.parse(data);
      return result;
    });
};

const postraw = (path: string, data: any) => {
  return fetch(`${path}`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: new Headers({
      Accept: "*/*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    }),
  });
};

const put = (path: string, data: any) => {
  let result: AueResponse;
  return fetch(`${path}`, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    }),
  })
    .then((res) => {
      result = { ...res, responseBody: "", data: {} };
      return result.text();
    })
    .then((data) => {
      result.responseBody = data;
      result.data = JSON.parse(data);
      return result;
    });
};

const deleteM = (path: string) => {
  let result: AueResponse;
  return fetch(`${path}`, {
    method: "DELETE",
    // credentials: "include",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    }),
  })
    .then((res) => {
      result = { ...res, responseBody: "", data: {} };
      return res.text();
    })
    .then((data) => {
      result.responseBody = data;
      result.data = JSON.parse(data);
      return result;
    });
};

const login = (credentials: LoginCredential) => {
  let result: AueResponse;
  return fetch(`${API_URL}/UserLogin/Login`, {
    method: "POST",
    body: JSON.stringify({
      LoginID: credentials.memberId,
      Password: credentials.password,
    }),
    headers: new Headers({
      "Content-Type": "application/json",
    }),
  })
    .then((res) => {
      result = { ...res, responseBody: "", data: {} };
      return result.text();
    })
    .then((data) => {
      result.responseBody = data;
      result.data = JSON.parse(data);
      return result;
    });
};

const uploadFileByMultipart = (path: string, file: File) => {
  let result: AueResponse;
  const formData = new FormData();
  formData.append("file", file, file.name);
  return fetch(`${path}`, {
    method: "POST",
    body: formData,
    headers: new Headers({
      Authorization: "Bearer " + getToken(),
    }),
  })
    .then((res) => {
      result = { ...res, responseBody: "", data: {} };
      return res.text();
    })
    .then((data) => {
      result.responseBody = data;
      result.data = JSON.parse(data);
      return result;
    });
};

export const API = {
  get: (path: string, params: Record<string, any> | undefined | null) => get(`${API_URL}${path}`, params),
  getRaw: (path: string, params: Record<string, any> | undefined | null) => getRaw(`${API_URL}${path}`, params),
  post: (path: string, data: any) => post(`${API_URL}${path}`, data),
  postraw: (path: string, data: any) => postraw(`${API_URL}${path}`, data),
  put: (path: string, data: any) => put(`${API_URL}${path}`, data),
  delete: (path: string) => deleteM(`${API_URL}${path}`),
  login,
  uploadFileByMultipart: (path: string, file: File) => uploadFileByMultipart(`${API_URL}${path}`, file),
  API_URL,
};

export default API;
