import { useEffect, useState, useRef } from "react";
import styled from "@emotion/styled";
import { Box, CircularProgress, Collapse, Grid, TableRow, TableCell } from "@mui/material";

import StockCard from "../../../components/StockCard";

import { useApp } from "../../../contexts/AppContext";

import API from "../../../utils/API";

const CarouselBox = styled(Box)`
  display: flex;
  justify-content: start;
  overflow: hidden;
  position: relative;
  margin: auto;
  background-size: cover;
  transition: display 0.5s ease;
`;

const StockDetail = (props: { open: boolean; colSpan: number; productId: number }) => {
  const { open, colSpan, productId } = props;
  const { currentUser, setCategoryTransfer } = useApp();
  const ref = useRef(null);
  const [data, setData] = useState<Record<string, any>[]>([]);
  const [loading, setLoading] = useState(false);

  const [scrollAmount, setScrollAmount] = useState(0);
  const [carouselWidth, setCarouselWidth] = useState(0);
  const [hiddenRight, setHiddenRight] = useState(false);
  const scrollPerCLick = 400;

  const refreshStock = () => {
    setLoading(true);
    API.get("/Product/QueryStockWeb", { id_Product: productId }).then((res) => {
      setLoading(false);
      setData(res.data.StockList);
    });
  };

  useEffect(() => {
    if (open) refreshStock();
  }, [open]);

  return (
    <TableRow>
      <TableCell style={{ padding: open ? 10 : 0 }} colSpan={colSpan + 1}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          {loading ? (
            <div style={{ height: "50px", textAlign: "center" }}>
              <CircularProgress size={36} style={{ marginTop: "7px" }} />
            </div>
          ) : (
            <Grid container>
              <Grid item xs={12}>
                <CarouselBox ref={ref}>
                  {data.map((row) => (
                    <StockCard
                      key={`detail_${row.id}_${row.id_Category}`}
                      row={row}
                      onClick={() => {
                        if (currentUser && !currentUser.isMaster) return;
                        setCategoryTransfer({
                          open: true,
                          categories: [...data],
                          idFrom: row.id_Category,
                          idProduct: productId,
                          onConfirm: () => refreshStock(),
                        });
                      }}
                    />
                  ))}
                </CarouselBox>
              </Grid>
            </Grid>
          )}
        </Collapse>
      </TableCell>
    </TableRow>
  );
};

export default StockDetail;
