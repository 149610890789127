import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";

import { Alert as MuiAlert, Checkbox, FormControlLabel, Button, TextField as MuiTextField } from "@mui/material";
import { spacing } from "@mui/system";

import useAuth from "../../hooks/useAuth";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const SubmitButton = styled(Button)(spacing);

function SignIn() {
  const navigate = useNavigate();
  const { signIn } = useAuth();

  return (
    <Formik
      initialValues={{
        LoginID: "",
        Password: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        LoginID: Yup.string().max(255).required("Login ID is required"),
        Password: Yup.string().max(255).required("Password is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          let theUser = await signIn(values.LoginID, values.Password);
          let menuFlagArr = theUser?.MenuFlag.split("|");
          // WMS Dashboard: 1, TMS Dashboard:8, My Profile:7
          if (menuFlagArr?.includes("1")) {
            navigate("/dashboard");
          } else if (menuFlagArr?.includes("8")) {
            navigate("/tdashboard");
          } else if (menuFlagArr?.includes("7")) {
            navigate("/profile");
          } else {
            navigate("/");
          }
        } catch (error: any) {
          const message = error || "Something went wrong";

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={3} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <TextField
            name="LoginID"
            label="Login ID"
            value={values.LoginID}
            error={Boolean(touched.LoginID && errors.LoginID)}
            fullWidth
            helperText={touched.LoginID && errors.LoginID}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
          />
          <TextField
            type="password"
            name="Password"
            label="Password"
            value={values.Password}
            error={Boolean(touched.Password && errors.Password)}
            fullWidth
            helperText={touched.Password && errors.Password}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            // mt={4}
          >
            Sign in
          </Button>
        </form>
      )}
    </Formik>
  );
}

export default SignIn;
