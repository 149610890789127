import React, { useEffect, useState } from "react";
import moment from "moment";
import { Helmet } from "react-helmet-async";
import styled from "@emotion/styled";
import { styled as styled2, alpha } from "@mui/material/styles";
import { spacing } from "@mui/system";
import {
  Divider as MuiDivider,
  Grid,
  Paper as MuiPaper,
  TablePagination,
  Typography,
  MenuProps,
  Menu,
  MenuItem,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import axiosInstance from "../../../utils/axios";
import API from "../../../utils/API";
import { formatDateISO } from "../../../utils";
import useAuth from "../../../hooks/useAuth";
import Table, { TableExpandProps } from "../../../components/table";
import { DateRangeSelectedRange } from "../../../components/DateRangePicker";
import {
  AVAILABLE_COLUMN_ASN,
  DEFAULT_COLUMN_SETTING_ASN,
  AVAILABLE_SUBTABLE_COLUMN_ASN,
  DEFAULT_SUBTABLE_COLUMN_SETTING_ASN,
} from "./tableColumn";
import { QueryParamsASN } from "../../../components/table";
import { Upload as UploadIcon } from "react-feather";
import ModalUpload from "./ModalUpload";
import { StatusObj, ASNType } from "../../../types/types";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const StyledMenu = styled2((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    // minWidth: 180,
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

const Expand: React.FC<TableExpandProps> = ({ row }) => {
  const [data, setData] = useState<Record<string, any>[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { uiSetting } = useAuth();

  const getData = () => {
    setLoading(true);
    API.post("/AdvancedShipmentNotice/Query", {
      includeDetails: true,
      SN: row.id,
    }).then((res) => {
      setLoading(false);
      setData(res.data.ASNList[0]?.Details || []);
    });
  };

  useEffect(getData, []);

  return (
    <Table
      data={data}
      size="small"
      columnConfig={{
        availableColumn: AVAILABLE_SUBTABLE_COLUMN_ASN,
        defaultSetting: DEFAULT_SUBTABLE_COLUMN_SETTING_ASN,
        columnSetting: uiSetting.columnAsnDetail,
      }}
      loading={loading}
    />
  );
};

function AdvancedShipmentNotice() {
  const { uiSetting, setUISetting } = useAuth();
  const [dataList, setDataList] = useState<Record<string, any>[]>([]);
  const [totalCnt, setTotalCnt] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [uploadOpen, setUploadOpen] = useState(false);
  const [params, setParams] = useState<QueryParamsASN>({
    PageSize: "20",
    PageNum: "1",
  });
  const [ASNTypes, setASNTypes] = useState<ASNType[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClickTypes = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseTypes = (ev: any, typeName?: string | null) => {
    setAnchorEl(null);
    if (typeName === null) return;
    setParams((param) => {
      return {
        ...param,
        PageNum: "",
        ASNType: typeName,
      };
    });
  };

  const [ASNStatusList, setASNStatusList] = useState<StatusObj[]>([]);
  const [anchorElStatus, setAnchorElStatus] = React.useState<null | HTMLElement>(null);
  const openStatus = Boolean(anchorElStatus);
  const handleClickStatus = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElStatus(event.currentTarget);
  };
  const handleCloseStatus = (e: any, statusId: number | null) => {
    setAnchorElStatus(null);
    if (statusId === null) return;
    setParams((param) => {
      return {
        ...param,
        PageNum: "",
        id_Status: statusId.toString(),
      };
    });
  };

  const getDataList = () => {
    setLoading(true);
    API.post("/AdvancedShipmentNotice/Query", {
      ...params,
      includeDetails: false,
    })
      .then((res) => {
        setDataList(res.data.ASNList);
        setTotalCnt(res.data.Total);
      })
      .finally(() => setLoading(false));
  };

  const exportData = () =>
    axiosInstance
      .post("/AdvancedShipmentNotice/Query", {
        ...params,
        includeDetails: true,
        PageSize: 100000,
        PageNum: 1,
      })
      .then((res) => {
        return res.data.ASNList as Record<string, any>[];
      });

  useEffect(() => {
    API.get("/AdvancedShipmentNotice/QueryASNType", null).then((res) => {
      setASNTypes(res.data.TypeList);
    });
    API.get("/Status/Query?statusType=5", null).then((res) => {
      setASNStatusList(res.data.StatusList);
    });
  }, []);

  useEffect(getDataList, [params]);

  const dateRangeChanged = (range: DateRangeSelectedRange) => {
    if (range.endDate && range.startDate) {
      setParams((params) => {
        return {
          ...params,
          PageNum: "",
          StartDate: formatDateISO(range.startDate),
          EndDate: formatDateISO(moment(range.endDate).add(1, "day").toDate()),
        };
      });
    } else {
      setParams((params) => {
        return {
          ...params,
          PageNum: "",
          StartDate: undefined,
          EndDate: undefined,
        };
      });
    }
  };

  return (
    <React.Fragment>
      <Helmet title="Advanced Shipment Notice" />
      <Grid justifyContent="space-between" container>
        <Grid item>
          <Typography variant="h3" gutterBottom>
            <Grid container>
              <Grid item sm={12}>
                Advanced Shipment Notice
              </Grid>
            </Grid>
          </Typography>
        </Grid>
      </Grid>

      <Divider my={3} />

      <Grid container spacing={6}>
        <Grid item sm={12}>
          <Paper>
            <Table
              data={dataList}
              columnConfig={{
                availableColumn: AVAILABLE_COLUMN_ASN,
                columnSetting: uiSetting.columnAsn,
                defaultSetting: DEFAULT_COLUMN_SETTING_ASN,
                handleColumnSettingChanged: (setting) => {
                  setUISetting({ columnAsn: setting });
                },
              }}
              subTableColumnConfig={{
                availableColumn: AVAILABLE_SUBTABLE_COLUMN_ASN,
                columnSetting: uiSetting.columnAsnDetail,
                defaultSetting: DEFAULT_SUBTABLE_COLUMN_SETTING_ASN,
                handleColumnSettingChanged: (setting) => {
                  setUISetting({ columnAsnDetail: setting });
                },
                subDataIndex: "Details",
              }}
              loading={loading}
              exportData={exportData}
              expand={Expand}
              toolButtons={[
                {
                  label: "Status",
                  icon: <KeyboardArrowDownIcon />,
                  onClick: handleClickStatus,
                },
                {
                  label: "Type",
                  icon: <KeyboardArrowDownIcon />,
                  onClick: handleClickTypes,
                },
                {
                  label: "Upload",
                  icon: <UploadIcon />,
                  onClick: (ev) => setUploadOpen(true),
                },
              ]}
              dateRangeChanged={dateRangeChanged}
              searchKeywords={["ANS No."]}
              onKeywordsChanged={(keyword) =>
                setParams((param) => {
                  return {
                    ...param,
                    PageNum: "",
                    ASNNo: keyword,
                  };
                })
              }
              hover
            />
            <TablePagination
              rowsPerPageOptions={[20, 50, 100, 250]}
              component="div"
              count={totalCnt}
              rowsPerPage={parseInt(params["PageSize"] || "10")}
              page={parseInt(params["PageNum"] || "1") - 1}
              onPageChange={(event, page) =>
                setParams((params) => {
                  return {
                    ...params,
                    PageNum: (page + 1).toString(),
                  };
                })
              }
              onRowsPerPageChange={(event) =>
                setParams((params) => {
                  return {
                    ...params,
                    PageSize: event.target.value,
                    PageNum: "1",
                  };
                })
              }
            />
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={(e) => handleCloseTypes(e, null)}
            >
              <MenuItem onClick={(e) => handleCloseTypes(e, "")} key={0} disableRipple>
                All
              </MenuItem>
              {ASNTypes.map((item) => {
                return (
                  <MenuItem onClick={(e) => handleCloseTypes(e, item.ASNType)} key={item.id} disableRipple>
                    {item.ASNType}
                  </MenuItem>
                );
              })}
            </StyledMenu>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorElStatus}
              open={openStatus}
              onClose={(e) => handleCloseStatus(e, null)}
            >
              <MenuItem onClick={(e) => handleCloseStatus(e, 0)} key={0}>
                All
              </MenuItem>
              {ASNStatusList.map((item) => {
                return (
                  <MenuItem onClick={(e) => handleCloseStatus(e, item.id)} key={item.id}>
                    {item.Status}
                  </MenuItem>
                );
              })}
            </StyledMenu>
          </Paper>
        </Grid>
      </Grid>
      <ModalUpload
        open={uploadOpen}
        onClose={(refresh: boolean = false) => {
          setUploadOpen(false);
          if (refresh) getDataList();
        }}
      />
    </React.Fragment>
  );
}

export default AdvancedShipmentNotice;
