import { Grid, Paper, Divider } from "@mui/material";
import { differenceInCalendarMonths } from "date-fns";
import Month from "./Month";
import DefinedRanges from "./DefinedRanges";
import { MARKERS } from "./Utils";

const Menu = (props) => {
  const {
    ranges,
    dateRange,
    minDate,
    maxDate,
    firstMonth,
    setFirstMonth,
    secondMonth,
    setSecondMonth,
    setDateRange,
    helpers,
    handlers,
    locale,
    hoverDay,
  } = props;
  const canNavigateCloser = differenceInCalendarMonths(secondMonth, firstMonth) >= 2;
  const commonProps = {
    dateRange,
    minDate,
    maxDate,
    helpers,
    handlers,
  };
  return (
    <Paper elevation={5}>
      <Grid container direction="row" wrap="nowrap">
        <Grid>
          <Grid container direction="row" justifyContent="center" wrap="nowrap" sx={{ margin: "15px 0" }}>
            <Month
              {...commonProps}
              hoverDay={hoverDay}
              value={firstMonth}
              setValue={setFirstMonth}
              navState={[true, canNavigateCloser]}
              marker={MARKERS.FIRST_MONTH}
              locale={locale}
            />
            <Divider flexItem orientation="vertical" />
            <Month
              {...commonProps}
              hoverDay={hoverDay}
              value={secondMonth}
              setValue={setSecondMonth}
              navState={[canNavigateCloser, true]}
              marker={MARKERS.SECOND_MONTH}
              locale={locale}
            />
          </Grid>
        </Grid>
        <Divider flexItem orientation="vertical" />
        <Grid>
          <DefinedRanges selectedRange={dateRange} ranges={ranges} setRange={setDateRange} locale={locale} />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Menu;
