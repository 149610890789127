import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import styled from "@emotion/styled";
import { Divider as MuiDivider, Grid, Link, Paper as MuiPaper, TablePagination, Typography } from "@mui/material";
import { spacing } from "@mui/system";
import Table from "./TableSOH";
import { AVAILABLE_COLUMN_SOH, DEFAULT_COLUMN_SETTING_SOH } from "./tableColumn";
import useAuth from "../../../hooks/useAuth";
import API from "../../../utils/API";
import Transaction from "./Transaction";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

function StockOnHand() {
  const { uiSetting, setUISetting } = useAuth();
  const [prodList, setProdList] = useState<Record<string, any>[]>([]);
  const [openedProduct, setOpenedProduct] = useState<number>(0);
  const [totalCnt, setTotalCnt] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [params, setParams] = useState({
    PageSize: "20",
    PageNum: "1",
    basicProduct: true,
    InStockOnly: true,
  });

  const getProdList = () => {
    setLoading(true);
    API.get("/Product/QueryProductWeb", params).then((res) => {
      setLoading(false);
      setProdList(res.data.ProductList);
      setTotalCnt(res.data.Total);
    });
  };

  const exportData = () => {
    return API.get("/Product/QueryProductWeb", {
      ...params,
      PageSize: 100000,
      PageNum: 1,
    }).then((res) => {
      return res.data.ProductList as Record<string, any>[];
    });
  };

  useEffect(getProdList, [params]);

  return (
    <React.Fragment>
      <Helmet title="Stock On Hand" />
      <Grid justifyContent="space-between" container>
        <Grid item>
          <Typography variant="h3" gutterBottom>
            <Grid container>
              <Grid item sm={12}>
                Stock On Hand
              </Grid>
            </Grid>
          </Typography>
        </Grid>
      </Grid>
      <Divider my={3} />
      <Grid container spacing={6}>
        <Grid item sm={12}>
          <Paper>
            <Table
              data={prodList}
              columnConfig={{
                availableColumn: AVAILABLE_COLUMN_SOH,
                columnSetting: uiSetting.columnSoh,
                handleColumnSettingChanged: (setting) => {
                  setUISetting({ columnSoh: setting });
                },
                defaultSetting: DEFAULT_COLUMN_SETTING_SOH,
              }}
              setOpenedProduct={setOpenedProduct}
              loading={loading}
              exportData={exportData}
              searchKeywords={["SKU", "Barcode", "Product", "IP"]}
              onKeywordsChanged={(keyword) => {
                setParams((params) => ({ ...params, PageNum: "", keyword }));
              }}
              inStockOnly={params.InStockOnly}
              changeStockHandler={(event) => {
                setParams((params) => ({ ...params, InStockOnly: event.target.checked }));
              }}
            />
            <TablePagination
              rowsPerPageOptions={[20, 50, 100, 250]}
              component="div"
              count={totalCnt}
              rowsPerPage={parseInt(params["PageSize"] || "10")}
              page={parseInt(params["PageNum"] || "1") - 1}
              onPageChange={(event, page) =>
                setParams({
                  ...params,
                  PageNum: (page + 1).toString(),
                })
              }
              onRowsPerPageChange={(event) =>
                setParams({
                  ...params,
                  PageSize: event.target.value,
                  PageNum: "1",
                })
              }
            />
          </Paper>
        </Grid>
      </Grid>
      <Transaction productId={openedProduct} onClose={() => setOpenedProduct(0)} />
    </React.Fragment>
  );
}

export default StockOnHand;
