import { SidebarItemsType } from "../../types/sidebar";

const WMSpagesSection = [
  {
    id: "1",
    href: "/dashboard",
    title: "Dashboard",
  },
  {
    id: "2",
    href: "/stock-on-hand?PageSize=10&PageNum=1&InStockOnly=true",
    title: "Stock On Hand",
  },
  {
    id: "3",
    href: "/asn?PageSize=10&PageNum=1",
    title: "ASN Mng",
    // visibilityControl: "ShowASN",
  },
  {
    id: "4", // hide this page in database Menuflag field
    href: "/in-order?PageSize=10&PageNum=1",
    title: "Inbound Mng",
    visibilityControl: "ShowInboundOrder",
  },
  {
    id: "5",
    href: "/out-req?PageSize=10&PageNum=1",
    title: "Sales Order Mng",
  },
  {
    href: "/out-order?PageSize=10&PageNum=1",
    title: "Outbound Details",
    visibilityControl: "ShowOutboundOrder",
  },
] as SidebarItemsType[];

const TMSpagesSection = [
  {
    id: "8",
    href: "/tdashboard",
    title: "Dashboard",
  },
  {
    id: "6",
    href: "/local-delivery",
    title: "Local Delivery",
  },
] as SidebarItemsType[];

const ACCOUNTpagesSection = [
  {
    id: "7",
    href: "/profile",
    title: "My Profile",
  },
] as SidebarItemsType[];

const navItems = [
  {
    title: "WMS",
    pages: WMSpagesSection,
  },
  {
    title: "TMS",
    pages: TMSpagesSection,
  },
  {
    title: "ACCOUNT",
    pages: ACCOUNTpagesSection,
  },
];

export default navItems;
