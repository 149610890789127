import { Grid, IconButton, TextField, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

export interface CargoItemType {
  SKU: string;
  Qty: number;
  StockQty: number;
  Name: string;
  NameEn: string;
  Barcode: string;
  IPName: string;
  Category: string;
  id_StockCategory: number;
}

interface ItemListRowProps {
  row: CargoItemType;
  length: number; //used to control the visibility of DeleteIcon
  WIDTHS: any;
  onDelete: React.MouseEventHandler<HTMLElement>;
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}
const ItemListRow = (props: ItemListRowProps) => {
  const { row, length, WIDTHS, onDelete, onChange } = props;

  return (
    <>
      <Grid item sx={{ width: WIDTHS.other }}>
        <TextField
          size="small"
          fullWidth
          inputProps={{ style: { textAlign: "center", WebkitAppearance: "none" } }}
          name="SKU"
          value={row.SKU}
          error={row.SKU.length === 0 ? true : false}
          onChange={onChange}
        />
      </Grid>
      <Grid item sx={{ width: WIDTHS.qty }}>
        <TextField
          type="number"
          size="small"
          fullWidth
          inputProps={{ style: { textAlign: "center", WebkitAppearance: "none" }, min: 0, max: row.StockQty }}
          name="Qty"
          value={row.Qty}
          error={row.Qty === 0 ? true : false}
          onChange={onChange}
        />
      </Grid>
      <Grid item>
        <TextField
          size="small"
          sx={{ backgroundColor: "lightgray", width: WIDTHS.qty }}
          value={row.StockQty}
          fullWidth
        />
      </Grid>
      <Grid item>
        <TextField size="small" sx={{ backgroundColor: "lightgray", width: WIDTHS.name }} value={row.Name} fullWidth />
      </Grid>
      <Grid item>
        <TextField
          size="small"
          sx={{ backgroundColor: "lightgray", width: WIDTHS.name }}
          value={row.NameEn}
          fullWidth
        />
      </Grid>
      <Grid item>
        <TextField
          size="small"
          sx={{ backgroundColor: "lightgray", width: WIDTHS.other }}
          value={row.Barcode}
          fullWidth
        />
      </Grid>
      <Grid item>
        <TextField
          size="small"
          sx={{ backgroundColor: "lightgray", width: WIDTHS.other }}
          value={row.IPName}
          fullWidth
        />
      </Grid>
      <Grid item>
        <TextField
          size="small"
          sx={{ backgroundColor: "lightgray", width: WIDTHS.other }}
          value={row.Category}
          fullWidth
        />
      </Grid>
      <Grid item>
        <IconButton disabled={length <= 1} color="error" onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
      </Grid>
      <Grid item xs={12}></Grid>
    </>
  );
};
export default ItemListRow;
