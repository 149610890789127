import React, { useState, createContext, useContext } from "react";

import { AuthUser } from "../types/auth";

export type MessageBoxType = {
  msg: string | null;
  title: string | null;
  open: boolean;
  onClose?: (() => void) | null;
  onConfirm?: (() => void) | null;
  onCancel?: (() => void) | null;
  confirmText?: string | null;
  cancelText: string | null;
  fullwidth?: boolean;
};

export type CategoryTransferType = {
  open: boolean;
  categories: Record<string, any>[];
  idFrom: number;
  idProduct: number;
  onClose?: (() => void) | null;
  onConfirm?: (() => void) | null;
  onCancel?: (() => void) | null;
};

const AppContext = createContext<{
  msgObj: MessageBoxType;
  setMessageBox: (props: MessageBoxType) => void;
  categoryTransfer: CategoryTransferType;
  setCategoryTransfer: (props: CategoryTransferType) => void;
  currentUser: AuthUser;
  setCurrentUser: (obj: AuthUser) => void;
}>({
  currentUser: null,
  setCurrentUser: (obj: AuthUser) => {},
  msgObj: {
    msg: null,
    title: null,
    open: false,
    onClose: null,
    onConfirm: null,
    onCancel: null,
    confirmText: null,
    cancelText: null,
    fullwidth: false,
  },
  setMessageBox: (props: MessageBoxType) => {},
  categoryTransfer: {
    open: false,
    categories: [],
    idFrom: -1,
    idProduct: 0,
    onClose: null,
    onConfirm: null,
    onCancel: null,
  },
  setCategoryTransfer: (props: CategoryTransferType) => {},
});

AppContext.displayName = "AppContext";

export const AppProvider = (props: { children: any }) => {
  const authStr = localStorage.getItem("auth");
  const [currentUser, _setCurrentUser] = useState(authStr != null ? JSON.parse(authStr) : {});

  const [msgObj, setMsgObj] = useState<MessageBoxType>({
    msg: null,
    title: null,
    open: false,
    onClose: null,
    onConfirm: null,
    onCancel: null,
    confirmText: null,
    cancelText: null,
    fullwidth: false,
  });

  const setMessageBox = (props: MessageBoxType) => {
    setMsgObj({
      ...{
        msg: null,
        title: null,
        open: false,
        onClose: null,
        onConfirm: null,
        onCancel: null,
        confirmText: null,
        cancelText: null,
        fullwidth: false,
      },
      ...props,
    });
  };

  const [categoryTransfer, setCategoryTransfer] = useState<CategoryTransferType>({
    open: false,
    categories: [],
    idFrom: -1,
    idProduct: 0,
    onClose: null,
    onConfirm: null,
    onCancel: null,
  });

  return (
    <AppContext.Provider
      children={props.children}
      value={{
        currentUser,
        setCurrentUser: (obj) => _setCurrentUser(obj || {}),
        msgObj,
        setMessageBox,
        categoryTransfer,
        setCategoryTransfer,
      }}
    />
  );
};

export const useApp = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useApp must be used in AppProvider");
  }
  return context;
};
