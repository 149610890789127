import React from "react";
import { matchPath } from "react-router-dom";

import { SidebarItemsType } from "../../types/sidebar";
import SidebarNavListItem from "./SidebarNavListItem";
import SidebarNavList from "./SidebarNavList";
// import { UserSetting } from "../../types/auth";

type ReduceChildRoutesProps = {
  items: JSX.Element[];
  page: SidebarItemsType;
  depth: number;
  currentRoute: string;
  user?: any;
};

const reduceChildRoutes = (props: ReduceChildRoutesProps) => {
  const { items, page, depth, currentRoute, user } = props;
  let menuFlag = user?.MenuFlag;
  let menuFlagArr = menuFlag?.split("|");
  if (!menuFlagArr?.includes(page.id)) {
    return items;
  }
  // old logic:
  // if (page.visibilityControl && user.UserSetting && !user.UserSetting[page.visibilityControl]) {
  //   return items;
  // }

  if (page.children) {
    const open = page.href
      ? !!matchPath(
          {
            path: page.href,
            end: false,
          },
          currentRoute
        )
      : false;

    items.push(
      <SidebarNavListItem
        depth={depth}
        icon={page.icon}
        key={page.title}
        badge={page.badge}
        open={!!open}
        title={page.title}
        href={page.href}
      >
        <SidebarNavList depth={depth + 1} pages={page.children} />
      </SidebarNavListItem>
    );
  } else {
    items.push(
      <SidebarNavListItem
        depth={depth}
        href={page.href}
        icon={page.icon}
        key={page.title}
        badge={page.badge}
        title={page.title}
        pageID={page.id}
      />
    );
  }

  return items;
};

export default reduceChildRoutes;
