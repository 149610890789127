import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField as MuiTextField,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
  FormLabel,
  FormControlLabel,
  Radio,
} from "@mui/material";
import API from "../../../utils/API";
import { useTheme } from "@mui/material/styles";
import ItemListRow, { CargoItemType } from "./ItemListRow";
import useAuth from "../../../hooks/useAuth";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import { Plus as AddIcon } from "react-feather";
const WIDTHS = {
  name: 350,
  qty: 100,
  other: 150,
  info1: 150,
  info2: 600,
  address: 474,
};

const TextField = styled(MuiTextField)`
  margin: 4px;
`;

interface ModalCreateProps {
  open: boolean;
  onClose: Function;
}
const ModalCreate = (props: ModalCreateProps) => {
  const { open, onClose } = props;
  const { user } = useAuth();
  const [error, setError] = useState<Record<string, any>>({});
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [salesOrderTypes, setSalesOrderTypes] = useState<Record<string, any>[]>([]);

  const [orderData, setOrderData] = useState<Record<string, any>>({
    SalesOrderId: "",
    id_User: user?.id_User,
    id_Type: 101,
    ReceiverName: "",
    ReceiverPhone: "",
    ReceiverProvince: "",
    ReceiverCity: "",
    ReceiverAddr: "",
    ReceiverPostCode: "",
    ReceiverCountry: "Australia",
    Notes: "",
    Details: [],
  });

  const [isAustralia, setIsAustralia] = useState(true);
  const handleCountryRadios = (event: React.ChangeEvent<HTMLInputElement>) => {
    if ((event.target as HTMLInputElement).value === "true") {
      setIsAustralia(true);
      setOrderData({ ...orderData, ReceiverCountry: "Australia" });
    } else {
      setIsAustralia(false);
      setOrderData({ ...orderData, ReceiverCountry: "" });
    }
  };

  const [id_StockCategory, setId_StockCategory] = useState(0);
  const [stockCategoryList, setStockCategoryList] = useState<Record<string, any>[]>([]);

  const theme = useTheme();

  useEffect(() => {
    API.get(`/Stock/QueryStockCategory`, null).then((res) => {
      if (res.data.Code === 0) {
        setStockCategoryList(res.data.ItemList);
        setId_StockCategory(res.data.ItemList[res.data.ItemList.length - 1].id);
      }
    });
    API.get(`/SalesOrder/QuerySalesOrderType`, null).then((res) => {
      if (res.data.Code === 0) {
        setSalesOrderTypes(res.data.TypeList);
      }
    });
  }, []);

  const [itemList, setItemList] = useState<CargoItemType[]>([
    {
      SKU: "",
      Qty: 0,
      StockQty: 0,
      Name: "",
      NameEn: "",
      Barcode: "",
      IPName: "",
      Category: "",
      id_StockCategory: id_StockCategory,
    },
  ]);

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = () => {
    let hasItemError = false;
    for (var item of itemList) {
      if (!item.SKU) {
        hasItemError = true;
        setErrorMsg("Please input SKU!");
        break;
      }
      if (item.Qty <= 0) {
        hasItemError = true;
        setErrorMsg("Qty must >= 1");
        break;
      }
      item.id_StockCategory = id_StockCategory;
    }
    if (hasItemError) {
      return;
    }

    orderData.Details = itemList;

    let valid = checkValid();
    if (!valid) {
      setErrorMsg("Please input correct information!");
      return;
    }

    if (orderData.DangerousGoods === "Y") {
      orderData.DangerousGoods = true;
    } else {
      orderData.DangerousGoods = false;
    }

    API.post("/SalesOrder/CreateTPLSalesOrderV2", orderData).then((res) => {
      if (res.data && res.data.Code === 0) {
        onClose(true);
      } else {
        setErrorMsg(res.data.Message);
      }
    });
  };

  // useEffect(() => {
  //   // Calculate Total
  //   let totalVolume: number = 0;
  //   let totalWeight: number = 0;
  //   let uniqueContents: string[] = [];
  //   itemList.forEach((item: CargoItemType) => {
  //     totalWeight = +totalWeight + +item.Weight;
  //     let itemVol = (item.Length * item.Width * item.Height) / 1000000;
  //     totalVolume = +totalVolume + +itemVol;
  //     if (!uniqueContents.includes(item.ItemContent)) {
  //       uniqueContents.push(item.ItemContent);
  //     }
  //   });
  //   setOrderData({
  //     ...orderData,
  //     TotalWeight: totalWeight,
  //     TotalVolume: totalVolume,
  //     TotalCnt: itemList.length,
  //     OrderContent: uniqueContents.join(","),
  //   });
  // }, [itemList]);

  const validationRules: Record<string, any> = {
    SalesOrderId: (v: string): boolean => v.trim().length > 0,
    ReceiverName: (v: string): boolean => v.trim().length > 0,
    // ReceiverPhone: (v: string): boolean => /^1\d{10}$/.test(v) || /^09\d{8}$/.test(v) || /^(\+|)852\d{8}$/.test(v),
    ReceiverPhone: (v: string): boolean => /^\d+$/.test(v),
    ReceiverCity: (v: string): boolean => v?.trim().length > 0,
    ReceiverProvince: (v: string): boolean => v?.trim().length > 0,
    ReceiverAddr: (v: string): boolean => v.trim().length > 0,
    ReceiverPostCode: (v: string): boolean => /^\d+$/.test(v) && v.trim().length === 4,
    ReceiverCountry: (v: string): boolean => v.trim().length > 0,
  };

  const checkValid = (key: any = null, value: any = null) => {
    if (key) {
      if (!validationRules[key]) return true;
      const valid = validationRules[key](value);
      setError({ ...error, [key]: !valid });
      return valid;
    } else {
      let tempError: Record<string, boolean> = {};
      let result = true;
      Object.keys(validationRules).forEach((key) => {
        const valid = validationRules[key](orderData[key]);
        tempError[key] = !valid;
        if (!valid) result = false;
      });
      setError(tempError);
      return result;
    }
  };

  const changeHandler = (ev: any) => {
    //React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    // console.log(ev.target.name, ev.target.value);
    setOrderData({ ...orderData, [ev.target.name]: ev.target.value });
    checkValid(ev.target.name, ev.target.value);
  };

  const id_StockCategoryChangeHandler = (ev: any) => {
    setId_StockCategory(ev.target.value);
  };

  const addItemRow = () => {
    setItemList((old) => {
      return [
        ...old,
        {
          SKU: "",
          Qty: 0,
          StockQty: 0,
          Name: "",
          NameEn: "",
          Barcode: "",
          IPName: "",
          Category: "",
          id_StockCategory: id_StockCategory,
        },
      ];
    });
  };

  const changeItemsHandler = async (ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    const key = ev.target.name;
    const value = ev.target.value;
    // console.log("index", index, "key", key, "value", value);
    if (!value && key.toUpperCase() === "SKU") {
      setErrorMsg(`${key} required!`);
      setItemList((itemList) =>
        itemList.map((oneItem: any, i) => {
          if (index === i) {
            oneItem[key] = value;
            oneItem["StockQty"] = 0;
            oneItem["Name"] = "";
            oneItem["NameEn"] = "";
            oneItem["Barcode"] = "";
            oneItem["IPName"] = "";
            oneItem["Category"] = "";
          }
          return oneItem;
        })
      );
      return;
    }

    if (key.toUpperCase() === "SKU") {
      var res = await API.get(`/Stock/QueryStock?SKU=${value}`, null);
      var Errors = res.data.Errors;
      if (Errors.length === 0) {
        setErrorMsg("");
        let product = res.data.ProductInfo;
        setItemList((itemList) =>
          itemList.map((oneItem: any, i) => {
            if (index === i) {
              oneItem[key] = value;
              oneItem["StockQty"] = res.data.TotalQty;
              oneItem["Name"] = product.Name;
              oneItem["NameEn"] = product.NameEn;
              oneItem["Barcode"] = product.Barcode;
              oneItem["IPName"] = product.IPName;
              oneItem["Category"] = product.Category;
            }
            return oneItem;
          })
        );
      } else {
        setErrorMsg(Errors[0].ErrorMsgEn);
        setItemList((itemList) =>
          itemList.map((oneItem: any, i) => {
            if (index === i) {
              oneItem[key] = value;
              oneItem["StockQty"] = 0;
              oneItem["Name"] = "";
              oneItem["NameEn"] = "";
              oneItem["Barcode"] = "";
              oneItem["IPName"] = "";
              oneItem["Category"] = "";
            }
            return oneItem;
          })
        );
      }
    } else {
      // key === "Qty"
      setItemList((itemList) =>
        itemList.map((oneItem: any, i) => {
          if (index === i) {
            if (value > oneItem["StockQty"]) {
              oneItem[key] = oneItem["StockQty"];
            } else if (Number(value) < 0) {
              oneItem[key] = 0;
            } else {
              oneItem[key] = value;
            }
          }
          return oneItem;
        })
      );
    }
  };

  const deleteItem = (index: number) => {
    setItemList((itemList) => itemList.filter((e, i) => i !== index));
  };

  return (
    <>
      <Dialog open={open} onClose={() => handleClose()} scroll="paper" fullScreen>
        <DialogTitle sx={{ margin: "auto", width: "100%" }}>
          Create a sales order
          <Button variant="outlined" color="primary" onClick={handleClose} sx={{ float: "right" }}>
            <CloseIcon />
          </Button>
        </DialogTitle>
        <DialogContent>
          <Typography variant="h2" textAlign="center" sx={{ color: "red" }} gutterBottom>
            {errorMsg}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </Typography>
          <Typography variant="h6" gutterBottom>
            Items:
          </Typography>
          <Grid container spacing={1} sx={{ textAlign: "center" }}>
            <Grid item sx={{ width: WIDTHS.other }}>
              SKU
            </Grid>
            <Grid item sx={{ width: WIDTHS.qty }}>
              Qty
            </Grid>
            <Grid item sx={{ width: WIDTHS.qty }}>
              Stock
            </Grid>
            <Grid item sx={{ width: WIDTHS.name }}>
              Name
            </Grid>
            <Grid item sx={{ width: WIDTHS.name }}>
              English Name
            </Grid>
            <Grid item sx={{ width: WIDTHS.other }}>
              Barcode
            </Grid>
            <Grid item sx={{ width: WIDTHS.other }}>
              IPName
            </Grid>
            <Grid item sx={{ width: WIDTHS.other }}>
              Category
            </Grid>
            <Grid item sx={{ marginLeft: 5 }}>
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={addItemRow}
                sx={{ float: "left" }}
                startIcon={<AddIcon />}
              >
                Item
              </Button>
            </Grid>
            <Grid item xs={12}></Grid>
            {itemList.map((item, index) => (
              <ItemListRow
                key={`itemList_${index}`}
                row={item}
                length={itemList.length}
                WIDTHS={WIDTHS}
                onChange={(ev) => changeItemsHandler(ev, index)}
                onDelete={(ev) => deleteItem(index)}
              />
            ))}

            {/* <Grid item xs={4} textAlign="left">
              Item Qty: {orderData.TotalCnt}
            </Grid> */}
            {/* <Grid item xs={4} textAlign="left">
              Total Volume(m<sup>3</sup>): {orderData.TotalVolume.toFixed(3)}
            </Grid>
            <Grid item xs={4} textAlign="left">
              Total Weight(kg): {orderData.TotalWeight}
            </Grid>
            <Grid item xs={12} textAlign="left">
              Consignment Content: {orderData.OrderContent}
            </Grid> */}
          </Grid>
          <Grid container spacing={1} sx={{ marginTop: `0`, marginBottom: `${theme.spacing(1)}` }}>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Sales Order Information:
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                size="small"
                fullWidth
                autoComplete="new-password"
                name="SalesOrderId"
                value={orderData.SalesOrderId}
                error={error.SalesOrderId}
                label="*Sales Order ID"
                sx={{ width: WIDTHS.info1 }}
                onChange={(ev) => {
                  changeHandler(ev);
                }}
              />
            </Grid>

            <Grid item xs={12}></Grid>
            <Grid item>
              <TextField
                size="small"
                fullWidth
                autoComplete="new-password"
                name="ReceiverName"
                value={orderData.ReceiverName}
                error={error.ReceiverName}
                label="*Receiver Name"
                sx={{ width: WIDTHS.other }}
                onChange={(ev) => {
                  changeHandler(ev);
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                size="small"
                fullWidth
                autoComplete="new-password"
                name="ReceiverPhone"
                value={orderData.ReceiverPhone}
                error={error.ReceiverPhone}
                label="*Receiver Phone"
                sx={{ width: WIDTHS.other }}
                onChange={(ev) => {
                  changeHandler(ev);
                }}
              />
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item>
              <TextField
                size="small"
                fullWidth
                autoComplete="new-password"
                name="ReceiverAddr"
                value={orderData.ReceiverAddr}
                error={error.ReceiverAddr}
                label="*Receiver Address"
                sx={{ width: WIDTHS.address }}
                onChange={changeHandler}
              />
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item>
              <TextField
                size="small"
                fullWidth
                autoComplete="new-password"
                name="ReceiverCity"
                value={orderData.ReceiverCity}
                error={error.ReceiverCity}
                label="*Receiver Suburb"
                sx={{ width: WIDTHS.other }}
                onChange={changeHandler}
              />
            </Grid>
            <Grid item>
              <FormControl
                sx={{ m: 1, minWidth: 120, width: WIDTHS.other }}
                size="small"
                error={error.ReceiverProvince}
                fullWidth
              >
                <InputLabel id="demo-select-small">*Receiver State</InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  name="ReceiverProvince"
                  value={orderData.ReceiverProvince}
                  label="Receiver Province"
                  onChange={changeHandler}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="ACT">ACT</MenuItem>
                  <MenuItem value="NSW">NSW</MenuItem>
                  <MenuItem value="NT">NT</MenuItem>
                  <MenuItem value="QLD">QLD</MenuItem>
                  <MenuItem value="SA">SA</MenuItem>
                  <MenuItem value="TAS">TAS</MenuItem>
                  <MenuItem value="VIC">VIC</MenuItem>
                  <MenuItem value="WA">WA</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <TextField
                size="small"
                fullWidth
                autoComplete="new-password"
                name="ReceiverPostCode"
                value={orderData.ReceiverPostCode}
                error={error.ReceiverPostCode}
                label="*Receiver PostCode"
                sx={{ width: WIDTHS.other }}
                onChange={changeHandler}
              />
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  <Typography variant="h6" gutterBottom color="black">
                    Receiver Country:
                  </Typography>
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="isAustralia"
                  value={isAustralia}
                  onChange={handleCountryRadios}
                >
                  <FormControlLabel value={true} control={<Radio />} label="Australia" />
                  <FormControlLabel value={false} control={<Radio />} label="Other country" />
                </RadioGroup>
              </FormControl>
              <TextField
                size="small"
                fullWidth
                autoComplete="new-password"
                name="ReceiverCountry"
                value={isAustralia ? "Australia" : orderData.ReceiverCountry}
                error={error.ReceiverCountry}
                // label="*Receiver Country"
                sx={{
                  width: WIDTHS.other,
                  position: "relative",
                  top: 23,
                  visibility: isAustralia ? "hidden" : "visible",
                }}
                onChange={changeHandler}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Stock Category:
              </Typography>
            </Grid>
            <Grid item>
              <FormControl
                sx={{ m: 1, minWidth: 120, width: WIDTHS.other }}
                size="small"
                error={error.id_StockCategory}
                fullWidth
              >
                {/* <InputLabel id="demo-select-small">*Stock Category</InputLabel> */}
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  name="id_StockCategory"
                  value={id_StockCategory}
                  // label="Stock Category"
                  onChange={id_StockCategoryChangeHandler}
                >
                  {stockCategoryList.map((c: any) => {
                    return <MenuItem value={c.id}>{c.Category}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Sales Order Type:
              </Typography>
            </Grid>
            <Grid item>
              <FormControl fullWidth sx={{ m: 1, minWidth: 120, width: WIDTHS.other }} size="small">
                <InputLabel id="select_type">Type</InputLabel>
                <Select
                  fullWidth
                  labelId="select_type"
                  label="Type"
                  name="id_Type"
                  value={orderData.id_Type}
                  onChange={changeHandler}
                >
                  {salesOrderTypes.map((type) => {
                    return (
                      <MenuItem value={type.id} sx={{ minWidth: "110px", textAlign: "center" }}>
                        {type.SOType}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Notes:
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                size="small"
                fullWidth
                multiline
                rows={2}
                autoComplete="new-password"
                name="Notes"
                value={orderData.Notes}
                error={error.Notes}
                // label="Notes"
                sx={{ width: WIDTHS.address }}
                onChange={changeHandler}
              />
            </Grid>
          </Grid>
          <br />
          <Button variant="contained" color="primary" onClick={handleClose}>
            CANCEL
          </Button>
          &nbsp;
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            SUBMIT
          </Button>
        </DialogContent>
        {/* <DialogActions sx={{ pl: 10 }}>
          <Button variant="outlined" color="primary" onClick={handleClose}>
            CANCEL
          </Button>
          <Button variant="outlined" color="primary" onClick={handleSubmit}>
            SUBMIT
          </Button>
        </DialogActions> */}
      </Dialog>
    </>
  );
};

export default ModalCreate;
