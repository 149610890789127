import { useState, useEffect, Dispatch, SetStateAction } from "react";
import { TableRow, TableCell } from "@mui/material";
import { TableColumn } from "../../../components/table";
import { getCellContent } from "../../../components/table/Table";
import StockDetail from "./StockDetail";

const Row = (props: {
  row: Record<string, any>;
  displayedColumn: TableColumn[];
  openId: number;
  setOpenId: Dispatch<SetStateAction<number>>;
}) => {
  const { row, displayedColumn, openId, setOpenId } = props;

  return (
    <>
      <TableRow
        hover
        onClick={(ev) => {
          let target = ev.target as Element | null;
          while (target instanceof Element && !target.classList.contains("MuiTableCell-root")) {
            if (target.classList.contains("preventRaise")) return;
            target = target.parentElement;
          }
          setOpenId(row.id === openId ? 0 : row.id);
        }}
      >
        {displayedColumn.map((column) => (
          <TableCell key={column.id} align={column.dataAlign} sx={{ ...column.style }}>
            {getCellContent(row, column)}
          </TableCell>
        ))}
      </TableRow>
      <StockDetail open={row.id === openId} colSpan={displayedColumn.length} productId={row.id} />
    </>
  );
};

export default Row;
