import { useState, useEffect, CSSProperties } from "react";
import styled from "@emotion/styled";
import {
  CircularProgress,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Chip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import API from "../../../utils/API";
import { formatDateISO } from "../../../utils";
import Div from "../../../components/DivSx";

export type SOHTransaction = {
  productId: number;
  onClose: () => void;
};

const InSpan = styled.span`
  color: #2e7d32;
`;
const OutSpan = styled.span`
  color: #f44336;
`;

const columns: {
  id: string;
  label: string;
  render?: (v: any, r: Record<string, any>) => JSX.Element | string | undefined;
  style?: CSSProperties;
}[] = [
  {
    id: "InitTime",
    label: "Date",
    render: (value: any, row: Record<string, any>) => formatDateISO(value),
  },
  {
    id: "Reference",
    label: "ASN/SaleOrder",
  },
  {
    id: "StockOps",
    label: "Type",
    render: (value: any, row: Record<string, any>) => {
      switch (row.id_StockOps) {
        case 1:
        default:
          return <Chip label={value} size="small" color="success" variant="outlined" />;
        case 2:
          return <Chip label={value} size="small" color="error" variant="outlined" />;
      }
    },
  },
  {
    id: "Qty",
    label: "In",
    render: (value: any, row: Record<string, any>) => {
      if (row.id_StockOps === 1) return <InSpan>+{value}</InSpan>;
    },
    style: { textAlign: "center" },
  },
  {
    id: "Qty",
    label: "Out",
    render: (value: any, row: Record<string, any>) => {
      if (row.id_StockOps === 2) return <OutSpan>-{value}</OutSpan>;
      return "";
    },
    style: { textAlign: "center" },
  },
  {
    id: "AfterTrans",
    label: "Balance",
    style: { textAlign: "center" },
  },
];

const Transaction = (props: SOHTransaction) => {
  const { productId, onClose } = props;
  const [transList, setTransList] = useState<Record<string, any>[]>([]);
  const [totalCnt, setTotalCnt] = useState<number>(0);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState({ id: 0, Name: "", Barcode: "", SKU: "" });

  const refreshData = () => {
    setLoading(true);
    API.get(`/Transaction/QueryProduct?basicProduct=true&PageSize=9999&id_Product=${productId}`, null).then((res) => {
      setLoading(false);
      if (res && res.data && res.data.Code === 0) {
        setProduct(res.data.Product);
        setTransList(res.data.TransList);
        setTotalCnt(res.data.Total);
      } else {
        setError("error");
      }
    });
  };

  useEffect(() => {
    if (productId > 0) {
      refreshData();
    } else {
      setProduct({ id: 0, Name: "", Barcode: "", SKU: "" });
      setTransList([]);
      setTotalCnt(0);
    }
  }, [productId]);

  return (
    <Dialog open={productId > 0} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>
        SKU: {product.SKU} {product.Name}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon fontSize="large" />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          minHeight: "300px",
          scrollbarGutter: "stable",
          "::-webkit-scrollbar": {
            width: "10px",
            background: "rgba(33, 33, 33, 0.1)",
            borderRadius: "10px",
          },
          "::-webkit-scrollbar-thumb": {
            background: "rgba(33, 33, 33, 0.3)",
            borderRadius: "10px",
          },
        }}
      >
        {!loading ? (
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((tr, i) => (
                  <TableCell key={`th_${i}`} style={tr.style}>
                    {tr.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {transList.map((row, i) => (
                <TableRow key={`tr_${i}`} hover>
                  {columns.map((c, i) => (
                    <TableCell key={`td_trans_${row.id}`} style={c.style}>
                      {c.render ? c.render(row[c.id], row) : row[c.id]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Div sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%,-50%)" }}>
            <CircularProgress />
          </Div>
        )}
      </DialogContent>
    </Dialog>
  );
};
export default Transaction;
