import {
  parseISO,
  isValid,
  max,
  min,
  isSameMonth,
  addMonths,
  isSameDay,
  isWithinInterval,
  startOfWeek,
  startOfMonth,
  endOfWeek,
  endOfMonth,
  isBefore,
  addDays,
  addWeeks,
} from "date-fns";

export const chunks = (array, size) =>
  Array.from({ length: Math.ceil(array.length / size) }, (_v, i) => array.slice(i * size, i * size + size));
// Date
export const getDaysInMonth = (date, locale) => {
  const startWeek = startOfWeek(startOfMonth(date), { locale });
  const endWeek = endOfWeek(endOfMonth(date), { locale });
  const days = [];
  for (let curr = startWeek; isBefore(curr, endWeek); ) {
    days.push(curr);
    curr = addDays(curr, 1);
  }
  return days;
};
export const isStartOfRange = ({ startDate }, day) => startDate && isSameDay(day, startDate);
export const isEndOfRange = ({ endDate }, day) => endDate && isSameDay(day, endDate);
export const inDateRange = ({ startDate, endDate }, day) => {
  return (
    startDate &&
    endDate &&
    (isSameDay(day, startDate) || isSameDay(day, endDate) || isWithinInterval(day, { start: startDate, end: endDate }))
  );
};
export const isRangeSameDay = ({ startDate, endDate }) => {
  return startDate && endDate && isSameDay(startDate, endDate);
};
export const parseOptionalDate = (date, defaultValue) => {
  if (date) {
    const parsed = date instanceof Date ? date : parseISO(date);
    if (isValid(parsed)) return parsed;
  }
  return defaultValue;
};
export const getValidatedMonths = (range, minDate, maxDate) => {
  const { startDate, endDate } = range;
  if (startDate && endDate) {
    const newStart = max([startDate, minDate]);
    const newEnd = min([endDate, maxDate]);
    return [newStart, isSameMonth(newStart, newEnd) ? addMonths(newStart, 1) : newEnd];
  }
  return [startDate, endDate];
};

export const getDefaultRanges = (date, locale) => [
  {
    label: "Today",
    startDate: date,
    endDate: date,
  },
  {
    label: "Yesterday",
    startDate: addDays(date, -1),
    endDate: addDays(date, -1),
  },
  {
    label: "This week",
    startDate: startOfWeek(date, { locale }),
    endDate: endOfWeek(date, { locale }),
  },
  {
    label: "Last week",
    startDate: startOfWeek(addWeeks(date, -1), { locale }),
    endDate: endOfWeek(addWeeks(date, -1), { locale }),
  },
  {
    label: "Last 7 days",
    startDate: addDays(date, -6),
    endDate: date,
  },
  {
    label: "This month",
    startDate: startOfMonth(date),
    endDate: endOfMonth(date),
  },
  {
    label: "Last month",
    startDate: startOfMonth(addMonths(date, -1)),
    endDate: endOfMonth(addMonths(date, -1)),
  },
  {
    label: "All",
    startDate: null,
    endDate: null,
  },
];

export const isSameRange = (first, second) => {
  const { startDate: fStart, endDate: fEnd } = first;
  const { startDate: sStart, endDate: sEnd } = second;
  if (fStart && sStart && fEnd && sEnd) {
    return isSameDay(fStart, sStart) && isSameDay(fEnd, sEnd);
  }
  return false;
};

export const MARKERS = {
  FIRST_MONTH: Symbol("firstMonth"),
  SECOND_MONTH: Symbol("secondMonth"),
};
