import React, { useEffect, useState } from "react";
import moment from "moment";
import { Helmet } from "react-helmet-async";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import {
  Divider as MuiDivider,
  Grid,
  Box,
  Paper as MuiPaper,
  Typography,
  Card as MuiCard,
  CardContent,
} from "@mui/material";
import API from "../../../utils/API";
import useAuth from "../../../hooks/useAuth";
import { QueryParams } from "../../../components/table";
import ChangePassword from "./ChangePassword";

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);
const Paper = styled(MuiPaper)(spacing);
const Spacer = styled.div(spacing);
const Centered = styled.div`
  text-align: center;
`;
const Left = styled.div`
  text-align: left;
`;

const Details = () => {
  const { user } = useAuth();
  return (
    <>
      <Card mb={6}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Profile Details:
          </Typography>
          <Spacer mb={4} />
          <Left>
            <>
              <Typography variant="subtitle1" gutterBottom>
                User Name: {user?.Name}
                {/* <Box fontWeight="fontWeightRegular">User Name: {user?.Name}</Box> */}
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Balance &nbsp;&nbsp;&nbsp;&nbsp;: ${user?.CreditAUD}
              </Typography>
            </>
          </Left>
        </CardContent>
      </Card>
    </>
  );
};

const Statements = () => {
  const [balance, setBalance] = useState(0);
  return (
    <>
      <Card mb={6}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Finance Statements:
          </Typography>

          <Spacer mb={4} />
          <Left>
            <>
              <Typography variant="h2" gutterBottom>
                <Box fontWeight="fontWeightRegular"></Box>
              </Typography>
            </>
          </Left>
        </CardContent>
      </Card>
    </>
  );
};

function MyProfile() {
  const { user, uiSetting, setUISetting } = useAuth();
  const [dataList, setDataList] = useState<Record<string, any>[]>([]);
  const [totalCnt, setTotalCnt] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [params, setParams] = useState<QueryParams>({
    PageSize: "20",
    PageNum: "1",
  });

  const getDataList = () => {
    setLoading(true);
    API.get(`/UserFinance/QueryFinanceStatement`, {
      ...params,
      id_User: user?.id_User,
    })
      .then((res) => {
        setDataList(res.data.ItemList);
        setTotalCnt(res.data.TotalCount);
      })
      .finally(() => setLoading(false));
  };

  return (
    <React.Fragment>
      <Helmet title="My Profile" />
      <Typography variant="h3" gutterBottom display="inline">
        My Profile
      </Typography>
      <Divider my={6} />
      <Grid container spacing={6}>
        <Grid item xs={12} lg={4}>
          <Details />
          <ChangePassword />
        </Grid>
        <Grid item xs={12} lg={8}>
          <Statements />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default MyProfile;
