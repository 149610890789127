import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import styled from "@emotion/styled";
import {
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TextField as MuiTextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Upload as UploadIcon } from "react-feather";
import API from "../../../utils/API";

const TextField = styled(MuiTextField)`
  margin: 8px;
`;

type ModalUploadType = {
  open: boolean;
  onClose: (refresh: boolean) => void;
};

const ModalUpload = (props: ModalUploadType) => {
  const { open, onClose } = props;
  const [details, setDetails] = useState<Record<string, any>[]>([]);
  const [uploading, setUploading] = useState(false);
  const [errors, setErrors] = useState<Record<string, any>[]>([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [type, setType] = useState("");
  const [notes, setNotes] = useState("");

  const handleFile = (ev: React.ChangeEvent<HTMLInputElement>) => {
    if (ev.target.files && ev.target.files[0]) {
      setUploading(true);
      const file = ev.target.files[0];
      API.uploadFileByMultipart(`/SalesOrder/ImportSalesOrderExcel?id_User=0&templateID=3`, file).then((res) => {
        setUploading(false);
        // if (res.data.Code === 0 && res.data.Errors.length === 0) {
        if (res.data && res.data.Errors && res.data.Errors.length === 0) {
          setDetails(
            res.data.SalesOrderList.map((e: Record<string, any>) => {
              return {
                ReceiverName: e.ReceiverName,
                ReceiverPhone: e.ReceiverPhone,
                ReceiverCountry: e.ReceiverCountry,
                ReceiverProvince: e.ReceiverProvince,
                ReceiverCity: e.ReceiverCity,
                ReceiverAddr: e.ReceiverAddr,
                ReceiverPostCode: e.ReceiverPostCode,
                Notes: e.Notes,
                SalesOrderId: e.SalesOrderId,
                Type: "",
                Details: e.Details.map((d: Record<string, any>) => {
                  return {
                    id_Product: d.id_Product,
                    Qty: d.Qty,
                    ASNNo: d.ASNNo,
                    WholesaleOrder: d.WholesaleOrder,
                    IsWhole: d.IsWhole,
                    id_StockCategory: d.id_StockCategory,
                    BoxType: d.BoxType,
                    BoxTypeQty: d.BoxTypeQty,
                  };
                }),
              };
            })
          );
        } else if (res.data.Errors) {
          setErrors(res.data.Errors);
        } else if (res.data.Message) {
          setErrors([{ Line: 0, ErrorCode: 993, ErrorMsgEn: res.data.Message }]);
        }
      });
    }
  };

  const handleSubmit = (data: Record<string, any>) => {
    let submitErrors = [];

    if (!type || type.length === 0) {
      submitErrors.push({ Line: 0, ErrorCode: 993, ErrorMsgEn: "Type is invalid." });
    }
    if (submitErrors.length > 0) {
      setErrors(submitErrors);
      return;
    }
    data = {
      SalesOrderList: data.map((e: Record<string, any>) => {
        return { ...e, Notes: notes };
      }),
    };
    setUploading(true);
    API.post(`/SalesOrder/BatchCreateTPLSalesOrder?type=${type}`, data).then((res) => {
      setUploading(false);
      if (res.data.Code === 0) {
        handleClose(true);
      } else {
        // TODO Error handling
      }
    });
  };

  const handleClose = (refresh: boolean = false) => {
    setUploading(false);
    setDetails([]);
    setType("");
    setNotes("");
    setErrors([]);
    setCurrentStep(0);
    onClose(refresh);
  };

  return (
    <>
      <Dialog open={open} onClose={() => handleClose()} maxWidth={details.length === 0 ? "sm" : "md"} fullWidth>
        <input
          id="input_file"
          type="file"
          onChange={handleFile}
          onClick={(ev: React.MouseEvent<HTMLInputElement>) => {
            (ev.target as HTMLInputElement).value = "";
          }}
          style={{ display: "none" }}
        />
        <DialogTitle>Upload Outbound Request</DialogTitle>
        {currentStep === 1 ? (
          <StepTwo details={details} type={type} setType={setType} notes={notes} setNotes={setNotes} />
        ) : (
          <StepOne uploading={uploading} details={details} />
        )}

        {details.length > 0 && (
          <DialogActions sx={{ pl: 10 }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                if (currentStep === 1) {
                  setCurrentStep(0);
                } else {
                  setDetails([]);
                }
              }}
            >
              {currentStep === 1 ? "Previous" : "Start over"}
            </Button>
            <LoadingButton
              variant="contained"
              color="primary"
              loading={uploading}
              onClick={() => {
                if (currentStep === 1) {
                  handleSubmit(details);
                } else {
                  setCurrentStep(1);
                }
              }}
            >
              {currentStep === 1 ? "Submit" : "Next"}
            </LoadingButton>
          </DialogActions>
        )}
      </Dialog>
      <Dialog open={errors.length > 0}>
        <DialogTitle>
          <Typography variant="h4">Errors</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="subtitle2" color="error" gutterBottom>
            There are errors in uploaded file. Please fix them and try again.
          </Typography>
          {errors &&
            errors.length > 0 &&
            errors.map((e) => (
              <Typography variant="body1" key={`error_${e.Line}_${e.ErrorCode}`}>
                {(e.Line > 0 ? `Line ${e.Line} -` : "") + `${e.ErrorMsgEn}` + (e.Extra ? ` - ${e.Extra}` : "")}
              </Typography>
            ))}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setErrors([])}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

type StepOneProps = {
  uploading: boolean;
  details: Record<string, any>[];
};

type StepTwoProps = {
  details: Record<string, any>[];
  type: string;
  setType: Dispatch<SetStateAction<string>>;
  notes: string;
  setNotes: Dispatch<SetStateAction<string>>;
};

const StepOne = (props: StepOneProps) => {
  const { details, uploading } = props;
  return (
    <DialogContent
      sx={{
        overflowY: "hide",
        scrollbarGutter: "stable",
        "::-webkit-scrollbar": {
          width: 6,
          background: "rgba(33,33,33,0.1)",
        },
        "::-webkit-scrollbar-thumb": {
          borderRadius: "10px",
          background: (theme) => theme.palette.secondary.light,
        },
        ":hover": {
          overflowY: "auto",
        },
      }}
    >
      <Grid container spacing={3} justifyContent="center">
        <Grid item sx={{ textAlign: "center" }}>
          {details.length === 0 && (
            <>
              <LoadingButton
                variant="contained"
                startIcon={<UploadIcon />}
                loading={uploading}
                loadingPosition="start"
                onClick={(ev) => document.getElementById("input_file")?.click()}
              >
                Select file...
              </LoadingButton>
              <Grid item sx={{ textAlign: "center" }}>
                <Button href="/download/SalesOrderTemplate_v231006.xlsx">Download template</Button>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      {details.length > 0 && (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell align="left">Sales Order</TableCell>
                <TableCell align="left">Receiver</TableCell>
                <TableCell align="left">Address</TableCell>
                <TableCell align="center">SKUs</TableCell>
                <TableCell align="center">Piece Qty</TableCell>
                {/* <TableCell align="center">Pieces</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {details.map((row) => (
                <TableRow
                  key={`${row.SalesOrderId}_${row.SalesOrderId}`}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  hover
                >
                  <TableCell component="th" scope="row">
                    {row.SalesOrderId}
                  </TableCell>
                  <TableCell align="left">{row.ReceiverName}</TableCell>
                  <TableCell
                    align="left"
                    sx={{ maxWidth: 270, overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}
                  >
                    {row.ReceiverAddr} {row.ReceiverProvince}
                  </TableCell>
                  <TableCell align="center">{row.Details.length}</TableCell>
                  <TableCell align="center">
                    {row.Details.reduce((sum: Number, e: Record<string, any>) => sum + e.Qty, 0)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </DialogContent>
  );
};

const StepTwo = (props: StepTwoProps) => {
  const { details, type, setType, notes, setNotes } = props;
  const [typeError, setTypeError] = useState<boolean>(false);
  const [salesOrderTypes, setSalesOrderTypes] = useState<Record<string, any>[]>([]);

  useEffect(() => {
    setTypeError(type == null || type.length === 0 || type === "0");
  }, [type]);

  useEffect(() => {
    API.get(`/SalesOrder/QuerySalesOrderType`, null).then((res) => {
      if (res.data.Code === 0) {
        setSalesOrderTypes(res.data.TypeList);
      } else {
        //
      }
    });
  }, []);

  return (
    <DialogContent>
      <Grid container justifyContent="center" spacing={5}>
        <Grid item sm={12} sx={{ textAlign: "center" }}>
          <Typography variant="subtitle2">Please input and confirm following details</Typography>
        </Grid>
        <Grid item sm={4}>
          <Card variant="outlined" sx={{ margin: "8px", width: "100%" }}>
            <CardContent>
              <Grid container>
                <Grid item xs={9}>
                  <Typography variant="body2" gutterBottom>
                    Total Orders:
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body2" style={{ float: "right" }}>
                    {details.length}
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="body2" gutterBottom>
                    Total SKUs:
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body2" style={{ float: "right" }}>
                    {details.reduce((sum, e) => sum + e.Details.length, 0)}
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="body2" gutterBottom>
                    Total Set:
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body2" style={{ float: "right" }}>
                    2
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="body2" gutterBottom>
                    Total Piece:
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body2" style={{ float: "right" }}>
                    1
                  </Typography>
                </Grid>

                <Grid item xs={9}>
                  <Typography variant="body2" gutterBottom>
                    Total Qty:
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body2" style={{ float: "right" }}>
                    {details.reduce(
                      (sum, e) => sum + e.Details.reduce((sum: Number, e: Record<string, any>) => sum + e.Qty, 0),
                      0
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <FormControl fullWidth sx={{ margin: "8px" }}>
            <InputLabel id="select_type">Type</InputLabel>
            <Select
              fullWidth
              labelId="select_type"
              error={typeError}
              label="Type"
              value={type}
              onChange={(ev) => setType(ev.target.value)}
            >
              {salesOrderTypes.map((type) => {
                return (
                  <MenuItem value={type.id} sx={{ minWidth: "110px", textAlign: "center" }}>
                    {type.SOType}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            type="text"
            multiline
            rows={4}
            autoComplete="new-password"
            value={notes}
            label="Notes"
            onChange={(ev) => setNotes(ev.target.value)}
          />
        </Grid>
      </Grid>
    </DialogContent>
  );
};

export default ModalUpload;
