import { createContext, ReactNode, useEffect, useLayoutEffect, useReducer } from "react";

import { JWTContextType, ActionMap, AuthState, AuthUser, UserUISetting } from "../types/auth";
import axiosInstance from "../utils/axios";

import axios from "../utils/axios";
import moment from "moment";

import { useApp } from "../contexts/AppContext";

const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";
const SIGN_UP = "SIGN_UP";
const SET_UI_SETTING = "SET_UI_SETTING";

type AuthActionTypes = {
  [INITIALIZE]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [SIGN_IN]: {
    user: AuthUser;
  };
  [SIGN_OUT]: undefined;
  [SIGN_UP]: {
    user: AuthUser;
  };
  [SET_UI_SETTING]: {
    uiSetting: UserUISetting;
  };
};

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  uiSetting: {},
};

const JWTReducer = (state: AuthState, action: ActionMap<AuthActionTypes>[keyof ActionMap<AuthActionTypes>]) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        ...state,
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case SIGN_UP:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case SET_UI_SETTING:
      return {
        ...state,
        uiSetting: { ...state.uiSetting, ...action.payload.uiSetting },
      };
    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const { setCurrentUser } = useApp();
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  useLayoutEffect(() => {
    const initialize = () => {
      try {
        const userJson = window.localStorage.getItem("user");
        const user: AuthUser = userJson ? JSON.parse(userJson) : null;

        if (isValidToken(user)) {
          setSession(user);
          getUISetting();
          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const signIn = async (LoginID: string, Password: string) => {
    const response = await axiosInstance.post("/UserLogin/Login", {
      LoginID,
      Password,
    });
    const user: AuthUser = response.data;

    setSession(user);
    getUISetting();
    dispatch({
      type: SIGN_IN,
      payload: {
        user,
      },
    });
    return user;
  };

  const signOut = async () => {
    setSession(null);
    dispatch({ type: SIGN_OUT });
  };

  const signUp = async (email: string, password: string, firstName: string, lastName: string) => {
    const response = await axios.post("/api/auth/sign-up", {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem("accessToken", accessToken);
    dispatch({
      type: SIGN_UP,
      payload: {
        user,
      },
    });
  };

  const resetPassword = (email: string) => console.log(email);

  const getUISetting = () => {
    axiosInstance.get("/UserLogin/GetUISetting").then((res) => {
      dispatch({
        type: SET_UI_SETTING,
        payload: {
          uiSetting: res.data.UISetting,
        },
      });
    });
  };

  const setUISetting = (input: UserUISetting) => {
    var setting = { ...state.uiSetting, ...input };
    axiosInstance.post("/UserLogin/SetUISetting", {
      UISetting: setting,
    });
    dispatch({
      type: SET_UI_SETTING,
      payload: { uiSetting: setting },
    });
  };

  const setSession = (user: AuthUser) => {
    setCurrentUser(user);
    if (user) {
      localStorage.setItem("user", JSON.stringify(user));
      axiosInstance.defaults.headers.common.Authorization = `Bearer ${user.Token}`;
    } else {
      localStorage.removeItem("user");
      delete axiosInstance.defaults.headers.common.Authorization;
    }
  };

  const isValidToken = (user: AuthUser) => {
    if (!user) return false;

    return moment() < moment(user.TokenExpireTime);
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        signIn,
        signOut,
        signUp,
        resetPassword,
        setUISetting,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
