import { TableColumn } from "../../../components/table";
import moment from "moment";

export const AVAILABLE_COLUMN_OUT_REQ: TableColumn[] = [
  {
    id: "InitTime",
    dataIndex: "InitTime",
    header: "Request Date",
    render: (obj) => moment(obj).format("yyyy-MM-DD"),
  },
  {
    id: "SalesOrderId",
    dataIndex: "SalesOrderId",
    header: "Sales Order",
  },
  {
    id: "Status",
    dataIndex: "Status",
    header: "Status",
  },
  {
    id: "Type",
    dataIndex: "Type",
    header: "Type",
  },
  {
    id: "ReceiverName",
    dataIndex: "ReceiverName",
    header: "Receiver",
  },
  {
    id: "ReceiverPhone",
    dataIndex: "ReceiverPhone",
    header: "Phone",
  },
  {
    id: "ReceiverFullAddress",
    header: "Address",
    render: (value, obj) =>
      `${obj.ReceiverAddr}, ${obj.ReceiverCity || ""}, ${obj.ReceiverProvince || ""}, ${obj.ReceiverPostCode || ""}`,
  },
  {
    id: "Notes",
    dataIndex: "Notes",
    header: "Notes",
  },
  {
    id: "ShipmentOrderId",
    dataIndex: "ShipmentOrderId",
    header: "Shipment Order",
  },
];

export const DEFAULT_COLUMN_SETTING_OUT_REQ: string[] = [
  "InitTime",
  "SalesOrderId",
  "Status",
  "ReceiverName",
  "ReceiverPhone",
  "ReceiverFullAddress",
  "Notes",
  "ShipmentOrderId",
];

export const AVAILABLE_SUBTABLE_COLUMN_OUT_REQ: TableColumn[] = [
  {
    id: "ProductName",
    dataIndex: "Name",
    header: "Product",
  },
  {
    id: "Brand",
    dataIndex: "Brand",
    header: "Brand",
  },
  {
    id: "SKU",
    dataIndex: "SKU",
    header: "SKU",
  },
  {
    id: "Barcode",
    dataIndex: "Barcode",
    header: "Barcode",
  },
  {
    id: "Scale",
    dataIndex: "Scale",
    header: "Scale",
  },
  {
    id: "BoxType",
    dataIndex: "BoxType",
    header: "Box Type",
  },
  {
    id: "BoxTypeQty",
    dataIndex: "BoxTypeQty",
    header: "Quantity",
  },
  {
    id: "Qty",
    dataIndex: "Qty",
    header: "Piece",
  },
  {
    id: "Notes",
    dataIndex: "Notes",
    header: "Detail Notes",
  },
];

export const DEFAULT_SUBTABLE_COLUMN_SETTING_OUT_REQ: string[] = ["ProductName", "Brand", "Scale", "Qty", "Notes"];
