import { useState, useEffect } from "react";

import { Dialog, DialogContent, DialogActions, DialogTitle, Grid, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import StockCard from "../StockCard";

import { useApp } from "../../contexts/AppContext";

import API from "../../utils/API";

const CategoryTransferDialog = () => {
  const { categoryTransfer, setCategoryTransfer, setMessageBox } = useApp();
  const { open, onClose, onConfirm } = categoryTransfer;
  const [from, setFrom] = useState<Record<string, any> | null>();
  const [right, setRight] = useState<Record<string, any>[]>([]);
  const [qty, setQty] = useState(0);
  const [idTo, setIdTo] = useState(-1);
  const [valid, setValid] = useState(false);
  const [loading, setLoading] = useState(false);

  const closeHandler = () => {
    setIdTo(-1);
    setFrom(null);
    setRight([]);
    setQty(0);
    setValid(false);
    setCategoryTransfer({
      open: false,
      categories: [],
      idFrom: -1,
      idProduct: 0,
      onClose: null,
      onConfirm: null,
      onCancel: null,
    });
    if (onClose) onClose();
  };

  const confirmHandler = () => {
    setLoading(true);
    API.post(`/Stock/TransferStockCategoryV2`, {
      id_Product: categoryTransfer.idProduct,
      id_From: categoryTransfer.idFrom,
      id_to: idTo,
      qty: qty,
    }).then((res) => {
      setLoading(false);
      if (res && res.data && res.data.Code === 0) {
        closeHandler();
        if (onConfirm) onConfirm();
      } else {
        if (res.data.Errors) {
          setMessageBox({
            msg: res.data.Errors[0].ErrorMsgEn,
            title: "Error",
            open: true,
            cancelText: "OK",
          });
        }
      }
    });
  };

  useEffect(() => {
    if (categoryTransfer.categories.length > 0 && categoryTransfer.idFrom >= 0) {
      setFrom(categoryTransfer.categories.find((e) => e.id_Category === categoryTransfer.idFrom));
      setRight(categoryTransfer.categories.filter((e) => e.id_Category !== categoryTransfer.idFrom));
    }
  }, [categoryTransfer.categories, categoryTransfer.idFrom]);

  useEffect(() => {
    const fromOrignal = categoryTransfer.categories.find((e) => e.id_Category === categoryTransfer.idFrom);
    if (from) {
      setFrom((e) => ({ ...e, AvailableQty: fromOrignal?.AvailableQty - qty, StockQty: fromOrignal?.StockQty - qty }));
    }
    if (idTo >= 0 && categoryTransfer.categories.length > 0) {
      const rightOriginal = categoryTransfer.categories.filter((e) => e.id_Category !== categoryTransfer.idFrom);
      let temp = JSON.parse(JSON.stringify(rightOriginal));
      const index = rightOriginal.findIndex((e) => e.id_Category === idTo);
      temp[index].AvailableQty += qty;
      temp[index].StockQty += qty;
      setRight(temp);
    }
    setValid(qty > 0 && idTo >= 0 && fromOrignal?.AvailableQty >= qty);
  }, [qty, idTo]);

  return (
    <Dialog open={open} onClose={closeHandler} maxWidth="sm" fullWidth={true}>
      <DialogTitle>Transfer Category</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            {from && (
              <StockCard
                row={from}
                selected
                style={{ position: "relative", top: "50%", transform: "translateY(-50%)", margin: "0" }}
              />
            )}
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "center" }}>
            <TextField
              type="number"
              sx={{
                position: "relative",
                top: "50%",
                width: "110px",
                transform: "translateY(-50%)",
                margin: "0",
                textAlign: "center",
              }}
              size="small"
              value={qty}
              onChange={(ev) => {
                const value = parseInt(ev.target.value);
                setQty(isNaN(value) ? 0 : value);
              }}
            />
          </Grid>
          <Grid item xs={4}>
            {right.map((row) => (
              <StockCard
                selected={idTo === row.id_Category}
                key={`transferCategoryCard_${row.id_Category}`}
                row={row}
                style={{ margin: "5px 0" }}
                onClick={() => setIdTo(row.id_Category)}
              />
            ))}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <LoadingButton color="primary" variant="contained" loading={loading} onClick={confirmHandler} disabled={!valid}>
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CategoryTransferDialog;
