import React, {
  Dispatch,
  SetStateAction,
  ChangeEvent,
  MouseEvent,
  MouseEventHandler,
  useMemo,
  useState,
  useCallback,
} from "react";
import styled from "@emotion/styled";
import debounce from "lodash.debounce";
import { darken } from "polished";
import {
  Box,
  Button,
  TableContainer,
  Table as MuiTable,
  Toolbar,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  MenuList,
  Switch,
} from "@mui/material";
import { ViewWeek as ViewWeekIcon, Assignment as AssignmentIcon } from "@mui/icons-material";
import { Download as ExportIcon, Search as SearchIcon } from "react-feather";
import LightTooltip from "../../../components/LightTooltip";

import Row from "./TableRowSOH";

import moment from "moment";

import { TableProps, TableColumn, ColumnConfig, ExportDataConfig } from "../../../components/table";
import { getCellContent } from "../../../components/table/Table";

import { EXPORT_COLUMN_SOH } from "./tableColumn";

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 100%;
`;

const Search = styled.div`
  border-radius: 4px;
  background-color: ${(props) => props.theme.header.background};
  display: none;
  position: relative;
  width: 200px;
  margin-right: 8px;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;

const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)};
    padding-right: ${(props) => props.theme.spacing(2.5)};
    padding-bottom: ${(props) => props.theme.spacing(2.5)};
    padding-left: ${(props) => props.theme.spacing(12)};
    width: 160px;
  }
`;

interface TableSOHProps extends TableProps {
  inStockOnly: boolean;
  changeStockHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setOpenedProduct: Dispatch<SetStateAction<number>>;
}

const Table: React.FC<TableSOHProps> = ({
  data,
  columnConfig,
  exportData,
  exportMultipleFormat,
  loading,
  searchKeywords,
  onKeywordsChanged,
  toolButtons,
  inStockOnly,
  changeStockHandler,
  setOpenedProduct,
}) => {
  const [filterAnchorEl, setFilterAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [exportAnchorEl, setExportAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [openId, setOpenId] = useState<number>(0);

  const XLSX = window.XLSX;

  const toggleFilter: MouseEventHandler = (e: MouseEvent<HTMLButtonElement>) => {
    setFilterAnchorEl(e.currentTarget);
  };

  const closeFilter = () => {
    setFilterAnchorEl(null);
  };

  const closeExportMenu = () => {
    setExportAnchorEl(null);
  };

  const checkboxChangedHandler =
    (columnConfig: ColumnConfig, displayedColumn: TableColumn[]) => (event: ChangeEvent<HTMLInputElement>) => {
      if (columnConfig.handleColumnSettingChanged) {
        var setting: string[] = displayedColumn
          .filter((c) => columnConfig.availableColumn.includes(c))
          .map((c) => c.id);
        if (event.target.checked) {
          if (!setting.includes(event.target.name)) {
            setting.push(event.target.name);
          }
        } else {
          setting = setting.filter((c) => c !== event.target.name);
        }
        columnConfig.handleColumnSettingChanged(setting);
      }
    };

  const getDisplayColumn = (columnConfig: ColumnConfig) => {
    let setting =
      columnConfig.columnSetting && columnConfig.columnSetting.length > 0
        ? columnConfig.columnSetting
        : columnConfig.defaultSetting;
    let resColumn = columnConfig.availableColumn.filter((col) => col.id && setting.includes(col.id));
    const auth = localStorage.getItem("user");
    const authObj = auth == null ? {} : JSON.parse(auth);
    if (authObj.isMaster) {
      resColumn.push({
        id: "Actions",
        header: "Transactions",
        dataAlign: "center",
        render: (obj, row) => {
          return (
            <Box mr={2}>
              <LightTooltip title="Transactions">
                <IconButton className="preventRaise" onClick={(ev) => setOpenedProduct(row.id)}>
                  <AssignmentIcon />
                </IconButton>
              </LightTooltip>
            </Box>
          );
        },
      });
    }

    return resColumn;
  };

  const displayedColumn: TableColumn[] = useMemo(() => getDisplayColumn(columnConfig), [columnConfig.columnSetting]);

  const handleExportClick: MouseEventHandler = (e: MouseEvent<HTMLButtonElement>) => {
    if (exportData) {
      ExportXLSX(exportData, "SOH");
    } else if (exportMultipleFormat) {
      setExportAnchorEl(e.currentTarget);
    }
  };

  const handleExportMenuClick = (expConf: ExportDataConfig) => () => {
    ExportXLSX(expConf.getData, "SOH_" + expConf.menuTitle);
    setExportAnchorEl(null);
  };

  const ExportXLSX = (getData: () => Promise<Record<string, any>[]>, filename: string) => {
    if (!getData) {
      return;
    }
    getData()
      .then((dataList) => {
        const xlsxData: string[][] = [];

        // sheet column headers
        var xlsxRow: string[] = [];
        columnConfig.availableColumn.forEach((column) => {
          let xlsxHeader = typeof column.header === "string" ? column.header : column.id;
          xlsxRow.push(xlsxHeader);
        });
        xlsxData.push(xlsxRow);

        // sheet content
        dataList.forEach((obj) => {
          const xlsxRow: string[] = [];
          columnConfig.availableColumn.forEach((column) => {
            let cellContent = column.exportFn ? column.exportFn(obj) : getCellContent(obj, column);
            xlsxRow.push(cellContent);
          });
          xlsxData.push(xlsxRow);
        });
        var worksheet = XLSX.utils.aoa_to_sheet(xlsxData);
        var new_workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(new_workbook, worksheet, "sheet1");
        XLSX.writeFile(new_workbook, filename + "_" + moment().format("YYYYMMDD") + ".xlsx");
      })
      .catch((e) => {
        alert("An error has occured.");
      });
  };

  const debouncedChangeHandler = useCallback(
    debounce((e) => {
      onKeywordsChanged && onKeywordsChanged(e.target.value);
    }, 300),
    [onKeywordsChanged]
  );
  return (
    <Box p={2}>
      {(exportData || exportMultipleFormat || columnConfig.handleColumnSettingChanged) && (
        <Toolbar>
          <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
            <Search>
              <SearchIconWrapper>
                <SearchIcon style={{ color: "#9e9e9e" }} />
              </SearchIconWrapper>
              <Input placeholder={searchKeywords?.join(", ")} onChange={debouncedChangeHandler} />
            </Search>
          </div>
          <FormGroup sx={{ width: "200px" }}>
            <FormControlLabel
              labelPlacement="start"
              control={<Switch checked={inStockOnly} onChange={changeStockHandler} />}
              label="In Stock"
            />
          </FormGroup>
          {toolButtons &&
            toolButtons.map((button, index) => (
              <Button
                key={`button_${button.label.replace(/\s/, "")}_${index}}`}
                sx={{ minWidth: "100px", margin: 2 }}
                variant="contained"
                startIcon={button.icon}
                onClick={button.onClick}
              >
                {button.label}
              </Button>
            ))}

          <Spacer />

          {(exportData || exportMultipleFormat) && (
            <React.Fragment>
              <LightTooltip title="Export" placement="top">
                <IconButton onClick={handleExportClick}>
                  <ExportIcon />
                </IconButton>
              </LightTooltip>
              <Menu anchorEl={exportAnchorEl} open={Boolean(exportAnchorEl)} onClose={closeExportMenu}>
                <MenuList>
                  {exportMultipleFormat &&
                    exportMultipleFormat.map((expConfig) => (
                      <MenuItem key={expConfig.menuTitle} onClick={handleExportMenuClick(expConfig)}>
                        {expConfig.menuTitle}
                      </MenuItem>
                    ))}
                </MenuList>
              </Menu>
            </React.Fragment>
          )}
          {columnConfig.handleColumnSettingChanged && (
            <React.Fragment>
              <LightTooltip title="Column Filter" placement="top">
                <IconButton onClick={toggleFilter}>
                  <ViewWeekIcon />
                </IconButton>
              </LightTooltip>
              <Menu anchorEl={filterAnchorEl} open={Boolean(filterAnchorEl)} onClose={closeFilter}>
                <MenuList>
                  {columnConfig.availableColumn.map((c, i) => (
                    <MenuItem key={c.id}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={displayedColumn.map((c) => c.id).includes(c.id)}
                            color="default"
                            onChange={checkboxChangedHandler(columnConfig, displayedColumn)}
                            name={c.id}
                          />
                        }
                        label={c.header || c.id}
                      />
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            </React.Fragment>
          )}
        </Toolbar>
      )}
      {loading ? (
        <LoaderContainer>
          <CircularProgress />
        </LoaderContainer>
      ) : (
        <TableContainer>
          <MuiTable>
            <TableHead>
              <TableRow>
                {displayedColumn.map((column) => (
                  <TableCell key={column.id} align={column.dataAlign}>
                    {column.header || column.dataIndex}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {data.map((row, index) => (
                <Row
                  key={`row_soh_${index}`}
                  row={row}
                  displayedColumn={displayedColumn}
                  openId={openId}
                  setOpenId={setOpenId}
                />
              ))}
            </TableBody>
          </MuiTable>
        </TableContainer>
      )}
    </Box>
  );
};

export default Table;
