import React, { useMemo, useState, useCallback, Dispatch, SetStateAction, useEffect } from "react";
import styled from "@emotion/styled";
import debounce from "lodash.debounce";
import { darken } from "polished";
import {
  Box,
  Button,
  Grid,
  Checkbox,
  TableContainer,
  Table as MuiTable,
  Collapse,
  Toolbar,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  InputBase,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stepper,
  Step,
  StepLabel,
  StepIconProps,
  Typography,
} from "@mui/material";
import { Search as SearchIcon, Printer as PrintIcon, Trash2 as DeleteIcon, Check as CheckIcon } from "react-feather";

import LightTooltip from "../../../components/LightTooltip";
import { DateRangePicker } from "../../../components/DateRangePicker";
import PicPreview, { ReactImageGalleryItem } from "../../../components/PicPreview";
import { formatDateISO } from "../../../utils";
import API from "../../../utils/API";
import { TableProps, TableColumn, ColumnConfig } from "../../../components/table";
import TableDetail from "../ldComponents/TableDetailLocalDelivery";
import { useApp } from "../../../contexts/AppContext";
import Div from "../../../components/DivSx";
import { height } from "@mui/system";

const QontoStepIconRoot = styled("div")<{ ownerState: { active?: boolean } }>(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#784af4",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#fff",
    backgroundColor: theme.palette.secondary.main,
    width: "18px",
    height: "18px",
    borderRadius: "50%",
    zIndex: 1,
    fontSize: 6,
  },
  "& .QontoStepIcon-circle": {
    width: "18px",
    height: "18px",
    borderRadius: "50%",
    backgroundColor: "#e0e0e0",
  },
}));

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 100%;
`;

const Search = styled.div`
  border-radius: 4px;
  background-color: ${(props) => props.theme.header.background};
  display: none;
  position: relative;
  width: 200px;
  margin-right: 8px;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;

const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)};
    padding-right: ${(props) => props.theme.spacing(2.5)};
    padding-bottom: ${(props) => props.theme.spacing(2.5)};
    padding-left: ${(props) => props.theme.spacing(12)};
    width: 160px;
  }
`;

const ButtonAction = styled(Button)`
  /* background-color: ${(props) => (props.color === "primary" ? props.theme.palette.primary.light : "")}; */
  margin: 0 2px;
  box-shadow: none;
  min-width: 0;
  padding: 5px;
`;

const printPDF = async (orderId: string[]) => {
  //由于Firefox的CORS政策不允许网页和iframe的域名不同，
  //所以打印功能暂时不支持Firefox和类似Mozilla内核的浏览器。
  //未来也许可以用NextJS等方案解决。
  const response = await API.postraw(`/DeliveryOrder/BatchOrderPrint`, orderId);
  const resBlob = await response.blob();
  var frame = document.getElementById("ff_printme");
  if (frame !== null && frame.parentNode != null) frame.parentNode.removeChild(frame);
  var printFrame = document.createElement("iframe");
  printFrame.id = "ff_printme";
  printFrame.style.visibility = "hidden";
  printFrame.style.position = "fixed";
  printFrame.style.right = "0";
  printFrame.style.bottom = "0";
  printFrame.style.width = "0";
  printFrame.style.height = "0";
  printFrame.src = window.URL.createObjectURL(resBlob);
  printFrame.onload = () => {
    const ff = document.getElementById("ff_printme") as HTMLIFrameElement;
    if (ff != null && ff.contentWindow != null) {
      ff.contentWindow.document.execCommand("print", false) || ff.contentWindow.print();
    }
  };
  document.body.appendChild(printFrame);
};

export const getCellContent = (row: Record<string, any>, column: TableColumn) => {
  var obj: any;
  if (column.dataIndex) {
    if (typeof column.dataIndex === "string") {
      obj = row[column.dataIndex];
    } else {
      obj = row;
      for (let i = 0; i < column.dataIndex.length; i++) {
        if (!obj) {
          obj = "";
          break;
        }
        obj = obj[column.dataIndex[i]];
      }
    }
  } else {
    obj = row[column.id];
  }

  if (column.render) {
    return column.render(obj, row);
  } else {
    return obj;
  }
};

const TableItemList: React.FC<TableProps> = ({ data, columnConfig }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [openDate, setOpenDate] = useState<boolean>(false);
  const [selecting, setSelecting] = useState<boolean>(false);
  const [printing, setPrinting] = useState<boolean>(false);
  const [selectedOrders, setSelectedOrders] = useState<string[]>([]);
  const [dateRange, setDateRange] = useState<{ startDate: Date | null; endDate: Date | null; label?: string }>({
    startDate: null,
    endDate: null,
    label: "",
  });
  const [openedRowId, setOpenedRowId] = useState(0);
  const [podList, setPodList] = useState<ReactImageGalleryItem[]>([]);

  const { setMessageBox } = useApp();

  const getDisplayColumn = (columnConfig: ColumnConfig) => {
    let setting =
      columnConfig.columnSetting && columnConfig.columnSetting.length > 0
        ? columnConfig.columnSetting
        : columnConfig.defaultSetting;
    let resColumn = columnConfig.availableColumn.filter((col) => col.id && setting.includes(col.id));

    return resColumn;
  };

  const displayedColumn: TableColumn[] = useMemo(() => getDisplayColumn(columnConfig), [columnConfig.columnSetting]);

  return (
    <Box p={2}>
      {loading ? (
        <LoaderContainer>
          <CircularProgress />
        </LoaderContainer>
      ) : (
        <TableContainer>
          <MuiTable>
            <TableHead>
              <TableRow>
                {displayedColumn.map((column) => (
                  <TableCell key={column.id} align={column.dataAlign}>
                    {column.header || column.dataIndex}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {data.map((row, index) => (
                <TableRowLocalDelivery
                  key={`row_localdelivery_${row.ItemID}`}
                  row={row}
                  displayedColumn={displayedColumn}
                  openedRowId={openedRowId}
                  setOpenedRowId={setOpenedRowId}
                  setPodList={setPodList}
                  selecting={selecting}
                  selected={selectedOrders.includes(row.OrderId)}
                  setSelectedOrders={setSelectedOrders}
                  printing={printing}
                />
              ))}
            </TableBody>
          </MuiTable>
        </TableContainer>
      )}
      <PicPreview pics={podList} open={podList.length > 0} onClose={() => setPodList([])} />
    </Box>
  );
};

const TableRowLocalDelivery = ({
  row,
  displayedColumn,
  openedRowId,
  setOpenedRowId,
  setPodList,
  selecting,
  selected,
  setSelectedOrders,
  printing,
}: {
  row: Record<string, any>;
  displayedColumn: TableColumn[];
  openedRowId: number;
  setOpenedRowId: Dispatch<SetStateAction<number>>;
  setPodList: Dispatch<SetStateAction<ReactImageGalleryItem[]>>;
  selecting: boolean;
  selected: boolean;
  setSelectedOrders: Dispatch<SetStateAction<string[]>>;
  printing: boolean;
}) => {
  const { setMessageBox } = useApp();

  const clickHandler = () => {
    setOpenedRowId(openedRowId === row.ItemSN ? 0 : row.ItemSN);
  };

  return (
    <React.Fragment>
      <TableRow hover onClick={clickHandler}>
        {displayedColumn.map((column) => (
          <TableCell
            key={column.id}
            align={column.dataAlign}
            sx={{ ...column.style }}
            style={{ paddingBottom: 6, paddingTop: 6 }}
          >
            {getCellContent(row, column)}
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={displayedColumn.length + 1}>
          <Collapse in={openedRowId === row.ItemSN} timeout="auto" mountOnEnter unmountOnExit>
            {/* <TableDetail row={row} setPodList={setPodList} /> */}
            <PanelItemTrack row={row} />
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

function QontoStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? <div className="QontoStepIcon-completedIcon" /> : <div className="QontoStepIcon-circle" />}
    </QontoStepIconRoot>
  );
}

const PanelItemTrack = (props: {
  row: Record<string, any>;
  // setPodList: Dispatch<SetStateAction<ReactImageGalleryItem[]>>;
}) => {
  const { row } = props;
  let itemID = row.ItemID;
  const [tracking, setTracking] = useState<Record<string, any>[]>([]);
  // const [pods, setPods] = useState<Record<string, any>[]>([]);
  const [loaded, setLoaded] = useState(false);
  const [activeStepIndex, setActiveStepIndex] = useState(2);

  useEffect(() => {
    setActiveStepIndex(tracking.length);
  }, [tracking]);

  useEffect(() => {
    if (itemID) {
      setLoaded(false);
      API.get(`/DeliveryOrder/TrackItem?ItemID=${row.ItemID}`, null).then((res) => {
        setLoaded(true);
        if (res && res.data && res.data.Code === 0) {
          setTracking(
            res.data.TrackList.sort((a: Record<string, any>, b: Record<string, any>) =>
              a.TrackTime < b.TrackTime ? -1 : 1
            )
          );
          // setPods(res.data.PODList);
        } else {
          // setErrors(res.data.Errors);
        }
      });
    }
  }, [itemID]);

  return (
    <Grid container sx={{ padding: "20px 30px" }}>
      <Grid
        item
        xs={12}
        sx={{
          padding: "0 30px",
          maxHeight: "475px",
          overflowY: "auto",
          scrollbarGutter: "stable",
          "::-webkit-scrollbar": {
            width: 10,
            background: "rgba(33,33,33,0.1)",
            borderRadius: "10px",
          },
          "::-webkit-scrollbar-thumb": {
            borderRadius: "10px",
            background: (theme) => theme.palette.secondary.main,
          },
          ":hover": {
            overflowY: "auto",
          },
        }}
      >
        {loaded ? (
          tracking.length > 0 ? (
            <Box sx={{ width: "100%" }}>
              <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                Item Delivery Track:
              </Typography>
              <Stepper
                activeStep={activeStepIndex}
                orientation="vertical"
                sx={{
                  " .MuiStepConnector-root": {
                    marginLeft: "8px",
                  },
                }}
              >
                {tracking.map((step, index) => {
                  return (
                    <Step key={`tracking_${itemID}_${index}`}>
                      <StepLabel StepIconComponent={QontoStepIcon}>
                        {step.TrackStatus}
                        <br />
                        {step.Staff}
                        <br />
                        {step.TrackTime ? formatDateISO(step.TrackTime, "m") : ""}
                      </StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            </Box>
          ) : (
            "No item delivery track yet"
          )
        ) : (
          <Div sx={{ justifyContent: "center", alignItems: "center", display: "flex", minHeight: "100%" }}>
            <CircularProgress color="secondary" />
          </Div>
        )}
      </Grid>
    </Grid>
  );
};

export default TableItemList;
