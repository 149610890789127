import React, { useState } from "react";
import {
  Paper as MuiPaper,
  Toolbar,
  Typography,
  TextField as MuiTextField,
  Button as MuiButton,
  FormControlLabel,
  Checkbox,
  Autocomplete as MuiAutocomplete,
} from "@mui/material";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { useSearchParams } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";

import { QueryConditionField } from ".";

const Paper = styled(MuiPaper)(spacing);
const Content = styled.div`
  margin-left: ${(props) => props.theme.spacing(2)};
  margin-right: ${(props) => props.theme.spacing(2)};
  margin-bottom: ${(props) => props.theme.spacing(2)};
`;
const TextField = styled(MuiTextField)(spacing);
const Button = styled(MuiButton)(spacing);

const Autocomplete = styled(MuiAutocomplete)(spacing);

export const getQueryConditionFromSearchParam = (queryFields: QueryConditionField[], params: URLSearchParams) => {
  let condition: Record<string, any> = {};
  queryFields.forEach((field) => {
    switch (field.type) {
      case "text":
        condition[field.id] = params.get(field.id);
        break;
      case "checkbox":
        condition[field.id] = params.get(field.id) === "true";
        break;
      case "date":
        condition[field.id] = params.get(field.id) ? moment(params.get(field.id)) : null;
        break;
      case "selection":
        condition[field.id] = params.get(field.id);
        break;
      default:
        break;
    }
  });
  return condition;
};

export const getAxiosParamFromSearchParam = (queryFields: QueryConditionField[], params: URLSearchParams) => {
  var axiosParam: Record<string, string> = Object.fromEntries(params.entries());
  if (axiosParam["EndDate"]) {
    axiosParam["EndDate"] = moment(axiosParam["EndDate"])
      .add(1, "days")
      .add(-1, "milliseconds")
      .format("YYYY-MM-DDThh:mm:ss.SSS");
  }
  queryFields.forEach((field) => {
    if (field.type === "selection") {
      if (axiosParam[field.id]) {
        axiosParam[field.id] = field.selection.find((option) => option.label === axiosParam[field.id])?.value || "";
      }
    }
  });

  return axiosParam;
};

type QueryConditionProps = { queryFields: QueryConditionField[] };

const QueryCondition: React.FC<QueryConditionProps> = ({ queryFields }) => {
  const [params, setParams] = useSearchParams();
  const [condition, setCondition] = useState<Record<string, any>>(
    getQueryConditionFromSearchParam(queryFields, params)
  );

  const search = () => {
    let param = Object.fromEntries(params.entries());
    queryFields.forEach((field) => {
      switch (field.type) {
        case "text":
          param[field.id] = condition[field.id] || "";
          break;
        case "checkbox":
          param[field.id] = Boolean(condition[field.id]).toString();
          break;
        case "date":
          param[field.id] = condition[field.id] ? condition[field.id].format("YYYY-MM-DD") : "";
          break;
        case "selection":
          param[field.id] = condition[field.id] || "";
          break;
        default:
          break;
      }
    });
    param["PageNum"] = "1";
    setParams(param);
  };

  const onKeyUp = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      search();
    }
  };

  return (
    <Paper>
      <Toolbar>
        <Typography variant="h6" id="queryConditionTitle">
          Query Condition
        </Typography>
      </Toolbar>

      <Content>
        <Button fullWidth variant="contained" color="primary" mb={3} onClick={search}>
          <SearchIcon />
          SEARCH
        </Button>
        {queryFields.map((field) => {
          switch (field.type) {
            case "text":
              return (
                <TextField
                  key={field.id}
                  label={field.label}
                  size="small"
                  my={1}
                  fullWidth
                  value={condition[field.id] || ""}
                  onChange={(e) => setCondition({ ...condition, [field.id]: e.target.value })}
                  onKeyUp={onKeyUp}
                />
              );
            case "checkbox":
              return (
                <FormControlLabel
                  key={field.id}
                  control={
                    <Checkbox
                      checked={condition[field.id]}
                      color="default"
                      onChange={(e) => {
                        setCondition({
                          ...condition,
                          [field.id]: e.target.checked,
                        });
                      }}
                    />
                  }
                  label={field.label}
                />
              );
            case "date":
              return (
                <DatePicker
                  key={field.id}
                  label={field.label}
                  value={condition[field.id] || null}
                  onChange={(newValue) => {
                    setCondition({ ...condition, [field.id]: newValue });
                  }}
                  renderInput={(params) => <TextField {...params} size="small" my={1} />}
                  inputFormat="YYYY-MM-DD"
                />
              );
            case "selection":
              return (
                <Autocomplete
                  key={field.id}
                  size="small"
                  my={1}
                  value={condition[field.id]}
                  options={field.selection.map((option) => option.label)}
                  onChange={(event: React.SyntheticEvent, value) => {
                    setCondition({ ...condition, [field.id]: value });
                  }}
                  renderInput={(params) => <TextField {...params} label={field.label} />}
                />
              );
            // default:
            //   return <React.Fragment key={field.id}></React.Fragment>;
          }
        })}
      </Content>
    </Paper>
  );
};

export default QueryCondition;
