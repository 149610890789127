import React, { useState } from "react";
import styled from "@emotion/styled";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Card,
  CardContent,
  Grid,
  List,
  Link,
  Typography,
  ListItemText as MuiListItemText,
  ListItemButtonProps as MuiListItemButtonProps,
  ListItemButton as MuiListItemButton,
} from "@mui/material";
import { TITLE } from "../constants";

interface ListItemButtonProps extends MuiListItemButtonProps {
  component?: string;
  href?: string;
}

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(0.25)} ${(props) => props.theme.spacing(4)};
  background: ${(props) => props.theme.footer.background};
  position: relative;
`;

const ListItemButton = styled(MuiListItemButton)<ListItemButtonProps>`
  display: inline-block;
  width: auto;
  padding-left: ${(props) => props.theme.spacing(2)};
  padding-right: ${(props) => props.theme.spacing(2)};

  &,
  &:hover,
  &:active {
    color: #ff0000;
  }
`;

const ListItemText = styled(MuiListItemText)`
  span {
    color: ${(props) => props.theme.footer.color};
  }
`;

function Footer() {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <Wrapper>
      <Grid container spacing={0}>
        <Grid sx={{ display: { xs: "none", md: "block" } }} container item xs={12} md={6}>
          {TITLE === "Auexpress" && (
            <List>
              <ListItemButton onClick={() => setModalOpen(true)}>
                <ListItemText primary="Help Center" />
              </ListItemButton>
            </List>
          )}
        </Grid>
        <Grid container item xs={12} md={6} justifyContent="flex-end">
          <List>
            <ListItemButton>
              <ListItemText primary={`© ${new Date().getFullYear()} - ${TITLE} WMS`} />
            </ListItemButton>
          </List>
        </Grid>
      </Grid>
      <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
        <DialogTitle>Help Center</DialogTitle>
        <DialogContent>
          <Card variant="outlined" sx={{ my: 3 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Warehouse Operation Team
              </Typography>
              <Link href="mailto:alan.yan@auexpress.com.au" underline="none">
                <Typography variant="subtitle2">alan.yan@auexpress.com.au</Typography>
              </Link>
            </CardContent>
          </Card>
          <Card variant="outlined" sx={{ my: 3 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                IT Support
              </Typography>
              <Link href="mailto:philip.wu@auexpress.com.au" underline="none">
                <Typography variant="subtitle2">philip.wu@auexpress.com.au</Typography>
              </Link>
            </CardContent>
          </Card>
        </DialogContent>
      </Dialog>
    </Wrapper>
  );
}

export default Footer;
