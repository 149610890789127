import React, { useState, useEffect, Dispatch, SetStateAction, Fragment } from "react";
import styled from "@emotion/styled";
import { Box, CircularProgress, Grid, Tab, Stepper, Step, StepLabel } from "@mui/material";
import { StepIconProps } from "@mui/material/StepIcon";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import API from "../../../utils/API";
import Div from "../../../components/DivSx";
import { formatDateISO } from "../../../utils";
import { ReactImageGalleryItem } from "../../../components/PicPreview";
// import Table from "../../../components/table";
import TableItemList from "../LocalDeliveryConsignment/TableItemList";
import useAuth from "../../../hooks/useAuth";
import {
  AVAILABLE_SUBTABLE_COLUMN_CONSIGNMENT,
  DEFAULT_SUBTABLE_COLUMN_SETTING_CONSIGNMENT,
} from "../LocalDeliveryConsignment/tableColumn";
import { Check } from "@mui/icons-material";
import { minWidth } from "@mui/system";

const QontoStepIconRoot = styled("div")<{ ownerState: { active?: boolean } }>(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#784af4",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#fff",
    backgroundColor: theme.palette.secondary.main,
    width: "18px",
    height: "18px",
    borderRadius: "50%",
    zIndex: 1,
    fontSize: 6,
  },
  "& .QontoStepIcon-circle": {
    width: "18px",
    height: "18px",
    borderRadius: "50%",
    backgroundColor: "#e0e0e0",
  },
}));

const TableDetail = (props: {
  row: Record<string, any>;
  // setPodList: Dispatch<SetStateAction<ReactImageGalleryItem[]>>;
}) => {
  const { row } = props;
  const { uiSetting, setUISetting } = useAuth();
  const [value, setValue] = useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <TabContext value={value}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <TabList onChange={handleChange}>
          <Tab label="Order Track" value="1" />
          <Tab label="Items" value="2" />
        </TabList>
      </Box>
      <TabPanel value="1" sx={{ minWidth: 730 }}>
        <PanelTrack open={value === "1"} row={row} />
      </TabPanel>
      <TabPanel value="2" sx={{ minHeight: 435 }}>
        <TableItemList
          data={row.ItemList}
          hover
          size="small"
          columnConfig={{
            availableColumn: AVAILABLE_SUBTABLE_COLUMN_CONSIGNMENT,
            columnSetting: uiSetting.columnConsignmentDetails,
            defaultSetting: DEFAULT_SUBTABLE_COLUMN_SETTING_CONSIGNMENT,
          }}
        />
      </TabPanel>
    </TabContext>
  );
};

function QontoStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? <div className="QontoStepIcon-completedIcon" /> : <div className="QontoStepIcon-circle" />}
    </QontoStepIconRoot>
  );
}
// function QontoStepIcon2(props: StepIconProps) {
//   const { active, completed, className } = props;

//   return (
//     <QontoStepIconRoot ownerState={{ active }} className={className}>
//       {completed ? <Check className="QontoStepIcon-completedIcon" /> : <div className="QontoStepIcon-circle" />}
//     </QontoStepIconRoot>
//   );
// }

// Initialized
// Confirmed
// Collected
// In Transit
// Delivered

const PanelTrack = (props: {
  row: Record<string, any>;
  open: boolean;
  // setPodList: Dispatch<SetStateAction<ReactImageGalleryItem[]>>;
}) => {
  const { row, open } = props;
  let orderId = row.OrderId;
  let order_id_Status = row.id_Status;
  const [tracking, setTracking] = useState<Record<string, any>[]>([]);
  const [pods, setPods] = useState<Record<string, any>[]>([]);
  const [loaded, setLoaded] = useState(false);
  const [activeStepIndex, setActiveStepIndex] = useState(0);

  useEffect(() => {
    if (orderId && open) {
      setLoaded(false);
      API.get(`/DeliveryOrder/TrackOrder?OrderID=${orderId}&lang=EN`, null).then((res) => {
        setLoaded(true);
        if (res && res.data && res.data.Code === 0) {
          setTracking(
            // res.data.TrackList.sort((a: Record<string, any>, b: Record<string, any>) =>
            //   a.TrackTime < b.TrackTime ? -1 : 1
            // )
            res.data.TrackList
          );
          setPods(res.data.PODList);
        } else {
          // setErrors(res.data.Errors);
        }
      });
    }
  }, [orderId, open]);

  // 1001	运单创建	Order Created
  // 1011	确认发货	Shipping Confirmed
  // 1028	货物入仓	Order Inbound
  // 1042	派送途中	In Transit
  // 1046	CP派送途中	Out For Delivery
  // 1050	签收成功	Delivered

  // 1	Initialized
  // 10	Confirmed
  // 15	Partial Collected
  // 20	Collected
  // 30	In Transit
  // 80	Out For Delivery
  // 88	Delivered
  useEffect(() => {
    switch (order_id_Status) {
      case 1:
      case 10:
        setActiveStepIndex(1);
        break;
      case 20:
        setActiveStepIndex(2);
        break;
      case 30:
        setActiveStepIndex(3);
        break;
      case 80:
        setActiveStepIndex(4);
        break;
      case 88:
        setActiveStepIndex(5);
        break;
      default:
        break;
    }
  }, [order_id_Status]);

  return (
    <Grid container sx={{ padding: "20px 30px" }}>
      <Grid
        item
        xs={4}
        sx={{
          padding: "0 30px",
          height: "370px",
          overflowY: "auto",
          scrollbarGutter: "stable",
          "::-webkit-scrollbar": {
            width: 10,
            background: "rgba(33,33,33,0.1)",
            borderRadius: "10px",
          },
          "::-webkit-scrollbar-thumb": {
            borderRadius: "10px",
            background: (theme) => theme.palette.secondary.main,
          },
          ":hover": {
            overflowY: "auto",
          },
        }}
      >
        {loaded ? (
          tracking ? (
            <Stepper
              activeStep={activeStepIndex}
              orientation="vertical"
              sx={{
                " .MuiStepConnector-root": {
                  marginLeft: "8px",
                },
              }}
            >
              {tracking.map((step, index) => (
                <Step key={`tracking_${orderId}_${index}`}>
                  <StepLabel StepIconComponent={QontoStepIcon}>
                    {step.TrackStatus}
                    <br />
                    {step.TrackTime ? formatDateISO(step.TrackTime, "m") : ""}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          ) : (
            "failed"
          )
        ) : (
          <Div sx={{ justifyContent: "center", alignItems: "center", display: "flex", minHeight: "100%" }}>
            <CircularProgress color="secondary" />
          </Div>
        )}
      </Grid>
      {/* <Grid item xs={8} sx={{ padding: "0 30px", display: "flex" }}>
        {pods.map((e, i) => (
          <img
            key={`pod_${orderId}_${i}`}
            src={e.PODThumbSignedUrl}
            alt=""
            width="150"
            style={{ margin: "0 10px", cursor: "pointer" }}
            onClick={() => {
              setPodList(
                pods.map((e) => ({
                  original: e.PODSignedUrl,
                  thumbnail: e.PODThumbSignedUrl,
                  originalWidth: 100,
                }))
              );
            }}
          />
        ))}
      </Grid> */}
    </Grid>
  );
};

export default TableDetail;
