import { QueryConditionField } from "../../../components/table";

export const QUERY_FIELDS_OUT_REQ: QueryConditionField[] = [
  {
    id: "SalesOrderId",
    label: "Sales Order",
    type: "text",
  },
  {
    id: "ShipmentOrderId",
    label: "Shipment Order",
    type: "text",
  },
  {
    id: "StartDate",
    label: "From",
    type: "date",
  },
  {
    id: "EndDate",
    label: "To",
    type: "date",
  },
  {
    id: "Type",
    label: "Type",
    type: "text",
  },
];
