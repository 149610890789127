import { useState } from "react";
import { TableColumn } from "../../../components/table";
import moment from "moment";
import { CircularProgress, Button, Chip } from "@mui/material";
import Div from "../../../components/DivSx";
import API from "../../../utils/API";

const Price = (props: { orderId: string }) => {
  const { orderId } = props;
  const [load, setLoad] = useState(0);
  const [price, setPrice] = useState("");
  const [eta, setEta] = useState("");
  const handleClick = (ev: React.MouseEvent<HTMLElement>) => {
    ev.stopPropagation();
    setLoad(1);
    API.get(`/DeliveryOrder/CheckOrderPrice?OrderID=${orderId}&lang=EN`, null).then((res) => {
      if (res.data && res.data.Code === 0) {
        setPrice("$" + res.data.TotalAmt);
        setLoad(2);
        if (res.data.ETA) {
          setEta(moment(res.data.ETA).format("DDMMM"));
        }
      }
    });
  };

  const Content = () => {
    return (
      <>
        {price}
        {eta && <div>ETA: {eta}</div>}
      </>
    );
  };

  return (
    <Div sx={{ textAlign: "left" }}>
      {load === 1 ? (
        <CircularProgress size={22} />
      ) : load === 2 ? (
        <Content />
      ) : (
        <Button style={{ padding: 0 }} onClick={handleClick}>
          Show
        </Button>
      )}
    </Div>
  );
};

export const AVAILABLE_COLUMN_CONSIGNMENT: TableColumn[] = [
  {
    id: "InitTime",
    dataIndex: "InitTime",
    header: "Created",
    render: (obj) => moment(obj).format("yyyy-MM-DD"),
  },
  {
    id: "OrderId",
    dataIndex: "OrderId",
    header: "Order Id",
  },
  {
    id: "Status",
    dataIndex: "Status",
    header: "Status",
    // render: (value, row) => {
    //   return <Chip label={value} variant="outlined" color="primary" size="small" />;
    // },
  },
  {
    id: "ReceiverName",
    dataIndex: "ReceiverName",
    header: "Receiver Name",
  },
  {
    id: "ReceiverPhone",
    dataIndex: "ReceiverPhone",
    header: "Receiver Phone",
  },
  {
    id: "ReceiverAddr1",
    dataIndex: "ReceiverAddr1",
    header: "Receiver Addr",
  },
  {
    id: "ReceiverSuburb",
    dataIndex: "ReceiverSuburb",
    header: "Suburb",
  },
  {
    id: "TotalCnt",
    dataIndex: "TotalCnt",
    header: "Qty",
  },
  {
    id: "TotalVolume",
    dataIndex: "TotalVolume",
    header: "Volume",
  },
  {
    id: "TotalWeight",
    dataIndex: "TotalWeight",
    header: "Weight",
  },
  {
    id: "Reference1",
    dataIndex: "Reference1",
    header: "Reference1",
  },
  {
    id: "Manifest",
    dataIndex: "Manifest",
    header: "Manifest",
  },
  {
    id: "OrderContent",
    dataIndex: "OrderContent",
    header: "Content",
  },
  {
    id: "id",
    dataIndex: "OrderId",
    header: "Price",
    style: { minWidth: "110px" },
    render: (value, row) => {
      return <Price orderId={value} />;
    },
  },
  {
    id: "DangerousGoods",
    dataIndex: "DangerousGoods",
    header: "Dangerous Goods",
    render: (value) => {
      return value ? "Y" : "N";
    },
  },
  {
    id: "UserNotes",
    dataIndex: "UserNotes",
    header: "Notes",
  },
];

export const DEFAULT_COLUMN_SETTING_CONSIGNMENT: string[] = [
  "InitTime",
  "OrderId",
  "ReceiverName",
  "ReceiverAddr1",
  "ReceiverSuburb",
  "TotalCnt",
  "TotalVolume",
  "TotalWeight",
  "Manifest",
];

export const AVAILABLE_SUBTABLE_COLUMN_CONSIGNMENT: TableColumn[] = [
  {
    id: "ItemID",
    dataIndex: "ItemID",
    header: "Item ID",
  },
  {
    id: "Length",
    dataIndex: "Length",
    header: "Length",
  },
  {
    id: "Width",
    dataIndex: "Width",
    header: "Width",
  },
  {
    id: "Height",
    dataIndex: "Height",
    header: "Height",
  },
  {
    id: "Volume",
    dataIndex: "Volume",
    header: "Volume",
  },
  {
    id: "Weight",
    dataIndex: "Weight",
    header: "Weight",
  },
  {
    id: "ItemContent",
    dataIndex: "ItemContent",
    header: "Content",
  },
];

export const DEFAULT_SUBTABLE_COLUMN_SETTING_CONSIGNMENT: string[] = [
  "ItemID",
  "Length",
  "Width",
  "Height",
  "Volume",
  "Weight",
  "ItemContent",
];
