import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField as MuiTextField,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import API from "../../../utils/API";
import { useTheme } from "@mui/material/styles";
import { useApp } from "../../../contexts/AppContext";
import ItemListRow, { CargoItemType } from "./ItemListRow";
import useAuth from "../../../hooks/useAuth";
import moment from "moment";

const TextField = styled(MuiTextField)`
  margin: 4px;
`;

interface ModalCreateProps {
  open: boolean;
  onClose: Function;
}
const ModalCreate = (props: ModalCreateProps) => {
  const { open, onClose } = props;
  const { user } = useAuth();
  const [error, setError] = useState<Record<string, any>>({});
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [itemList, setItemList] = useState<CargoItemType[]>([
    { Length: 10, Width: 10, Height: 10, Weight: 1, ItemContent: "" },
  ]);

  const [orderData, setOrderData] = useState<Record<string, any>>({
    // OrderId: "",
    id_User: user?.id_User,
    ReceiverName: "",
    ReceiverPhone: "",
    ReceiverEmail: "",
    ReceiverCompany: "",
    ReceiverAddr1: "",
    ReceiverAddr2: "",
    ReceiverSuburb: "",
    ReceiverState: "",
    ReceiverPostCode: "",
    SenderName: "",
    SenderPhone: "",
    SenderEmail: "",
    SenderCompany: "",
    SenderAddr1: "",
    SenderAddr2: "",
    SenderSuburb: "",
    SenderState: "",
    SenderPostCode: "",
    TotalCnt: 1,
    TotalWeight: 1,
    TotalVolume: 0.001,
    OrderContent: "",
    ItemList: [],
    DangerousGoods: "",
    Manifest: moment(new Date()).format("YYYYMMDD"),
    Reference1: "",
    DeliverInstruction: "",
  });
  const theme = useTheme();
  const { setMessageBox } = useApp();

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = () => {
    // console.log(orderData);
    // return;

    let valid = checkValid();
    if (!valid) {
      setErrorMsg("Please input correct information!");
      return;
    }

    if (orderData.DangerousGoods === "Y") {
      orderData.DangerousGoods = true;
    } else {
      orderData.DangerousGoods = false;
    }

    // Change item unit from cm to meter
    let itemMeterUnitList: CargoItemType[] = [];
    itemList.forEach((item) => {
      let newItem: CargoItemType = { ...item }; //Clone an object
      newItem.Length = item.Length / 100;
      newItem.Width = item.Width / 100;
      newItem.Height = item.Height / 100;
      itemMeterUnitList.push(newItem);
    });
    orderData.ItemList = itemMeterUnitList;

    API.post("/DeliveryOrder/CreateOrder", orderData).then((res) => {
      if (res.data && res.data.Code === 0) {
        setMessageBox({
          msg: "",
          title: `1 order created`,
          open: true,
          confirmText: "OK",
          cancelText: null,
          onConfirm: async () => {
            //
          },
        });

        onClose(true);
      } else {
        setErrorMsg(res.data.Message);
      }
    });
  };

  useEffect(() => {
    // Calculate Total
    let totalVolume: number = 0;
    let totalWeight: number = 0;
    let uniqueContents: string[] = [];
    itemList.forEach((item: CargoItemType) => {
      totalWeight = +totalWeight + +item.Weight;
      let itemVol = (item.Length * item.Width * item.Height) / 1000000;
      totalVolume = +totalVolume + +itemVol;
      if (!uniqueContents.includes(item.ItemContent)) {
        uniqueContents.push(item.ItemContent);
      }
    });
    setOrderData({
      ...orderData,
      TotalWeight: totalWeight,
      TotalVolume: totalVolume,
      TotalCnt: itemList.length,
      OrderContent: uniqueContents.join(","),
    });
  }, [itemList]);

  const validationRules: Record<string, any> = {
    // OrderId: (v) => true,
    // ShipmentContent: (v) => v.trim().length > 0,
    SenderName: (v: string): boolean => v.trim().length > 0,
    SenderPhone: (v: string): boolean => /^\d+$/.test(v),
    SenderAddr1: (v: string): boolean => v.trim().length > 0,
    SenderSuburb: (v: string): boolean => v?.trim().length > 0,
    SenderState: (v: string): boolean => v?.trim().length > 0,
    SenderPostCode: (v: string): boolean => /^\d+$/.test(v) && v.trim().length === 4,
    ReceiverPostCode: (v: string): boolean => /^\d+$/.test(v) && v.trim().length === 4,
    ReceiverName: (v: string): boolean => v.trim().length > 0,
    // ReceiverPhone: (v: string): boolean => /^1\d{10}$/.test(v) || /^09\d{8}$/.test(v) || /^(\+|)852\d{8}$/.test(v),
    ReceiverPhone: (v: string): boolean => /^\d+$/.test(v),
    ReceiverSuburb: (v: string): boolean => v?.trim().length > 0,
    ReceiverState: (v: string): boolean => v?.trim().length > 0,
    ReceiverAddr1: (v: string): boolean => v.trim().length > 0,
    TotalWeight: (v: number): boolean => !isNaN(v) || (v + "").trim().length === 0,
    Manifest: (v: string): boolean => v.trim().length >= 4 || v.trim().length === 0,
    DangerousGoods: (v: string): boolean => v === "Y" || v === "N",
  };

  const checkValid = (key: any = null, value: any = null) => {
    if (key) {
      if (!validationRules[key]) return true;
      const valid = validationRules[key](value);
      setError({ ...error, [key]: !valid });
      return valid;
    } else {
      let tempError: Record<string, boolean> = {};
      let result = true;
      Object.keys(validationRules).forEach((key) => {
        const valid = validationRules[key](orderData[key]);
        tempError[key] = !valid;
        if (!valid) result = false;
      });
      setError(tempError);
      return result;
    }
  };

  const changeHandler = (ev: any) => {
    //React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    // console.log(ev.target.name, ev.target.value);
    setOrderData({ ...orderData, [ev.target.name]: ev.target.value });
    checkValid(ev.target.name, ev.target.value);
  };

  const addItemRow = () => {
    setItemList((old) => {
      return [...old, { Length: 10, Width: 10, Height: 10, Weight: 1, ItemContent: "" }];
    });
  };

  const changeItemsHandler = (ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    const key = ev.target.name;
    // console.log("index", index, "key", key, "value", ev.target.value);
    setItemList((itemList) =>
      itemList.map((oneItem: any, i) => {
        if (index === i) {
          oneItem[key] = ev.target.value;
        }
        return oneItem;
      })
    );
  };

  const deleteItem = (index: number) => {
    setItemList((itemList) => itemList.filter((e, i) => i !== index));
  };

  return (
    <>
      <Dialog open={open} onClose={() => handleClose()} scroll="paper">
        <DialogTitle sx={{ margin: "auto" }}>Create a Consignment</DialogTitle>
        <DialogContent>
          <Typography variant="h6" textAlign="center" sx={{ color: "red" }} gutterBottom>
            {errorMsg}
          </Typography>
          <Typography variant="h6" gutterBottom>
            Items:
          </Typography>
          <Grid container spacing={1} sx={{ textAlign: "center" }}>
            <Grid item xs={11.1}>
              <Button size="small" fullWidth color="primary" variant="outlined" onClick={addItemRow}>
                Add Item
              </Button>
            </Grid>
            <Grid item xs={1.5}>
              L(cm)
            </Grid>
            <Grid item xs={1.5}>
              W(cm)
            </Grid>
            <Grid item xs={1.5}>
              H(cm)
            </Grid>
            <Grid item xs={1.5}>
              W(kg)
            </Grid>
            <Grid item xs={5}>
              Item Content
            </Grid>
            <Grid item xs={1}></Grid>
            {itemList.map((item, index) => (
              <ItemListRow
                key={`itemList_${index}`}
                row={item}
                length={itemList.length}
                onChange={(ev) => changeItemsHandler(ev, index)}
                onDelete={(ev) => deleteItem(index)}
              />
            ))}

            <Grid item xs={4} textAlign="left">
              Item Qty: {orderData.TotalCnt}
            </Grid>
            <Grid item xs={4} textAlign="left">
              Total Volume(m<sup>3</sup>): {orderData.TotalVolume.toFixed(3)}
            </Grid>
            <Grid item xs={4} textAlign="left">
              Total Weight(kg): {orderData.TotalWeight}
            </Grid>
            <Grid item xs={12} textAlign="left">
              Consignment Content: {orderData.OrderContent}
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ marginTop: `0`, marginBottom: `${theme.spacing(1)}` }}>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Receiver Information:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                fullWidth
                autoComplete="new-password"
                name="ReceiverName"
                value={orderData.ReceiverName}
                error={error.ReceiverName}
                label="*Receiver Name"
                onChange={(ev) => {
                  changeHandler(ev);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                fullWidth
                autoComplete="new-password"
                name="ReceiverPhone"
                value={orderData.ReceiverPhone}
                error={error.ReceiverPhone}
                label="*Receiver Phone"
                onChange={(ev) => {
                  changeHandler(ev);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                fullWidth
                autoComplete="new-password"
                name="ReceiverEmail"
                value={orderData.ReceiverEmail}
                error={error.ReceiverEmail}
                label="Receiver Email"
                onChange={(ev) => {
                  changeHandler(ev);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                fullWidth
                autoComplete="new-password"
                name="ReceiverCompany"
                value={orderData.ReceiverCompany}
                error={error.ReceiverCompany}
                label="Receiver Company"
                onChange={(ev) => {
                  changeHandler(ev);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                fullWidth
                autoComplete="new-password"
                name="ReceiverAddr1"
                value={orderData.ReceiverAddr1}
                error={error.ReceiverAddr1}
                label="*Receiver Address"
                onChange={changeHandler}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                fullWidth
                autoComplete="new-password"
                name="ReceiverAddr2"
                value={orderData.ReceiverAddr2}
                error={error.ReceiverAddr2}
                label="Receiver Address 2"
                onChange={changeHandler}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                size="small"
                fullWidth
                autoComplete="new-password"
                name="ReceiverSuburb"
                value={orderData.ReceiverSuburb}
                error={error.ReceiverSuburb}
                label="*Receiver Suburb"
                onChange={changeHandler}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small" error={error.ReceiverState} fullWidth>
                <InputLabel id="demo-select-small">*Receiver State</InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  name="ReceiverState"
                  value={orderData.ReceiverState}
                  label="Receiver State"
                  onChange={changeHandler}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="ACT">ACT</MenuItem>
                  <MenuItem value="NSW">NSW</MenuItem>
                  <MenuItem value="NT">NT</MenuItem>
                  <MenuItem value="QLD">QLD</MenuItem>
                  <MenuItem value="SA">SA</MenuItem>
                  <MenuItem value="TAS">TAS</MenuItem>
                  <MenuItem value="VIC">VIC</MenuItem>
                  <MenuItem value="WA">WA</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <TextField
                size="small"
                fullWidth
                autoComplete="new-password"
                name="ReceiverPostCode"
                value={orderData.ReceiverPostCode}
                error={error.ReceiverPostCode}
                label="*Receiver PostCode"
                onChange={changeHandler}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Sender Information:
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <TextField
                size="small"
                fullWidth
                autoComplete="new-password"
                name="SenderName"
                value={orderData.SenderName}
                error={error.SenderName}
                label="*Sender Name"
                onChange={(ev) => {
                  changeHandler(ev);
                  // debouncedRecentSenderHandler(ev);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                fullWidth
                autoComplete="new-password"
                name="SenderPhone"
                value={orderData.SenderPhone}
                error={error.SenderPhone}
                label="*Sender Phone"
                onChange={(ev) => {
                  changeHandler(ev);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                fullWidth
                autoComplete="new-password"
                name="SenderEmail"
                value={orderData.SenderEmail}
                error={error.SenderEmail}
                label="Sender Email"
                onChange={(ev) => {
                  changeHandler(ev);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                fullWidth
                autoComplete="new-password"
                name="SenderCompany"
                value={orderData.SenderCompany}
                error={error.SenderCompany}
                label="Sender Company"
                onChange={(ev) => {
                  changeHandler(ev);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                fullWidth
                autoComplete="new-password"
                name="SenderAddr1"
                value={orderData.SenderAddr1}
                error={error.SenderAddr1}
                label="*Sender Address"
                onChange={changeHandler}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                fullWidth
                autoComplete="new-password"
                name="SenderAddr2"
                value={orderData.SenderAddr2}
                error={error.SenderAddr2}
                label="Sender Address 2"
                onChange={changeHandler}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                size="small"
                fullWidth
                autoComplete="new-password"
                name="SenderSuburb"
                value={orderData.SenderSuburb}
                error={error.SenderSuburb}
                label="*Sender Suburb"
                onChange={changeHandler}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small" error={error.SenderState} fullWidth>
                <InputLabel id="demo-select-small">*Sender State</InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  name="SenderState"
                  value={orderData.SenderState}
                  label="Sender State"
                  onChange={changeHandler}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="ACT">ACT</MenuItem>
                  <MenuItem value="NSW">NSW</MenuItem>
                  <MenuItem value="NT">NT</MenuItem>
                  <MenuItem value="QLD">QLD</MenuItem>
                  <MenuItem value="SA">SA</MenuItem>
                  <MenuItem value="TAS">TAS</MenuItem>
                  <MenuItem value="VIC">VIC</MenuItem>
                  <MenuItem value="WA">WA</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <TextField
                size="small"
                fullWidth
                autoComplete="new-password"
                name="SenderPostCode"
                value={orderData.SenderPostCode}
                error={error.SenderPostCode}
                label="*Sender PostCode"
                onChange={changeHandler}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Other Information:
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small" error={error.DangerousGoods} fullWidth>
                <InputLabel id="demo-select-small">*Dangerous Goods</InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  name="DangerousGoods"
                  value={orderData.DangerousGoods}
                  label="Dangerous Goods"
                  onChange={changeHandler}
                >
                  <MenuItem value="">
                    <em>&nbsp;</em>
                  </MenuItem>
                  <MenuItem value="Y">Y</MenuItem>
                  <MenuItem value="N">N</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <TextField
                size="small"
                fullWidth
                autoComplete="new-password"
                name="Manifest"
                value={orderData.Manifest}
                error={error.Manifest}
                label="Manifest"
                onChange={changeHandler}
                title="Manifest to grouping and managing batch of orders. Must be alphabet and numbers. Minimum length 4"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                size="small"
                fullWidth
                autoComplete="new-password"
                name="Reference1"
                value={orderData.Reference1}
                error={error.Reference1}
                label="Reference1"
                onChange={changeHandler}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                size="small"
                fullWidth
                multiline
                autoComplete="new-password"
                name="DeliverInstruction"
                value={orderData.DeliverInstruction}
                error={error.DeliverInstruction}
                label="Special Instructions"
                onChange={changeHandler}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ pl: 10 }}>
          <Button variant="outlined" color="primary" onClick={handleClose}>
            CLOSE
          </Button>
          <Button variant="outlined" color="primary" onClick={handleSubmit}>
            SUBMIT
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ModalCreate;
