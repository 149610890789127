import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import styled from "@emotion/styled";
import { NavLink, useSearchParams } from "react-router-dom";
import { spacing, SpacingProps } from "@mui/system";
import { Divider as MuiDivider, Grid, Link, Paper as MuiPaper, TablePagination, Typography } from "@mui/material";
import axiosInstance from "../../../utils/axios";
import useAuth from "../../../hooks/useAuth";
import Table, { TableExpandProps } from "../../../components/table";
import {
  AVAILABLE_COLUMN_OUT_REQ,
  DEFAULT_COLUMN_SETTING_OUT_REQ,
  AVAILABLE_SUBTABLE_COLUMN_OUT_REQ,
  DEFAULT_SUBTABLE_COLUMN_SETTING_OUT_REQ,
} from "./tableColumn";
import QueryCondition, { getAxiosParamFromSearchParam } from "../../../components/table/QueryCondition";
import { QUERY_FIELDS_OUT_REQ } from "./queryFields";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Expand: React.FC<TableExpandProps> = ({ row }) => {
  const { uiSetting } = useAuth();

  return (
    <Table
      data={row.Details}
      size="small"
      columnConfig={{
        availableColumn: AVAILABLE_SUBTABLE_COLUMN_OUT_REQ,
        defaultSetting: DEFAULT_SUBTABLE_COLUMN_SETTING_OUT_REQ,
        columnSetting: uiSetting.columnOutReqDetails,
      }}
    ></Table>
  );
};

function OutboundOrder() {
  const { uiSetting, setUISetting } = useAuth();
  const [dataList, setDataList] = useState<Record<string, any>[]>([]);
  const [totalCnt, setTotalCnt] = useState<number>(0);
  const [params, setParams] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(false);

  const getDataList = () => {
    setLoading(true);
    axiosInstance
      .post("/OutboundOrder/QueryOutboundOrder", {
        ...getAxiosParamFromSearchParam(QUERY_FIELDS_OUT_REQ, params),
      })
      .then((res) => {
        setDataList(res.data.OutboundOrderList);
        setTotalCnt(res.data.Total);
      })
      .finally(() => setLoading(false));
  };

  const exportData = () =>
    axiosInstance
      .post("/OutboundOrder/QueryOutboundOrder", {
        ...getAxiosParamFromSearchParam(QUERY_FIELDS_OUT_REQ, params),
        PageSize: 100000,
        PageNum: 1,
      })
      .then((res) => {
        return res.data.OutboundOrderList as Record<string, any>[];
      });

  useEffect(getDataList, [params]);

  return (
    <React.Fragment>
      <Helmet title="Outbound Details" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Outbound Details
          </Typography>
        </Grid>
        <Grid item>
          <div></div>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item sm={12} md={10}>
          <Paper>
            <Table
              data={dataList}
              columnConfig={{
                availableColumn: AVAILABLE_COLUMN_OUT_REQ,
                columnSetting: uiSetting.columnOutReq,
                defaultSetting: DEFAULT_COLUMN_SETTING_OUT_REQ,
                handleColumnSettingChanged: (setting) => {
                  setUISetting({ columnOutReq: setting });
                },
              }}
              subTableColumnConfig={{
                availableColumn: AVAILABLE_SUBTABLE_COLUMN_OUT_REQ,
                columnSetting: uiSetting.columnOutReqDetails,
                defaultSetting: DEFAULT_SUBTABLE_COLUMN_SETTING_OUT_REQ,
                handleColumnSettingChanged: (setting) => {
                  setUISetting({ columnOutReqDetails: setting });
                },
                subDataIndex: "Details",
              }}
              title="Outbound  Details"
              loading={loading}
              exportData={exportData}
              expand={Expand}
              hover
            />
            <TablePagination
              rowsPerPageOptions={[20, 50, 100, 250]}
              component="div"
              count={totalCnt}
              rowsPerPage={parseInt(params.get("PageSize") || "10")}
              page={parseInt(params.get("PageNum") || "1") - 1}
              onPageChange={(event, page) =>
                setParams({
                  ...Object.fromEntries(params.entries()),
                  PageNum: (page + 1).toString(),
                })
              }
              onRowsPerPageChange={(event) =>
                setParams({
                  ...Object.fromEntries(params.entries()),
                  PageSize: event.target.value,
                  PageNum: "1",
                })
              }
            />
          </Paper>
        </Grid>
        <Grid item sm={12} md={2}>
          <QueryCondition queryFields={QUERY_FIELDS_OUT_REQ} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default OutboundOrder;
