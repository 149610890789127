import React, { useEffect, useState, useMemo, ChangeEvent, MouseEvent, MouseEventHandler } from "react";
import { Helmet } from "react-helmet-async";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import {
  Divider as MuiDivider,
  Grid,
  Paper as MuiPaper,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  CircularProgress,
  Button,
} from "@mui/material";
import { green, red } from "@mui/material/colors";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import API from "../../../utils/API";
import { formatDateISO } from "../../../utils";
import useAuth from "../../../hooks/useAuth";
import { DateRangePicker, DateRangeSelectedRange } from "../../../components/DateRangePicker";
import { QueryParams, TableColumn } from "../../../components/table";
import groupBy from "lodash/groupBy";
import moment from "moment";
import DashboardStats from "../../dashboard/Dashboard/Stats";
import BarChart from "./BarChart";
// import { addMonths, startOfMonth, endOfMonth } from "date-fns";
import ModalOrderList, { DynamicColumns } from "./ModalOrderList";
import { MonthlySummary, StatsOf1State } from "./types";
import DoughnutChart4States from "./DoughnutChart4States";
import ModalSearchOrder from "./ModalSearchOrder";
const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 100%;
`;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  // [`&.${tableCellClasses.head}`]: {
  //   backgroundColor: theme.palette.common.black,
  //   color: theme.palette.common.white,
  // },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    borderRight: "1px solid rgba(224, 224, 224, 1)",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  // "&:last-child td, &:last-child th": {
  //   border: 0,
  // },
}));

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

function TDashboard() {
  const { uiSetting, setUISetting } = useAuth();
  const [dataList, setDataList] = useState<Record<string, any>[]>([]);
  const [clickDataList, setClickDataList] = useState<Record<string, any>[]>([]);
  const [columnTitle, setColumnTitle] = useState<DynamicColumns>(DynamicColumns.E);

  const [totalCnt, setTotalCnt] = useState<number>(0);
  const [dashboardLoading, setDashboardLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [modalOrderListOpen, setModalOrderListOpen] = useState(false);
  const [modalSearchOrderOpen, setModalSearchOrderOpen] = useState(false);

  // const startOfLastMonth = moment().add(-1, "M").startOf("month").format("YYYY-MM-DD");
  const recentDate = moment().add(-2, "weeks").format("YYYY-MM-DD");

  const [params, setParams] = useState<QueryParams>({
    PageSize: "100000000",
    PageNum: "1", //the page sequence number
    id_Status: "0",
    StartDate: recentDate,
    // EndDate: "2023-07-20",
  });
  const [openDate, setOpenDate] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<{ startDate: Date | null; endDate: Date | null; label?: string }>({
    startDate: null,
    endDate: null,
    label: "Recent 2 weeks", //Initial Label
  });

  /**
   * getStats for the Table
   * @param orderList
   * @returns
   */
  const getStats = (orderList: Record<string, any>[]) => {
    // let lastDate: string = orderList[0].InitTime.slice(0, 10); //latest date = Now
    let firstDateStr: string = orderList[orderList.length - 1].InitTime.slice(0, 10); //2023-05-03
    let oneDay = moment(new Date());
    oneDay.add(1, "days");

    let statsList: Record<string, any>[] = [];

    while (oneDay.format("YYYY-MM-DD") !== firstDateStr) {
      oneDay.add(-1, "days");
      let oneDayStr = oneDay.format("YYYY-MM-DD");

      let resInitTime = groupBy(orderList, function (o) {
        return o.InitTime.slice(0, 10) === oneDayStr;
      }).true;

      let resConfirmTime = groupBy(resInitTime, function (o) {
        return o.ConfirmTime !== null;
      }).true;
      let resCollectTime = groupBy(resInitTime, function (o) {
        return o.CollectTime !== null;
      }).true;
      let resSentOutTime = groupBy(resInitTime, function (o) {
        return o.SentOutTime !== null;
      }).true;
      let resOutForDeliveryTime = groupBy(resInitTime, function (o) {
        return o.OutForDeliveryTime !== null;
      }).true;
      let resFinishTime = groupBy(resInitTime, function (o) {
        return o.FinishTime !== null;
      }).true;

      if (resInitTime === undefined) {
      } else {
        statsList.push({
          date: oneDayStr,
          InitTime: resInitTime,
          ConfirmTime: resConfirmTime,
          CollectTime: resCollectTime,
          SentOutTime: resSentOutTime,
          OutForDeliveryTime: resOutForDeliveryTime,
          FinishTime: resFinishTime,
        });
      }
    }
    return statsList;
  };

  const getDataList = () => {
    setLoading(true);
    API.get("/DeliveryOrder/QueryOrder", {
      ...params,
    })
      .then((res) => {
        let statsList = getStats(res.data.OrderList);
        setDataList(statsList);
        setTotalCnt(statsList.length);
      })
      .finally(() => setLoading(false));
  };

  const [dashboardData, setDashboardData] = useState<Record<string, any>>({});
  const [monthlySummary, setMonthlySummary] = useState<MonthlySummary[]>([]);
  const [statsOfStates1M, setStatsOfStates1M] = useState<StatsOf1State[]>([]); // 1 month
  const [statsOfStates12M, setStatsOfStates12M] = useState<StatsOf1State[]>([]); // 12 month

  useEffect(() => {
    setDashboardLoading(true);
    API.get("/Statistics/TMSCustomerDashboard", null)
      .then((res) => {
        if (res && res.data && res.data.Code === 0) {
          setDashboardData(res.data);
          setMonthlySummary(res.data.MonthlySummary);
          setStatsOfStates1M(res.data.OrderSummaryByState);
          setStatsOfStates12M(res.data.OrderSummaryByState12M);
        }
      })
      .finally(() => setDashboardLoading(false));
  }, []);

  useEffect(getDataList, [params]);

  const dateRangeChanged = (range: DateRangeSelectedRange) => {
    if (range.endDate && range.startDate) {
      setParams((params) => {
        return {
          ...params,
          StartDate: formatDateISO(range.startDate),
          EndDate: formatDateISO(moment(range.endDate).add(1, "day").toDate()),
        };
      });
    } else {
      setParams((params) => {
        return {
          ...params,
          StartDate: undefined,
          EndDate: undefined,
        };
      });
    }
  };

  const onNumberClickHandler = (theList: [], columnTitle: DynamicColumns) => {
    if (!theList) return;
    setClickDataList(theList);
    setColumnTitle(columnTitle);
    setModalOrderListOpen(true);
  };

  return (
    <React.Fragment>
      <Helmet title="Delivery Dashboard" />
      <Grid container justifyContent="start" alignItems="center">
        <Grid item>
          <Typography variant="h3">
            <Grid container>
              <Grid item>Delivery Dashboard</Grid>
            </Grid>
          </Typography>
        </Grid>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Grid item></Grid>
      </Grid>

      <Divider my={3} />

      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <DashboardStats
            title="Total Orders"
            amount={dashboardData.OrderCntThisMonth?.toString()}
            chip="This Month"
            percentagetext=""
            percentagecolor={red[500]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <DashboardStats
            title="Completed Orders"
            amount={dashboardData.CompletedOrderThisMonth?.toString()}
            chip="This Month"
            percentagetext=""
            percentagecolor={red[500]}
          />
        </Grid>
      </Grid>

      <Grid container spacing={6}>
        <Grid item xs={12} sm={6} alignSelf="stretch">
          <BarChart outboundData={monthlySummary} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DoughnutChart4States
            title="Orders by States"
            _1M={statsOfStates1M}
            _12M={statsOfStates12M}
            loading={dashboardLoading}
          />
        </Grid>
      </Grid>

      <Divider my={3} />
      <Grid container alignItems="center">
        <Grid item>
          <Typography variant="h5">Orders by created dates</Typography>
        </Grid>
        <Grid item>
          {dateRangeChanged && (
            <div>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => setOpenDate(true)}
                sx={{ minWidth: "210px", margin: 2 }}
              >
                {dateRange && dateRange.label ? (
                  dateRange.label
                ) : dateRange.startDate && dateRange.endDate ? (
                  <Grid container justifyContent="space-between">
                    <Grid item>{formatDateISO(dateRange.startDate)}</Grid>
                    <Grid item>to</Grid>
                    <Grid item>{formatDateISO(dateRange.endDate)}</Grid>
                  </Grid>
                ) : (
                  "All Dates"
                )}
              </Button>
              <DateRangePicker
                open={openDate}
                toggle={() => setOpenDate(!openDate)}
                onChange={(range) => {
                  dateRangeChanged(range);
                  setDateRange(range);
                  setOpenDate(false);
                }}
              />
            </div>
          )}
        </Grid>
        <Grid item>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              setModalSearchOrderOpen(true);
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Search All Orders&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={6}>
        <Grid item sm={12}>
          <Paper>
            {loading ? (
              <LoaderContainer>
                <CircularProgress />
              </LoaderContainer>
            ) : (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Date</StyledTableCell>
                      <StyledTableCell align="right">Created Orders</StyledTableCell>
                      <StyledTableCell align="right">Confirmed Orders</StyledTableCell>
                      <StyledTableCell align="right">Collected Orders</StyledTableCell>
                      <StyledTableCell align="right">In Transit Orders</StyledTableCell>
                      <StyledTableCell align="right">Out For Delivery</StyledTableCell>
                      <StyledTableCell align="right">Delivered Orders</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dataList.map((row) => {
                      return (
                        <StyledTableRow key={row.date} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                          <StyledTableCell onClick={() => onNumberClickHandler(row.InitTime, DynamicColumns.Created)}>
                            {row.date}
                          </StyledTableCell>
                          <StyledTableCell
                            align="right"
                            onClick={() => onNumberClickHandler(row.InitTime, DynamicColumns.Created)}
                          >
                            {row.InitTime?.length}
                          </StyledTableCell>
                          <StyledTableCell
                            align="right"
                            onClick={() => onNumberClickHandler(row.ConfirmTime, DynamicColumns.Confirmed)}
                          >
                            {row.ConfirmTime?.length}
                          </StyledTableCell>
                          <StyledTableCell
                            align="right"
                            onClick={() => onNumberClickHandler(row.CollectTime, DynamicColumns.Collected)}
                          >
                            {row.CollectTime?.length}
                          </StyledTableCell>
                          <StyledTableCell
                            align="right"
                            onClick={() => onNumberClickHandler(row.SentOutTime, DynamicColumns.InTransit)}
                          >
                            {row.SentOutTime?.length}
                          </StyledTableCell>
                          <StyledTableCell
                            align="right"
                            onClick={() => onNumberClickHandler(row.OutForDeliveryTime, DynamicColumns.OutForDelivery)}
                          >
                            {row.OutForDeliveryTime?.length}
                          </StyledTableCell>
                          <StyledTableCell
                            align="right"
                            onClick={() => onNumberClickHandler(row.FinishTime, DynamicColumns.Delivered)}
                          >
                            {row.FinishTime?.length}
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Paper>
        </Grid>
      </Grid>
      <ModalSearchOrder open={modalSearchOrderOpen} setOpen={setModalSearchOrderOpen} />

      <ModalOrderList
        open={modalOrderListOpen}
        onClose={(refresh: boolean = false) => {
          setModalOrderListOpen(false);
          // if (refresh) getDataList();
        }}
        dataList={clickDataList}
        columnTitle={columnTitle}
      />
    </React.Fragment>
  );
}

export default TDashboard;
