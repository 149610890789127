import React from "react";
import { Outlet } from "react-router-dom";
import styled from "@emotion/styled";

import { CssBaseline } from "@mui/material";

import GlobalStyle from "../components/GlobalStyle";
import Background from "../vendor/bg-image.jpg";
import BackgroundKing from "../vendor/bg-kingfreight.jpg";
import { TITLE } from "../constants";

const Root = styled.div`
  max-width: 520px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
  flex-direction: column;
`;

const Screen = styled.div`
  min-height: 100vh;
  max-width: 520px;
  margin: 0 auto;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  height: 100vh;
  flex-direction: column;

  &:after {
    content: "";
    background-image: url(${TITLE === "Auexpress" ? Background : BackgroundKing});
    background-size: cover;
    background-position-x: 50%;
    background-position-y: 50%;
    background-repeat: no-repeat;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: ${TITLE === "Auexpress" ? 0.65 : 0.55};
    z-index: -1;
  }
`;

const Auth: React.FC = ({ children }) => {
  return (
    <Screen>
      <Root>
        <CssBaseline />
        <GlobalStyle />
        {children}
        <Outlet />
      </Root>
    </Screen>
  );
};

export default Auth;
