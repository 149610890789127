import DateRangePickerWrapper from "./DateRangePickerWrapper";
import DateRangePicker from "./DateRangePicker";

const DateRangePickerExporter = (props: DateRangePickerProps) => <DateRangePickerWrapper {...props} />;

export type DateRangeSelectedRange = {
  startDate: Date | null;
  endDate: Date | null;
  label?: string;
};

export type DateRangePickerProps = {
  open: Boolean;
  toggle: () => void;
  onChange: (range: DateRangeSelectedRange) => void;
  closeOnClickOutside?: Boolean;
  wrapperClassName?: string;
  initialDateRange?: { startDate: string | Date; endDate: string | Date };
  definedRanges?: { startDate: string | Date; endDate: string | Date };
  minDate?: string | Date;
  maxDate?: string | Date;
  locale?: string;
};

export { DateRangePickerExporter as DateRangePicker, DateRangePicker as DateRangePickerComponent };
