import React, { useEffect, useState } from "react";
import moment from "moment";
import { Helmet } from "react-helmet-async";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { Divider as MuiDivider, Grid, Paper as MuiPaper, TablePagination, Typography } from "@mui/material";
import axiosInstance from "../../../utils/axios";
import useAuth from "../../../hooks/useAuth";
import Table, { TableExpandProps } from "../../../components/table";
import { DateRangeSelectedRange } from "../../../components/DateRangePicker";
import {
  AVAILABLE_COLUMN_IN_ORDER,
  DEFAULT_COLUMN_SETTING_IN_ORDER,
  AVAILABLE_SUBTABLE_COLUMN_IN_ORDER,
  DEFAULT_SUBTABLE_COLUMN_SETTING_IN_ORDER,
} from "./tableColumn";
import { QueryParams } from "../../../components/table";
import API from "../../../utils/API";
import { formatDateISO } from "../../../utils";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Expand: React.FC<TableExpandProps> = ({ row }) => {
  const { uiSetting } = useAuth();

  return (
    <Table
      data={row.Details}
      size="small"
      columnConfig={{
        availableColumn: AVAILABLE_SUBTABLE_COLUMN_IN_ORDER,
        defaultSetting: DEFAULT_SUBTABLE_COLUMN_SETTING_IN_ORDER,
        columnSetting: uiSetting.columnInOrderDetails,
      }}
    />
  );
};

function InboundOrder() {
  const { uiSetting, setUISetting } = useAuth();
  const [dataList, setDataList] = useState<Record<string, any>[]>([]);
  const [totalCnt, setTotalCnt] = useState<number>(0);
  const [params, setParams] = useState<QueryParams>({
    PageSize: "20",
    PageNum: "1",
  });
  const [loading, setLoading] = useState<boolean>(false);

  const getDataList = () => {
    setLoading(true);
    axiosInstance
      .post("/InboundOrder/Query", {
        ...params,
      })
      .then((res) => {
        setDataList(res.data.InboundOrderList);
        setTotalCnt(res.data.Total);
      })
      .finally(() => setLoading(false));
  };

  const exportData = () =>
    axiosInstance
      .post("/InboundOrder/Query", {
        ...params,
        PageSize: 100000,
        PageNum: 1,
      })
      .then((res) => {
        return res.data.InboundOrderList as Record<string, any>[];
      });

  const dateRangeChanged = (range: DateRangeSelectedRange) => {
    if (range.endDate && range.startDate) {
      setParams((params) => {
        return {
          ...params,
          StartDate: formatDateISO(range.startDate),
          EndDate: formatDateISO(moment(range.endDate).add(1, "day").toDate()),
        };
      });
    } else {
      setParams((params) => {
        return {
          ...params,
          StartDate: undefined,
          EndDate: undefined,
        };
      });
    }
  };

  useEffect(getDataList, [params]);

  return (
    <React.Fragment>
      <Helmet title="Inbound Mng" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Inbound Mng
          </Typography>
        </Grid>
        <Grid item>
          <div></div>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item sm={12}>
          <Paper>
            <Table
              data={dataList}
              columnConfig={{
                availableColumn: AVAILABLE_COLUMN_IN_ORDER,
                columnSetting: uiSetting.columnInOrder,
                defaultSetting: DEFAULT_COLUMN_SETTING_IN_ORDER,
                handleColumnSettingChanged: (setting) => {
                  setUISetting({ columnInOrder: setting });
                },
              }}
              subTableColumnConfig={{
                availableColumn: AVAILABLE_SUBTABLE_COLUMN_IN_ORDER,
                columnSetting: uiSetting.columnInOrderDetails,
                defaultSetting: DEFAULT_SUBTABLE_COLUMN_SETTING_IN_ORDER,
                handleColumnSettingChanged: (setting) => {
                  setUISetting({ columnInOrderDetails: setting });
                },
                subDataIndex: "Details",
              }}
              title="Inbound Mng"
              loading={loading}
              exportData={exportData}
              expand={Expand}
              dateRangeChanged={dateRangeChanged}
              hover
            />
            <TablePagination
              rowsPerPageOptions={[20, 50, 100, 250]}
              component="div"
              count={totalCnt}
              rowsPerPage={parseInt(params["PageSize"] || "10")}
              page={parseInt(params["PageNum"] || "1") - 1}
              onPageChange={(event, page) =>
                setParams((param) => {
                  return { ...param, PageNum: (page + 1).toString() };
                })
              }
              onRowsPerPageChange={(event) =>
                setParams((param) => {
                  return { ...param, PageSize: event.target.value, PageNum: "1" };
                })
              }
            />
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default InboundOrder;
