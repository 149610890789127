import { Grid, Typography, Paper } from "@mui/material";
import { isSameMonth, isWithinInterval, format, isToday, getDate, isLastDayOfMonth, isFirstDayOfMonth } from "date-fns";
import { chunks, getDaysInMonth, isStartOfRange, isEndOfRange, isRangeSameDay, inDateRange } from "./Utils";
import Header from "./Header";
import Day from "./Day";

var NavigationAction;
(function (NavigationAction) {
  NavigationAction[(NavigationAction["Previous"] = -1)] = "Previous";
  NavigationAction[(NavigationAction["Next"] = 1)] = "Next";
})(NavigationAction || (NavigationAction = {}));

const Month = (props) => {
  const {
    helpers,
    handlers,
    value: date,
    dateRange,
    marker,
    setValue: setDate,
    minDate,
    maxDate,
    locale,
    hoverDay,
  } = props;
  const WEEK_DAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const [back, forward] = props.navState;
  return (
    <Paper square elevation={0} sx={{ width: 290 }}>
      <Grid container>
        <Header
          date={date}
          setDate={setDate}
          nextDisabled={!forward}
          prevDisabled={!back}
          onClickPrevious={() => handlers.onMonthNavigate(marker, NavigationAction.Previous)}
          onClickNext={() => handlers.onMonthNavigate(marker, NavigationAction.Next)}
          locale={locale}
        />
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          sx={{
            marginTop: "10px",
            paddingLeft: "30px",
            paddingRight: "30px",
          }}
        >
          {WEEK_DAYS.map((day, index) => (
            <Typography color="textSecondary" key={index} variant="caption">
              {day}
            </Typography>
          ))}
        </Grid>
        <Grid
          item
          container
          direction="column"
          justifyContent="space-between"
          sx={{
            paddingLeft: "15px",
            paddingRight: "15px",
            marginTop: "15px",
            marginBottom: "20px",
          }}
        >
          {chunks(getDaysInMonth(date, locale), 7).map((week, idx) => (
            <Grid key={idx} container direction="row" justifyContent="center">
              {week.map((day, index) => {
                const isStart = isStartOfRange(dateRange, day);
                const isEnd = isEndOfRange(dateRange, day);
                const isRangeOneDay = isRangeSameDay(dateRange);
                const highlighted = inDateRange(dateRange, day) || helpers.inHoverRange(day);
                const isHover = format(day, "yyyy-MM-dd") === format(hoverDay, "yyyy-MM-dd");
                return (
                  <Day
                    isHover={isHover}
                    startOfWeek={index === 0}
                    endOfWeek={index === 6}
                    lastOfMonth={isLastDayOfMonth(day)}
                    firstOfMonth={isFirstDayOfMonth(day)}
                    key={format(day, "yyyy-MM-dd")}
                    filled={isStart || isEnd}
                    isToday={isToday(day)}
                    highlighted={highlighted && !isRangeOneDay}
                    disabled={!isSameMonth(date, day) || !isWithinInterval(day, { start: minDate, end: maxDate })}
                    startOfRange={isStart && !isRangeOneDay}
                    endOfRange={isEnd && !isRangeOneDay}
                    onClick={() => handlers.onDayClick(day)}
                    onHover={() => handlers.onDayHover(day)}
                    value={isSameMonth(date, day) ? getDate(day) : ""}
                  />
                );
              })}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Month;
