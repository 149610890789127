import { TableColumn } from "../../../components/table";
import moment from "moment";

export const AVAILABLE_COLUMN_IN_ORDER: TableColumn[] = [
  {
    id: "InitTime",
    dataIndex: "InitTime",
    header: "Date",
    render: (obj) => moment(obj).format("yyyy-MM-DD"),
  },
  {
    id: "Reference",
    dataIndex: "Reference",
    header: "Reference",
  },
  {
    id: "DeliveryVendor",
    dataIndex: "DeliveryVendor",
    header: "Delivery Vendor",
  },
  {
    id: "DeliveryDetail",
    dataIndex: "DeliveryDetail",
    header: "Delivery Detail",
  },
  {
    id: "Type",
    dataIndex: "Type",
    header: "Type",
  },
  {
    id: "Status",
    dataIndex: "Status",
    header: "Status",
  },
  {
    id: "Notes",
    dataIndex: "Notes",
    header: "Notes",
  },
];

export const DEFAULT_COLUMN_SETTING_IN_ORDER: string[] = [
  "InitTime",
  "Reference",
  "DeliveryVendor",
  "DeliveryDetail",
  "Type",
  "Status",
  "Notes",
];

export const AVAILABLE_SUBTABLE_COLUMN_IN_ORDER: TableColumn[] = [
  {
    id: "ProductName",
    dataIndex: "Name",
    header: "Product",
  },
  {
    id: "Brand",
    dataIndex: "Brand",
    header: "Brand",
  },
  {
    id: "SKU",
    dataIndex: "SKU",
    header: "SKU",
  },
  {
    id: "Barcode",
    dataIndex: "Barcode",
    header: "Barcode",
  },
  {
    id: "Scale",
    dataIndex: "Scale",
    header: "Scale",
  },
  {
    id: "Qty",
    dataIndex: "Qty",
    header: "Piece",
  },
  {
    id: "Notes",
    dataIndex: "Notes",
    header: "Detail Notes",
  },
];

export const DEFAULT_SUBTABLE_COLUMN_SETTING_IN_ORDER: string[] = ["ProductName", "Brand", "Scale", "Qty", "Notes"];
