import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import TableDetailLocalDelivery from "../ldComponents/TableDetailLocalDelivery";

interface ModalRowDetailProps {
  open: boolean;
  row: Record<string, any>;
  setRowDetailOpen: Function;
}
const ModalRowDetail = (props: ModalRowDetailProps) => {
  const { open, setRowDetailOpen, row } = props;

  const handleClose = () => {
    setRowDetailOpen(false);
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} maxWidth="md">
      <DialogTitle sx={{ margin: "14px auto 0px 14px", p: 2 }} id="customized-dialog-title">
        {row.OrderId}, {row.ReceiverName}, {row.ReceiverSuburb} {row.ReceiverPostCode}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <TableDetailLocalDelivery row={row} />
      </DialogContent>
    </Dialog>
  );
};

export default ModalRowDetail;
