import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  DialogTitle,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import LightTooltip from "../../../components/LightTooltip";
import PicPreview, { ReactImageGalleryItem } from "../../../components/PicPreview";
import ModalRowDetail from "./ModalRowDetail";
import PreviewDocument from "../../../components/PreviewDocument";

export enum DynamicColumns {
  E = "E",
  Created = "Created",
  Confirmed = "Confirmed",
  Collected = "Collected",
  InTransit = "InTransit",
  OutForDelivery = "OutForDelivery",
  Delivered = "Delivered",
}

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 100%;
`;

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  // "&:last-child td, &:last-child th": {
  //   border: 0,
  // },
}));

let previewDocumentSrcGlobal: string | null = null;

interface ModalOrderListProps {
  open: boolean;
  onClose: Function;
  dataList: Record<string, any>[];
  columnTitle: DynamicColumns;
}
const ModalOrderList = (props: ModalOrderListProps) => {
  const { open, onClose, dataList, columnTitle } = props;
  const [rowDetailOpen, setRowDetailOpen] = useState(false);
  const [currRow, setCurrRow] = useState<Record<string, any>>({});
  const [podList, setPodList] = useState<ReactImageGalleryItem[]>([]);
  const [previewDocumentOpen, setPreviewDocumentOpen] = useState(false);

  const handleClose = () => {
    onClose();
  };

  const timeProcess = (time: string) => {
    if (time) {
      return moment(time).format("YYYY-MM-DD, hh:mm");
    } else {
      return "";
    }
  };

  const showDynamicColumnContent = (row: any) => {
    switch (columnTitle) {
      case DynamicColumns.Created:
        return row.InitTime;
      case DynamicColumns.Confirmed:
        return row.ConfirmTime;
      case DynamicColumns.Collected:
        return row.CollectTime;
      case DynamicColumns.InTransit:
        return row.SentOutTime;
      case DynamicColumns.OutForDelivery:
        return row.OutForDeliveryTime;
      case DynamicColumns.Delivered:
        return row.FinishTime;
      default:
        break;
    }
  };

  return (
    <>
      <Dialog open={open} onClose={() => handleClose()} scroll="paper" fullScreen>
        <DialogTitle sx={{ margin: "auto", width: "100%" }}>
          {columnTitle} orders: {dataList.length}
          <Button variant="outlined" color="primary" onClick={handleClose} sx={{ float: "right" }}>
            <CloseIcon />
          </Button>
        </DialogTitle>
        <DialogContent>
          <TableContainer sx={{ maxHeight: "100%" }}>
            <Table stickyHeader size="small" aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Order Id</TableCell>
                  <TableCell align="center">{columnTitle}</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Receiver Name</TableCell>
                  <TableCell align="center">Receiver Phone</TableCell>
                  <TableCell align="center">Suburb</TableCell>
                  <TableCell align="center">Volumn</TableCell>
                  <TableCell align="center">Weight</TableCell>
                  <TableCell align="center">Reference1</TableCell>
                  <TableCell align="center">Manifest</TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataList.map((row) => {
                  return (
                    <StyledTableRow
                      key={row.name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      onClick={() => {
                        setCurrRow(row);
                        setRowDetailOpen(true);
                      }}
                    >
                      <TableCell align="center">{row.OrderId}</TableCell>
                      <TableCell align="center">{timeProcess(showDynamicColumnContent(row))}</TableCell>
                      <TableCell align="center">{row.Status}</TableCell>
                      <TableCell align="center">{row.ReceiverName}</TableCell>
                      <TableCell align="center">{row.ReceiverPhone}</TableCell>
                      <TableCell align="center">{row.ReceiverSuburb}</TableCell>
                      <TableCell align="center">{row.TotalVolume}</TableCell>
                      <TableCell align="center">{row.TotalWeight}</TableCell>
                      <TableCell align="center">{row.Reference1}</TableCell>
                      <TableCell align="center">{row.Manifest}</TableCell>
                      <TableCell align="center">
                        {row.PODList.length > 0 && (
                          <LightTooltip title="Proof Of Delivery">
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              sx={{ height: 26 }}
                              onClick={async (ev) => {
                                ev.stopPropagation();
                                setPodList(
                                  row.PODList.map((e: any) => ({
                                    original: e.PODSignedUrl,
                                    thumbnail: e.PODThumbSignedUrl,
                                    originalWidth: 100,
                                  }))
                                );
                                setPreviewDocumentOpen(true);
                                previewDocumentSrcGlobal = row.PODList[0].PODSignedUrl;
                              }}
                            >
                              POD
                            </Button>
                          </LightTooltip>
                        )}
                      </TableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <PreviewDocument
            src={previewDocumentSrcGlobal}
            open={previewDocumentOpen}
            onClose={() => {
              setPreviewDocumentOpen(false);
              previewDocumentSrcGlobal = null;
            }}
            buttons={[
              {
                text: "Close",
                onClick: () => {
                  setPreviewDocumentOpen(false);
                  previewDocumentSrcGlobal = null;
                },
              },
            ]}
          />
        </DialogContent>
      </Dialog>
      <ModalRowDetail open={rowDetailOpen} setRowDetailOpen={setRowDetailOpen} row={currRow} />
    </>
  );
};

export default ModalOrderList;
