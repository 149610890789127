import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import Chart from "react-chartjs-2";

import { orange, green, red, teal, deepOrange } from "@mui/material/colors";
import {
  Card as MuiCard,
  CardContent,
  CardHeader,
  CircularProgress,
  Table,
  TableContainer,
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  TableRow as MuiTableRow,
  Typography,
  FormGroup,
  FormControlLabel,
  Switch,
  Stack,
} from "@mui/material";
import { spacing } from "@mui/system";
import Div from "../../../components/DivSx";
import { DoughnutChartProps, DoughnutChart4StatesProps, ProductState } from "./types";

const CardSpacing = styled(MuiCard)(spacing);
const Card = styled(CardSpacing)`
  height: 500px;
`;

const ChartWrapper = styled.div`
  height: 168px;
  position: relative;
`;

const DoughnutInner = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -22px;
  text-align: left;
  z-index: 0;
`;

const TableRow = styled(MuiTableRow)`
  height: 33px;
`;

const TableCell = styled(MuiTableCell)`
  padding-top: 0;
  padding-bottom: 0;
`;

const DoughnutChart = ({ theme, title, _1M, _12M, loading }: DoughnutChart4StatesProps) => {
  const [dataSource, setDataSource] = useState<ProductState[]>([]);
  const [isOneYear, setIsOneYear] = useState(true);

  useEffect(() => {
    if (isOneYear) {
      setDataSource(_12M);
    } else {
      setDataSource(_1M);
    }
  }, [_1M, _12M]);

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //true = right = 1 year
    //false = left = 1 month
    if (event.target.checked) {
      setIsOneYear(true);
      setDataSource(_12M);
    } else {
      setIsOneYear(false);
      setDataSource(_1M);
    }
  };

  const getDoughnutColors = (state: string) => {
    switch (state) {
      case "Other":
        return theme.palette.success.dark;
      case "VIC":
        return theme.palette.info.dark;
      case "NSW":
        return theme.palette.warning.dark;
      case "QLD":
        return theme.palette.error.dark;
      case "SA":
        return theme.palette.secondary.dark;
      case "WA":
        return theme.palette.primary.dark;
      case "ACT":
        return theme.palette.success.light;
      case "TAS":
        return theme.palette.info.light;
      case "NT":
        return theme.palette.warning.light;
      default:
        break;
    }
  };

  const data = {
    labels: dataSource.map((item) => item.State),
    datasets: [
      {
        data: dataSource.map((item) => item.Qty),
        backgroundColor: dataSource.map((item) => getDoughnutColors(item.State)),
        borderWidth: 4,
        borderColor: theme.palette.background.paper,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "right" as "right",
      },
    },
    cutout: "50%",
  };

  return (
    <Card mb={6}>
      <CardHeader
        title={title}
        action={
          <FormGroup sx={{ width: "230px" }}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>Recent month</Typography>
              <Switch checked={isOneYear} onChange={handleSwitchChange} inputProps={{ "aria-label": "ant design" }} />
              <Typography>Recent year</Typography>
            </Stack>
          </FormGroup>
        }
      ></CardHeader>
      <CardContent>
        {loading ? (
          <Div sx={{ height: "340px" }}>
            <Div sx={{ position: "relative", top: "50%", left: "50%", transform: "translage(-50%, -50%)" }}>
              <CircularProgress />
            </Div>
          </Div>
        ) : (
          <>
            <ChartWrapper>
              <DoughnutInner>
                <Typography variant="h4">{dataSource.reduce((sum, item) => sum + item.Qty, 0)}</Typography>
                <Typography variant="caption">Total pieces</Typography>
              </DoughnutInner>
              <Chart type="doughnut" data={data} options={options} />
            </ChartWrapper>
            <TableContainer
              sx={{
                height: "220px",
                overflowY: "auto",
                scrollbarGutter: "stable",
                "::-webkit-scrollbar": {
                  width: "10px",
                  background: "rgba(33, 33, 33, 0.1)",
                  borderRadius: "10px",
                },
                "::-webkit-scrollbar-thumb": {
                  background: "rgba(33, 33, 33, 0.3)",
                  borderRadius: "10px",
                },
              }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>State</TableCell>
                    <TableCell align="right">Qty</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataSource.map((item) => (
                    <TableRow key={`pie_tr_${item.State}`}>
                      <TableCell component="th" scope="row">
                        {item.State}
                      </TableCell>
                      <TableCell align="right">{item.Qty}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default withTheme(DoughnutChart);
