import { Dialog, DialogContent } from "@mui/material";
import ImageGallery, { ReactImageGalleryItem } from "react-image-gallery";

import "react-image-gallery/styles/css/image-gallery.css";

interface Props {
  pics: ReactImageGalleryItem[];
  open: boolean;
  onClose: () => void;
  startIndex?: number;
}
function PicPreview(props: Props) {
  const { pics, open, onClose, startIndex } = props;

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogContent>
        <ImageGallery
          items={pics}
          showBullets={false}
          showThumbnails={false}
          showPlayButton={false}
          showFullscreenButton={true}
          infinite={false}
          useBrowserFullscreen={false}
          showNav={true}
          startIndex={startIndex ? startIndex : 0}
        />
      </DialogContent>
    </Dialog>
  );
}
export type { ReactImageGalleryItem } from "react-image-gallery";

export default PicPreview;
