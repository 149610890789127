import React, { useEffect, useState, useMemo, ChangeEvent, MouseEvent, MouseEventHandler } from "react";
import moment from "moment";
import { Helmet } from "react-helmet-async";
import styled from "@emotion/styled";
import { styled as styled2, alpha } from "@mui/material/styles";
import { spacing } from "@mui/system";
import {
  Checkbox,
  FormControlLabel,
  Divider as MuiDivider,
  IconButton,
  Grid,
  Menu,
  MenuList,
  MenuItem,
  Paper as MuiPaper,
  TablePagination,
  Typography,
  MenuProps,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import API from "../../../utils/API";
import { formatDateISO } from "../../../utils";
import useAuth from "../../../hooks/useAuth";
import Table from "../ldComponents/TableLocalDelivery";
import { DateRangeSelectedRange } from "../../../components/DateRangePicker";
import { AVAILABLE_COLUMN_CONSIGNMENT, DEFAULT_COLUMN_SETTING_CONSIGNMENT } from "./tableColumn";
import { QueryParams, TableColumn } from "../../../components/table";
import LightTooltip from "../../../components/LightTooltip";
import { Upload as UploadIcon, Plus as AddIcon } from "react-feather";
import { ViewWeek as ViewWeekIcon } from "@mui/icons-material";
import ModalUpload from "./ModalUpload";
import ModalCreate from "./ModalCreate";
import { StatusObj } from "../../../types/types";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const StyledMenu = styled2((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    // minWidth: 180,
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

function LocalDeliveryConsignment() {
  const { uiSetting, setUISetting } = useAuth();
  const [dataList, setDataList] = useState<Record<string, any>[]>([]);
  const [totalCnt, setTotalCnt] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [uploadOpen, setUploadOpen] = useState(false);
  const [createOpen, setCreateOpen] = useState(false);
  const [filterAnchorEl, setFilterAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [params, setParams] = useState<QueryParams>({
    PageSize: "20",
    PageNum: "1", //the page sequence number
    id_Status: "0",
  });

  const [statusList, setStatusList] = useState<StatusObj[]>([]);
  const [anchorElStatus, setAnchorElStatus] = React.useState<null | HTMLElement>(null);
  const openStatus = Boolean(anchorElStatus);
  const handleClickStatus = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElStatus(event.currentTarget);
  };
  const handleCloseStatus = (e: any, statusId: number | null) => {
    setAnchorElStatus(null);
    if (statusId === null) return;
    setParams((param) => {
      return {
        ...param,
        PageNum: "",
        id_Status: statusId.toString(),
      };
    });
  };

  const getDisplayColumn = () => {
    let setting =
      uiSetting.columnConsignment && uiSetting.columnConsignment.length > 0
        ? uiSetting.columnConsignment
        : DEFAULT_COLUMN_SETTING_CONSIGNMENT;
    let resColumn = AVAILABLE_COLUMN_CONSIGNMENT.filter((col) => col.id && setting.includes(col.id));

    return resColumn;
  };

  const displayedColumn: TableColumn[] = useMemo(() => getDisplayColumn(), [uiSetting.columnConsignment]);

  const toggleFilter: MouseEventHandler = (e: MouseEvent<HTMLButtonElement>) => {
    setFilterAnchorEl(e.currentTarget);
  };

  const closeFilter = () => {
    setFilterAnchorEl(null);
  };

  const getDataList = () => {
    setLoading(true);
    API.get("/DeliveryOrder/QueryOrder", {
      ...params,
    })
      .then((res) => {
        setDataList(res.data.OrderList);
        setTotalCnt(res.data.TotalCnt);
      })
      .finally(() => setLoading(false));
  };

  const exportData = () =>
    API.get("/DeliveryOrder/QueryOrder", {
      ...params,
      PageSize: 100000,
      PageNum: 1,
    }).then((res) => {
      return res.data.OrderList as Record<string, any>[];
    });

  useEffect(() => {
    API.get("/Status/Query?statusType=8", null).then((res) => {
      setStatusList(res.data.StatusList);
    });
  }, []);

  useEffect(getDataList, [params]);

  const dateRangeChanged = (range: DateRangeSelectedRange) => {
    if (range.endDate && range.startDate) {
      setParams((params) => {
        return {
          ...params,
          PageNum: "",
          StartDate: formatDateISO(range.startDate),
          EndDate: formatDateISO(moment(range.endDate).add(1, "day").toDate()),
        };
      });
    } else {
      setParams((params) => {
        return {
          ...params,
          PageNum: "",
          StartDate: undefined,
          EndDate: undefined,
        };
      });
    }
  };

  const checkboxChangedHandler = (displayedColumn: TableColumn[]) => (event: ChangeEvent<HTMLInputElement>) => {
    var setting: string[] = displayedColumn.filter((c) => AVAILABLE_COLUMN_CONSIGNMENT.includes(c)).map((c) => c.id);
    if (event.target.checked) {
      if (!setting.includes(event.target.name)) {
        setting.push(event.target.name);
      }
    } else {
      setting = setting.filter((c) => c !== event.target.name);
    }
    setUISetting({ columnConsignment: setting });
  };

  return (
    <React.Fragment>
      <Helmet title="Local Delivery Consignment" />
      <Grid justifyContent="space-between" container>
        <Grid item>
          <Typography variant="h3" gutterBottom>
            <Grid container>
              <Grid item sm={12}>
                Local Delivery Consignment
              </Grid>
            </Grid>
          </Typography>
        </Grid>
        <Grid item>
          <React.Fragment>
            <LightTooltip title="Column Filter" placement="top">
              <IconButton onClick={toggleFilter}>
                <ViewWeekIcon />
              </IconButton>
            </LightTooltip>
            <Menu anchorEl={filterAnchorEl} open={Boolean(filterAnchorEl)} onClose={closeFilter}>
              <MenuList>
                {AVAILABLE_COLUMN_CONSIGNMENT.map((c, i) => (
                  <MenuItem key={c.id}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={displayedColumn.map((c) => c.id).includes(c.id)}
                          color="default"
                          onChange={checkboxChangedHandler(displayedColumn)}
                          name={c.id}
                        />
                      }
                      label={c.header || c.id}
                    />
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </React.Fragment>
        </Grid>
      </Grid>

      <Divider my={3} />

      <Grid container spacing={6}>
        <Grid item sm={12}>
          <Paper>
            <Table
              data={dataList}
              setData={setDataList}
              columnConfig={{
                availableColumn: AVAILABLE_COLUMN_CONSIGNMENT,
                columnSetting: uiSetting.columnConsignment,
                defaultSetting: DEFAULT_COLUMN_SETTING_CONSIGNMENT,
                handleColumnSettingChanged: (setting) => {
                  setUISetting({ columnConsignment: setting });
                },
              }}
              title="Delivery Orders"
              loading={loading}
              exportData={exportData}
              toolButtons={[
                {
                  label: "Status",
                  icon: <KeyboardArrowDownIcon />,
                  onClick: handleClickStatus,
                },
                {
                  label: "Upload",
                  icon: <UploadIcon />,
                  onClick: (ev) => setUploadOpen(true),
                },
                {
                  label: "Consignment",
                  icon: <AddIcon />,
                  onClick: (ev) => setCreateOpen(true),
                },
              ]}
              dateRangeChanged={dateRangeChanged}
              searchKeywords={["Order Id, Ref, Manifest"]}
              onKeywordsChanged={(keyword) =>
                setParams((param) => ({
                  ...param,
                  PageNum: "1",
                  Keyword: keyword,
                }))
              }
              params={params}
              setParams={setParams}
              hover
            />
            <TablePagination
              rowsPerPageOptions={[20, 50, 100, 250]}
              component="div"
              count={totalCnt}
              rowsPerPage={parseInt(params["PageSize"] || "10")}
              page={parseInt(params["PageNum"] || "1") - 1}
              onPageChange={(event, page) =>
                setParams((params) => {
                  return {
                    ...params,
                    PageNum: (page + 1).toString(),
                  };
                })
              }
              onRowsPerPageChange={(event) =>
                setParams((params) => {
                  return {
                    ...params,
                    PageSize: event.target.value,
                    PageNum: "1",
                  };
                })
              }
            />
          </Paper>
        </Grid>
      </Grid>
      <ModalUpload
        open={uploadOpen}
        onClose={(refresh: boolean = false) => {
          setUploadOpen(false);
          if (refresh) getDataList();
        }}
      />
      <ModalCreate
        open={createOpen}
        onClose={(refresh: boolean = false) => {
          setCreateOpen(false);
          if (refresh) getDataList();
        }}
      />
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorElStatus}
        open={openStatus}
        onClose={(e) => handleCloseStatus(e, null)}
      >
        <MenuItem onClick={(e) => handleCloseStatus(e, 0)} key={0}>
          All
        </MenuItem>
        {statusList.map((item) => {
          return (
            <MenuItem onClick={(e) => handleCloseStatus(e, item.id)} key={item.id}>
              {item.Status}
            </MenuItem>
          );
        })}
      </StyledMenu>
    </React.Fragment>
  );
}

export default LocalDeliveryConsignment;
