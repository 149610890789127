// Themes
export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
};

export const TITLE = window.location.host === "3pl.kingfreight.com.au" ? "KingFreight" : "Auexpress";
// export const TITLE = window.location.host === "3pl.kingfreight.com.au" ? "Auexpress" : "KingFreight";

// export const API_URL = "http://localhost:44303/api";

export const API_URL = /localhost/.test(window.location.host) ? "http://localhost:8005/api" : "/api";

export const FAVICON = window.location.host === "3pl.kingfreight.com.au" ? "/faviconKF.ico" : "/favicon.ico";
