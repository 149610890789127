import { Grid, IconButton, TextField } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

export interface CargoItemType {
  Length: number;
  Width: number;
  Height: number;
  Weight: number;
  ItemContent: string;
}

interface ItemListRowProps {
  row: CargoItemType;
  length: number;
  onDelete: React.MouseEventHandler<HTMLElement>;
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}
const ItemListRow = (props: ItemListRowProps) => {
  const { row, length, onDelete, onChange } = props;

  return (
    <>
      <Grid item xs={1.5}>
        <TextField
          type="number"
          size="small"
          fullWidth
          inputProps={{ style: { textAlign: "center", WebkitAppearance: "none" } }}
          name="Length"
          value={row.Length}
          onChange={onChange}
        />
      </Grid>
      <Grid item xs={1.5}>
        <TextField
          type="number"
          size="small"
          fullWidth
          inputProps={{ style: { textAlign: "center", WebkitAppearance: "none" } }}
          name="Width"
          value={row.Width}
          onChange={onChange}
        />
      </Grid>
      <Grid item xs={1.5}>
        <TextField
          type="number"
          size="small"
          fullWidth
          inputProps={{ style: { textAlign: "center", WebkitAppearance: "none" } }}
          name="Height"
          value={row.Height}
          onChange={onChange}
        />
      </Grid>
      <Grid item xs={1.5}>
        <TextField
          type="number"
          size="small"
          fullWidth
          inputProps={{ style: { textAlign: "center" } }}
          name="Weight"
          value={row.Weight}
          onChange={onChange}
        />
      </Grid>
      <Grid item xs={5}>
        <TextField
          type="text"
          size="small"
          fullWidth
          inputProps={{ style: { textAlign: "center" } }}
          name="ItemContent"
          value={row.ItemContent}
          onChange={onChange}
        />
      </Grid>
      <Grid item xs={1}>
        <IconButton disabled={length <= 1} color="error" onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
      </Grid>
    </>
  );
};
export default ItemListRow;
