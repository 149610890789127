import React from "react";
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Worker, Viewer, SpecialZoomLevel } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

import ImageGallery from "react-image-gallery";

import "react-image-gallery/styles/css/image-gallery.css";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

const PreviewDocument = ({ open, src, buttons, onClose }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => {
      return [];
    },
  });

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      {/\.pdf/g.test(src) ? (
        <DialogContent>
          {isMobile ? (
            <div style={{ height: "100%" }}>
              <Worker workerUrl="/vendor/pdf.worker.js">
                <Viewer
                  fileUrl={src}
                  plugins={[defaultLayoutPluginInstance]}
                  defaultScale={SpecialZoomLevel.PageWidth}
                />
              </Worker>
            </div>
          ) : (
            <iframe
              style={{ width: "100%", height: "70vh" }}
              frameBorder="0"
              type="application/pdf"
              scrolling="auto"
              title="Document Preview"
              id="document_preview"
              src={src}
            ></iframe>
          )}
        </DialogContent>
      ) : (
        <DialogContent>
          <ImageGallery
            items={[{ original: src, originalWidth: "100%" }]}
            showBullets={false}
            showThumbnails={false}
            showPlayButton={false}
            showFullscreenButton={false}
            infinite={false}
            useBrowserFullscreen={false}
            showNav={false}
          />
        </DialogContent>
      )}
      <DialogActions>
        {buttons
          ? buttons.map((b) => (
              <Button key={b.text} onClick={b.onClick} color={b.color} autoFocus={b.autoFocus}>
                {b.text}
              </Button>
            ))
          : ""}
      </DialogActions>
    </Dialog>
  );
};

export default PreviewDocument;
