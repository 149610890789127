import React from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import { Paper, Typography } from "@mui/material";

import { ReactComponent as Logo } from "../../vendor/logo.svg";
import SignInComponent from "../../components/auth/SignIn";
import LogoKing from "../../vendor/logo-kingfreight.jpg";

import { TITLE } from "../../constants";

const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 240px;
  height: 160px;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};
  padding-bottom: ${(props) => props.theme.spacing(12)};
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
    padding-top: 0;
    padding-bottom: ${(props) => props.theme.spacing(16)};
  }
`;

function SignIn() {
  return (
    <React.Fragment>
      <Wrapper>
        <Helmet title="Sign In" />
        {TITLE === "Auexpress" ? <Brand /> : <img alt="" src={LogoKing} width={320} />}
        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Welcome back to {TITLE} WMS!
        </Typography>
        <Typography component="h2" variant="body1" align="center" gutterBottom>
          Sign in to your account to continue
        </Typography>
        <SignInComponent />
      </Wrapper>
    </React.Fragment>
  );
}

export default SignIn;
