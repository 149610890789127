import React from "react";

import async from "./components/Async";

// All pages that rely on 3rd party components (other than MUI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";

// Guards
import AuthGuard from "./components/guards/AuthGuard";

// Auth components
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import ResetPassword from "./pages/auth/ResetPassword";
import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";

import { Navigate } from "react-router-dom";

import Dashboard from "./pages/dashboard/Dashboard";
import StockOnHand from "./pages/stockOnHand/StockOnHand";
import AdvancedShipmentNotice from "./pages/asn/AdvancedShipmentNotice";
import OutboundRequest from "./pages/outbound/OutboundRequest";
import InboundOrder from "./pages/inbound/InboundOrder";
import OutboundOrder from "./pages/outbound/OutboundOrder";
import LocalDeliveryConsignment from "./pages/ld/LocalDeliveryConsignment";
import MyProfile from "./pages/profile/MyProfile";
import TDashboard from "./pages/ld/TDashboard";

const routes = [
  {
    path: "/",
    element: <Navigate to="profile" />,
  },
  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Dashboard />,
      },
    ],
  },
  {
    path: "stock-on-hand",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <StockOnHand />,
      },
    ],
  },
  {
    path: "asn",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <AdvancedShipmentNotice />,
      },
    ],
  },
  {
    path: "in-order",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <InboundOrder />,
      },
    ],
  },
  {
    path: "out-req",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <OutboundRequest />,
      },
    ],
  },
  {
    path: "out-order",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <OutboundOrder />,
      },
    ],
  },
  {
    path: "tdashboard",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <TDashboard />,
      },
    ],
  },
  {
    path: "local-delivery",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <LocalDeliveryConsignment />,
      },
    ],
  },
  {
    path: "profile",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <MyProfile />,
      },
    ],
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
      {
        path: "sign-up",
        element: <SignUp />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
