import React from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import Chart from "react-chartjs-2";
import { MoreVertical } from "react-feather";
import { rgba } from "polished";

import moment from "moment";
import { Card as MuiCard, CardContent, CardHeader, CircularProgress } from "@mui/material";
import { spacing } from "@mui/system";
import { BarChart2Props } from "./types";

import Div from "../../../components/DivSx";

const CardSpacing = styled(MuiCard)(spacing);
const Card = styled(CardSpacing)`
  height: 500px;
`;

const ChartWrapper = styled.div`
  height: 340px;
  width: 100%;
`;

const BarChart2 = ({ theme, title, boundData }: BarChart2Props) => {
  const color1 = theme.palette.primary.main;
  // const color2 = rgba(theme.palette.secondary.main, 0.33);
  const color2 = theme.palette.success.main;
  const color3 = theme.palette.error.main;
  const color4 = theme.palette.secondary.light;

  const data = {
    labels: boundData.map((item) => moment(item.date, "YYYY-MM-DD").format("MMM")),
    datasets: [
      {
        label: "Piece",
        backgroundColor: color1,
        borderColor: color1,
        hoverBackgroundColor: color1,
        hoverBorderColor: color1,
        data: boundData.map((item) => item.Piece),
        barPercentage: 0.9,
        categoryPercentage: 0.7,
        borderRadius: 1,
      },
      {
        label: "Set",
        backgroundColor: color2,
        borderColor: color2,
        hoverBackgroundColor: color2,
        hoverBorderColor: color2,
        data: boundData.map((item) => item.Set),
        barPercentage: 0.9,
        categoryPercentage: 0.7,
        borderRadius: 1,
      },
      {
        label: "Carton",
        backgroundColor: color3,
        borderColor: color3,
        hoverBackgroundColor: color3,
        hoverBorderColor: color3,
        data: boundData.map((item) => item.Carton),
        barPercentage: 0.9,
        categoryPercentage: 0.7,
        borderRadius: 1,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top" as const,
      },
    },
    scales: {
      y: {
        grid: {
          display: false,
        },
        ticks: {
          stepSize: 20,
          fontColor: theme.palette.text.secondary,
        },
      },
      x: {
        grid: {
          color: "transparent",
        },
      },
    },
  };

  return (
    <Card mb={6}>
      <CardHeader title={title} />
      <CardContent>
        <ChartWrapper>
          {boundData.length > 0 ? (
            <Chart type="bar" data={data} options={options} />
          ) : (
            <Div sx={{ position: "relative", top: "50%", left: "50%", transform: "translage(-50%, -50%)" }}>
              <CircularProgress />
            </Div>
          )}
        </ChartWrapper>
      </CardContent>
    </Card>
  );
};

export default withTheme(BarChart2);
