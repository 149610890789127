import React, { useState, useEffect, useMemo } from "react";
import styled from "@emotion/styled";
import {
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Upload as UploadIcon } from "react-feather";
import API from "../../../utils/API";
import { useApp } from "../../../contexts/AppContext";

const DeliveryOrderTemplate = "/download/DeliveryOrderTemplate_v230713.xlsx";

type ModalUploadType = {
  open: boolean;
  onClose: (refresh: boolean) => void;
};

const ModalUpload = (props: ModalUploadType) => {
  const { open, onClose } = props;
  const [consignments, setConsignments] = useState<Record<string, any>[]>([]);
  const [uploading, setUploading] = useState(false);
  const [finished, setFinished] = useState(false);
  const [errors, setErrors] = useState<Record<string, any>[]>([]);
  const { setMessageBox } = useApp();

  const handleFile = (ev: React.ChangeEvent<HTMLInputElement>) => {
    if (ev.target.files && ev.target.files[0]) {
      setUploading(true);
      const file = ev.target.files[0];
      API.uploadFileByMultipart(`/DeliveryOrder/ImportExcel`, file).then((res) => {
        setUploading(false);
        if (res.data.Code === 0 && res.data.Errors.length === 0) {
          setConsignments(res.data.ReqList);
        } else {
          setErrors(res.data.Errors);
        }
      });
    }
  };

  const handleSubmit = () => {
    setUploading(true);
    API.post(`/DeliveryOrder/BatchCreateOrder`, consignments)
      .then((res) => {
        setUploading(false);
        if (res.data.Code === 0) {
          setMessageBox({
            msg: "",
            title: `${consignments.length} orders created`,
            open: true,
            confirmText: "OK",
            cancelText: null,
            onConfirm: async () => {
              //
            },
          });
          handleClose(true);
        } else {
          var cloneConsignments = [...consignments];
          var array = res.data.Errors;
          for (let index = 0; index < array.length; index++) {
            const theError = array[index];
            var order = cloneConsignments[theError.Line];
            order.result = "Failed: " + theError.ErrorMsgEn;
          }
          setConsignments(cloneConsignments);
        }
      })
      .finally(() => {
        setFinished(true);
      });
  };

  var errorData: Record<string, any>[] = [];
  errorData = useMemo(() => {
    if (finished) {
      return consignments.filter((e) => {
        if (e.result && e.result.toString().startsWith("Fail")) {
          return true;
        } else {
          return false;
        }
      });
    }
    return [];
  }, [consignments, finished]);

  const handleClose = (refresh: boolean = false) => {
    if (uploading) return false;
    if (finished) {
      if (errorData.length > 0) {
        setMessageBox({
          msg: `${errorData.length} orders failed to create! Please edit your excel file and try to create these failed orders again!`,
          title: "Warning",
          open: true,
          cancelText: "Return",
          confirmText: "OK",
          onConfirm: () => {
            reset(true);
          },
        });
        return;
      }
    } else {
      reset(refresh);
    }
  };

  const reset = (refresh: boolean = false) => {
    setUploading(false);
    setConsignments([]);
    onClose(refresh);
    setFinished(false);
  };

  return (
    <>
      <Dialog open={open} onClose={() => handleClose()} maxWidth={consignments.length === 0 ? "sm" : "lg"} fullWidth>
        <input
          id="input_file"
          type="file"
          onChange={handleFile}
          onClick={(ev: React.MouseEvent<HTMLInputElement>) => {
            (ev.target as HTMLInputElement).value = "";
          }}
          style={{ display: "none" }}
        />
        <DialogTitle>Upload Consignments</DialogTitle>
        <DialogContent
          sx={{
            overflowY: "hide",
            scrollbarGutter: "stable",
            "::-webkit-scrollbar": {
              width: 6,
              background: "rgba(33,33,33,0.1)",
            },
            "::-webkit-scrollbar-thumb": {
              borderRadius: "10px",
              background: (theme) => theme.palette.secondary.light,
            },
            ":hover": {
              overflowY: "auto",
            },
          }}
        >
          <Grid container spacing={3} justifyContent="center">
            <Grid item sx={{ textAlign: "center" }}>
              {consignments.length === 0 && (
                <>
                  <LoadingButton
                    variant="contained"
                    startIcon={<UploadIcon />}
                    loading={uploading}
                    loadingPosition="start"
                    onClick={(ev) => document.getElementById("input_file")?.click()}
                  >
                    Select file...
                  </LoadingButton>
                  <Grid item sx={{ textAlign: "center" }}>
                    <Button href={DeliveryOrderTemplate}>Download delivery order template</Button>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
          {consignments.length > 0 && (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>{finished ? "Result" : "Line"}</TableCell>
                    <TableCell align="left">Reference</TableCell>
                    <TableCell align="left">Receiver</TableCell>
                    <TableCell align="left">Address</TableCell>
                    <TableCell align="center">Suburb</TableCell>
                    <TableCell align="center">Items Cnt</TableCell>
                    <TableCell align="center">Price</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {consignments.map((row, i) => {
                    return (
                      <TableRow
                        key={`${row.Reference1}`}
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                        hover
                      >
                        <TableCell sx={{ color: row.result ? "red" : "black" }}>
                          {/* {finished ? (e.result === "成功" ? e.OrderId : e.result) : e.Line} */}
                          {finished ? (row.result ? row.result : "Created successfully") : i + 1}
                        </TableCell>
                        <TableCell align="left">{row.Reference1}</TableCell>
                        <TableCell component="th" scope="row">
                          {row.ReceiverName}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ maxWidth: 270, overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}
                        >
                          {row.ReceiverAddr1}
                        </TableCell>
                        <TableCell>{row.ReceiverSuburb}</TableCell>
                        <TableCell align="center">{row.TotalCnt}</TableCell>
                        <TableCell align="right">$ {row.PricePreview === -1 ? 99999 : row.PricePreview}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DialogContent>
        {consignments.length > 0 && (
          <DialogActions sx={{ pl: 10, display: "flex", justifyContent: "space-between" }}>
            <Typography sx={{ float: "left" }} variant="h6">
              Total : {consignments.length}
              {finished && (
                <span>
                  &nbsp;&nbsp;&nbsp;&nbsp;Created: {consignments.length - errorData.length}
                  &nbsp;&nbsp;&nbsp;&nbsp;Failed: {errorData.length}
                </span>
              )}
            </Typography>

            <div>
              {finished ? (
                <LoadingButton
                  variant="contained"
                  color="primary"
                  loading={uploading}
                  onClick={() => {
                    handleClose();
                  }}
                >
                  Finish
                </LoadingButton>
              ) : (
                <>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      setConsignments([]);
                    }}
                  >
                    Start over
                  </Button>
                  &nbsp;
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    loading={uploading}
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Submit
                  </LoadingButton>
                </>
              )}
            </div>
          </DialogActions>
        )}
      </Dialog>
      <Dialog open={errors.length > 0}>
        <DialogTitle>
          <Typography variant="h4">Errors</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="subtitle2" color="error" gutterBottom>
            There are errors in uploaded file. Please fix them and try again.
          </Typography>
          {errors.map((e) => (
            <Typography variant="body1" key={`error_${e.Line}_${e.ErrorCode}`}>
              {(e.Line > 0 ? `Line ${e.Line} -` : "") + `${e.ErrorMsgEn}` + (e.Extra ? ` - ${e.Extra}` : "")}
            </Typography>
          ))}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setErrors([])}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ModalUpload;
