import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import {
  Divider as MuiDivider,
  Grid,
  Box,
  Paper as MuiPaper,
  Typography,
  Card as MuiCard,
  CardContent,
  TextField,
  Button,
} from "@mui/material";
import API from "../../../utils/API";

const Card = styled(MuiCard)(spacing);
const Left = styled.div`
  text-align: left;
`;

const SUCCESS_MSG = "Password Changed Successfully";

const ChangePassword = () => {
  const [error, setError] = useState<Record<string, any>>({});
  const [displayMsg, setDisplayMsg] = useState<string>("");
  const [data, setData] = useState<Record<string, any>>({
    LoginID: "",
    Password: "",
    ConfirmPassword: "",
  });

  const handleSubmit = () => {
    setDisplayMsg("");

    let valid = checkValid();
    if (!valid) {
      setDisplayMsg("Please input correct information!");
      return;
    }

    API.post("/UserLogin/ChangePassword", data).then((res) => {
      if (res.data && res.data.Code === 0) {
        setDisplayMsg(SUCCESS_MSG);
      } else {
        setDisplayMsg(res.data.Message);
      }
    });
  };

  const validationRules: Record<string, any> = {
    LoginID: (v: string): boolean => v.trim().length > 0,
    Password: (v: string): boolean => v.trim().length > 3,
    ConfirmPassword: (v: string): boolean => v.trim().length > 3,
  };

  const checkValid = (key: any = null, value: any = null) => {
    if (key) {
      if (!validationRules[key]) return true;
      const valid = validationRules[key](value);
      setError({ ...error, [key]: !valid });
      return valid;
    } else {
      let tempError: Record<string, boolean> = {};
      let result = true;
      Object.keys(validationRules).forEach((key) => {
        const valid = validationRules[key](data[key]);
        tempError[key] = !valid;
        if (!valid) result = false;
      });
      setError(tempError);
      return result;
    }
  };

  const changeHandler = (ev: any) => {
    //React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    // console.log(ev.target.name, ev.target.value);
    setData({ ...data, [ev.target.name]: ev.target.value });
    checkValid(ev.target.name, ev.target.value);
  };

  return (
    <>
      <Card mb={6}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Change Password:
          </Typography>
          {/* <Spacer mb={4} /> */}
          <Typography
            variant="h6"
            textAlign="center"
            sx={{ color: displayMsg === SUCCESS_MSG ? "green" : "red" }}
            gutterBottom
          >
            &nbsp;{displayMsg}
          </Typography>
          <Left>
            <Grid container spacing={3} sx={{ textAlign: "center" }}>
              <Grid item xs={12}>
                <TextField
                  size="small"
                  fullWidth
                  autoComplete="new-password"
                  name="LoginID"
                  value={data.LoginID}
                  error={error.LoginID}
                  label="Login ID"
                  onChange={(ev) => {
                    changeHandler(ev);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type="password"
                  size="small"
                  fullWidth
                  autoComplete="new-password"
                  name="Password"
                  value={data.Password}
                  error={error.Password}
                  label="New Password"
                  onChange={(ev) => {
                    changeHandler(ev);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type="password"
                  size="small"
                  fullWidth
                  autoComplete="new-password"
                  name="ConfirmPassword"
                  value={data.ConfirmPassword}
                  error={error.ConfirmPassword}
                  label="Confirm New Password"
                  onChange={(ev) => {
                    changeHandler(ev);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  // disabled={isSubmitting}
                  style={{ marginTop: 10 }}
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Left>
        </CardContent>
      </Card>
    </>
  );
};

export default ChangePassword;
