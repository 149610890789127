import { TableColumn } from "../../../components/table";
import Div from "../../../components/DivSx";
import moment from "moment";
import { Chip } from "@mui/material";

export const AVAILABLE_COLUMN_ASN: TableColumn[] = [
  {
    id: "ETA",
    dataIndex: "ETA",
    header: "ETA",
    render: (obj) => moment(obj).format("yyyy-MM-DD"),
  },
  {
    id: "InboundDate",
    dataIndex: "InboundDate",
    header: "Inbound Date",
    render: (obj) => (obj ? moment(obj).format("yyyy-MM-DD") : ""),
  },
  {
    id: "ASNNo",
    dataIndex: "ASNNo",
    header: "ASN No.",
  },
  {
    id: "Status",
    dataIndex: "Status",
    header: "Status",
    render: (value, row) => {
      if (row.id_Status === 10) {
        return <Chip label={value} variant="outlined" color="success" size="small" />;
      } else {
        return <Chip label={value} variant="outlined" color="primary" size="small" />;
      }
    },
  },
  {
    id: "Type",
    dataIndex: "Type",
    header: "Type",
  },
  {
    id: "Notes",
    dataIndex: "Notes",
    header: "Notes",
  },
];

export const DEFAULT_COLUMN_SETTING_ASN: string[] = ["ETA", "ASNNo", "Status", "Notes"];

export const AVAILABLE_SUBTABLE_COLUMN_ASN: TableColumn[] = [
  {
    id: "SKU",
    dataIndex: ["Product", "SKU"],
    header: "SKU",
  },
  {
    id: "Barcode",
    dataIndex: ["Product", "Barcode"],
    header: "Barcode",
  },
  {
    id: "ProductName",
    dataIndex: ["Product", "Name"],
    header: "Product",
    style: { maxWidth: 250, overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" },
  },
  {
    id: "WholesaleOrder",
    dataIndex: "WholesaleOrder",
    header: "Wholesale No.",
  },
  {
    id: "BoxType",
    dataIndex: "BoxType",
    header: "Box Type",
  },
  {
    id: "BoxTypeQty",
    dataIndex: "BoxTypeQty",
    header: "Quantity",
  },
  {
    id: "ActualBoxTypeQty",
    dataIndex: "ActualBoxTypeQty",
    header: "Actual Qty",
  },
  {
    id: "ActualBoxTypeQtyGap",
    dataIndex: "ActualBoxTypeQtyGap",
    header: "Qty Gap",
    render: (value, row) => {
      let diff: string | number = +row.ActualBoxTypeQty - +row.BoxTypeQty;
      let color = "";
      if (diff > 0) {
        color = "#2e7d32";
        diff = "+" + diff;
      } else if (diff < 0) {
        color = "#d32f2f";
      }
      // return <Div sx={{ color: color }}>{diff}</Div>;
      return diff.toString();
    },
  },
  {
    id: "Qty",
    dataIndex: "Qty",
    header: "Piece",
  },
  {
    id: "ActualQty",
    dataIndex: "ActualQty",
    header: "Actual Qty",
  },
  {
    id: "ActualQtyGap",
    dataIndex: "ActualQtyGap",
    header: "Qty Gap",
    render: (value, row) => {
      let diff: string | number = +row.ActualQty - +row.Qty;
      let color = "";
      if (diff > 0) {
        color = "#2e7d32";
        diff = "+" + diff;
      } else if (diff < 0) {
        color = "#d32f2f";
      }
      // return <Div sx={{ color: color }}>{diff}</Div>;
      return diff.toString();
    },
  },
  {
    id: "Notes",
    dataIndex: "Notes",
    header: "Detail Notes",
  },
];

export const DEFAULT_SUBTABLE_COLUMN_SETTING_ASN: string[] = [
  "WholesaleOrder",
  "Brand",
  "ProductName",
  "BoxType",
  "BoxTypeQty",
  "ActualBoxTypeQty",
  "ActualBoxTypeQtyGap",
  "Qty",
  "ActualQty",
  "ActualQtyGap",
  "Diff",
];
