import React, { useEffect } from "react";
import { useRoutes } from "react-router-dom";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { CacheProvider } from "@emotion/react";

import { FAVICON } from "./constants";

import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import "./i18n";
import createTheme from "./theme";
import routes from "./routes";

import useTheme from "./hooks/useTheme";
import createEmotionCache from "./utils/createEmotionCache";
import AlertMessageBox from "./components/AlertMessageBox";
import { AuthProvider } from "./contexts/JWTContext";
import { WithAxios } from "./hocs/WithAxios";

import { TITLE } from "./constants/index";
import CategoryTransferDialog from "./components/dialog/CategoryTransferDialog";

const clientSideEmotionCache = createEmotionCache();

declare global {
  interface Window {
    theme: any;
  }
}

function App({ emotionCache = clientSideEmotionCache }) {
  const content = useRoutes(routes);
  const { theme } = useTheme();
  window.theme = createTheme(theme);
  useEffect(() => {
    let link = document.createElement("link");
    link.rel = "shortcut icon";
    document.getElementsByTagName("head")[0].appendChild(link);
    link.href = FAVICON;
  }, []);
  return (
    <CacheProvider value={emotionCache}>
      <HelmetProvider>
        <Helmet titleTemplate={`%s | ${TITLE} WMS`} defaultTitle={`${TITLE} WMS`} />
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <MuiThemeProvider theme={window.theme}>
            <AuthProvider>
              <WithAxios>
                <AlertMessageBox />
                <CategoryTransferDialog />
                {content}
              </WithAxios>
            </AuthProvider>
          </MuiThemeProvider>
        </LocalizationProvider>
      </HelmetProvider>
    </CacheProvider>
  );
}

export default App;
