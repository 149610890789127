import { IconButton, Box, Typography } from "@mui/material";

const Day = ({
  startOfRange,
  endOfRange,
  disabled,
  highlighted,
  isToday,
  filled,
  onClick,
  onHover,
  value,
  startOfWeek,
  endOfWeek,
  lastOfMonth,
  firstOfMonth,
  isHover,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        margin: "2px 0",
        borderRadius:
          startOfRange || startOfWeek || firstOfMonth
            ? "50% 0 0 50%"
            : endOfRange || endOfWeek || lastOfMonth
            ? "0 50% 50% 0"
            : undefined,
        borderTopRightRadius: isHover ? "50%" : undefined,
        borderBottomRightRadius: isHover ? "50%" : undefined,
        backgroundColor: (theme) => (!disabled && highlighted ? theme.palette.primary.light : undefined),
      }}
    >
      <IconButton
        sx={{
          height: "36px",
          width: "36px",
          padding: 0,
          border: (theme) => {
            if (!disabled) {
              if (isToday) return `1px solid ${theme.palette.primary.main}`;
            }
          },
          ...(!disabled && filled
            ? {
                "&:hover": {
                  backgroundColor: (theme) => theme.palette.primary.main,
                },
                backgroundColor: (theme) => theme.palette.primary.main,
              }
            : {}),
        }}
        disabled={disabled}
        onClick={onClick}
        onMouseOver={onHover}
      >
        <Typography
          sx={{
            lineHeight: 1.6,
            color: (theme) =>
              !disabled
                ? filled || highlighted
                  ? theme.palette.primary.contrastText
                  : theme.palette.text.primary
                : theme.palette.text.secondary,
          }}
          variant="body2"
        >
          {value}
        </Typography>
      </IconButton>
    </Box>
  );
};

export default Day;
