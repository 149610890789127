import { List, ListItem, ListItemText } from "@mui/material";
import { isSameRange } from "./Utils";

const DefinedRanges = ({ ranges, setRange, selectedRange }) => {
  return (
    <List sx={{ minWidth: "110px", fontSize: "20px" }}>
      {ranges.map((range, idx) => (
        <ListItem
          button
          key={idx}
          onClick={() => setRange(range)}
          sx={{
            "&:hover": {
              backgroundColor: (theme) => theme.palette.primary.light,
            },
            ...(isSameRange(range, selectedRange) && {
              backgroundColor: (theme) => theme.palette.primary.main,
              color: (theme) => theme.palette.primary.contrastText,
              "&:hover": {
                backgroundColor: (theme) => theme.palette.primary.main,
                color: (theme) => theme.palette.primary.contrastText,
              },
            }),
          }}
        >
          <ListItemText primaryTypographyProps={{ variant: "body2", sx: { fontWeight: "normal" } }}>
            {range.label}
          </ListItemText>
        </ListItem>
      ))}
    </List>
  );
};

export default DefinedRanges;
