import React from "react";
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import { useApp } from "../contexts/AppContext";

const AlertMessageBox = (props: any) => {
  const { msgObj, setMessageBox } = useApp();
  const { msg, title, open, onClose, onConfirm, onCancel, confirmText, cancelText, fullwidth } = msgObj;

  const closeHandler = () => {
    setMessageBox({
      msg: null,
      title: null,
      open: false,
      onClose: null,
      onConfirm: null,
      onCancel: null,
      confirmText: null,
      cancelText: null,
    });
    if (onClose) onClose();
  };
  const confirmHandler = () => {
    setMessageBox({
      msg: null,
      title: null,
      open: false,
      onClose: null,
      onConfirm: null,
      onCancel: null,
      confirmText: null,
      cancelText: null,
    });
    if (onConfirm) onConfirm();
  };

  return (
    <Dialog
      fullWidth={fullwidth == null || fullwidth}
      open={open}
      onClose={closeHandler}
      aria-labelledby="3pl_message"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="3pl_message">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" style={{ minWidth: 250 }}>
          {msg}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {cancelText && (
          <Button onClick={onCancel ? onCancel : closeHandler} variant="outlined">
            {cancelText}
          </Button>
        )}
        {confirmText && (
          <Button
            onClick={confirmHandler}
            color="primary"
            variant="contained"
            autoFocus
            sx={{ boxShadow: "none", "&:hover": { boxShadow: "none" } }}
          >
            {confirmText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AlertMessageBox;
