import { Grid, IconButton } from "@mui/material";
import { getMonth, getYear, format } from "date-fns";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
const Header = ({ date, setDate, nextDisabled, prevDisabled, onClickNext, onClickPrevious, locale }) => {
  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item sx={{ padding: "5px" }}>
        <IconButton
          disabled={prevDisabled}
          onClick={onClickPrevious}
          sx={{
            padding: "10px",
            "&:hover": {
              background: "none",
            },
          }}
        >
          <ChevronLeft color={prevDisabled ? "disabled" : "action"} />
        </IconButton>
      </Grid>
      <Grid item sx={{ fontSize: "1.1rem" }}>
        {locale === "CN" ? `${getYear(date)} 年 ${getMonth(date) + 1} 月` : format(date, "MMMM yyyy")}
      </Grid>

      <Grid item sx={{ padding: "5px" }}>
        <IconButton
          disabled={nextDisabled}
          onClick={onClickNext}
          sx={{
            padding: "10px",
            "&:hover": {
              background: "none",
            },
          }}
        >
          <ChevronRight color={nextDisabled ? "none" : "action"} />
        </IconButton>
      </Grid>
    </Grid>
  );
};
export default Header;
