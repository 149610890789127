import styled from "@emotion/styled";
import { Card as MuiCard, CardContent, Grid, Typography } from "@mui/material";

const Card = styled(MuiCard)`
  cursor: pointer;
  color: #616161;
  border: solid 1px #616161;
  padding: 0px;
  min-width: 180px;
  max-width: 230px;
  transition: background-color 0.3s ease-out, max-height 0.3s ease;
  margin: 5px 10px;
  &:hover {
    background-color: #9db8e9;
  }
`;

const StockCard = (props: {
  row: Record<string, any>;
  onClick?: () => void;
  style?: Record<string, any>;
  selected?: boolean;
}) => {
  const { row, onClick, style, selected } = props;
  return (
    <Card onClick={onClick} style={{ ...style, backgroundColor: selected ? "#9db8e9" : "" }}>
      <CardContent sx={{ "&:last-child": { paddingBottom: "16px" } }}>
        <Grid container alignItems="center">
          <Grid item xs={6}>
            <Typography variant="h5" component="h2">
              {row.Category}
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ textAlign: "end" }}>
            <Typography variant="h6" sx={{ color: (theme) => (row.AvailableQty < 0 ? theme.palette.error.dark : "") }}>
              {row.AvailableQty} / {row.StockQty}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default StockCard;
