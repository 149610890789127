import Stepper from "@mui/material/Stepper";
import styled from "@emotion/styled";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Grid from "@mui/material/Grid";

import { useState, useEffect } from "react";
import Div from "../../../components/DivSx";
import { formatDateISO, formatDateTimeShort } from "../../../utils";
import API from "../../../utils/API";
import { StepIconProps } from "@mui/material";

const QontoStepIconRoot = styled("div")<{ ownerState: { active?: boolean } }>(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#784af4",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#fff",
    backgroundColor: theme.palette.secondary.main,
    width: "18px",
    height: "18px",
    borderRadius: "50%",
    zIndex: 1,
    fontSize: 6,
  },
  "& .QontoStepIcon-circle": {
    width: "18px",
    height: "18px",
    borderRadius: "50%",
    backgroundColor: "#e0e0e0",
  },
}));

function QontoStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? <div className="QontoStepIcon-completedIcon" /> : <div className="QontoStepIcon-circle" />}
    </QontoStepIconRoot>
  );
}

const initTracking = [
  {
    Id: 1,
    TrackStatus: "Dispatch",
    TrackTime: "",
  },
  {
    Id: 2,
    TrackStatus: "In Transit",
    TrackTime: "",
  },
  {
    Id: 3,
    TrackStatus: "Out For Delivery",
    TrackTime: "",
  },
  {
    Id: 4,
    TrackStatus: "Delivered",
    TrackTime: "",
  },
];

const PanelTrack = (props: { row: Record<string, any> }) => {
  const { row } = props;
  let orderId = row.OrderId;
  let order_id_Status = row.id_Status;
  const [tracking, setTracking] = useState<Record<string, any>[]>(initTracking);
  const [activeStepIndex, setActiveStepIndex] = useState(0);

  useEffect(() => {
    var t = [...initTracking];
    t[0].TrackTime = row.DispatchTime;
    t[1].TrackTime = row.TransitTime;
    t[2].TrackTime = row.DeliveringTime;
    t[3].TrackTime = row.FinishTime;

    let tempStep = 0;
    if (row.DispatchTime) tempStep = 1;
    if (row.TransitTime) tempStep = 2;
    if (row.DeliveringTime) tempStep = 3;
    if (row.FinishTime) tempStep = 4;
    setActiveStepIndex(tempStep);
  }, [row]);

  return (
    <Grid container sx={{ padding: "15px 0px 0px 0px" }}>
      <Grid
        item
        xs={4}
        sx={{
          //   padding: "0 0px",
          width: "370px",
          overflowY: "auto",
          scrollbarGutter: "stable",
          "::-webkit-scrollbar": {
            width: 10,
            background: "rgba(33,33,33,0.1)",
            borderRadius: "10px",
          },
          "::-webkit-scrollbar-thumb": {
            borderRadius: "10px",
            background: (theme) => theme.palette.secondary.main,
          },
          ":hover": {
            overflowY: "auto",
          },
        }}
      >
        {tracking ? (
          <Stepper
            activeStep={activeStepIndex}
            orientation="horizontal"
            alternativeLabel
            sx={{
              " .MuiStepConnector-root": {
                marginLeft: "8px",
              },
            }}
          >
            {tracking.map((step, index) => (
              <Step key={`tracking_${orderId}_${index}`}>
                <StepLabel StepIconComponent={QontoStepIcon}>
                  {step.TrackStatus}
                  <br />
                  {step.TrackTime ? formatDateTimeShort(step.TrackTime, "m") : ""}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        ) : (
          "failed"
        )}
      </Grid>
    </Grid>
  );
};

export default PanelTrack;
