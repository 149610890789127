import { TableColumn } from "../../../components/table";

export const AVAILABLE_COLUMN_SOH: TableColumn[] = [
  {
    id: "SKU",
    dataIndex: "SKU",
  },
  {
    id: "Barcode",
    dataIndex: "Barcode",
  },
  {
    id: "Brand",
    dataIndex: "Brand",
  },
  {
    id: "NameCn",
    dataIndex: "Name",
    header: "Product",
  },
  {
    id: "IPName",
    dataIndex: "IPName",
    header: "IP",
  },
  {
    id: "Category",
    dataIndex: "Category",
  },
  {
    id: "Scale",
    dataIndex: "Scale",
    dataAlign: "center",
  },
  // {
  //   id: "Quantity",
  //   header: "Quantity",
  //   dataIndex: "StockQty",
  //   dataAlign: "right",
  //   render: (obj, row) => `${row.AvailableQty}/${row.StockQty}`,
  // },
  {
    id: "AvailableQty",
    header: "Available Qty",
    dataIndex: "AvailableQty",
    dataAlign: "right",
  },
  {
    id: "StockQty",
    header: "Total Qty",
    dataIndex: "StockQty",
    dataAlign: "right",
  },
];

export const EXPORT_COLUMN_SOH: TableColumn[] = [
  {
    id: "SKU",
    dataIndex: "SKU",
  },
  {
    id: "Barcode",
    dataIndex: "Barcode",
  },
  {
    id: "NameCn",
    dataIndex: "Name",
    header: "Product",
  },
  {
    id: "IPName",
    dataIndex: "IPName",
    header: "IP",
  },
  {
    id: "Category",
    dataIndex: "Category",
  },
  {
    id: "Scale",
    dataIndex: "Scale",
    dataAlign: "center",
  },
  {
    id: "AvailableQty",
    header: "Quantity Ava.",
    dataIndex: "AvailableQty",
    dataAlign: "right",
  },
  {
    id: "StockQty",
    header: "Quantity Total",
    dataIndex: "StockQty",
    dataAlign: "right",
  },
];

export const DEFAULT_COLUMN_SETTING_SOH: string[] = ["Name", "SKU", "Barcode", "Scale", "Quantity"];
