import React, { useMemo, useState, useCallback, Dispatch, SetStateAction, MouseEvent, MouseEventHandler } from "react";
import styled from "@emotion/styled";
import debounce from "lodash.debounce";
import { darken } from "polished";
import {
  Box,
  Button,
  Grid,
  Checkbox,
  TableContainer,
  Table as MuiTable,
  Collapse,
  Toolbar,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  InputBase,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Menu,
  MenuList,
} from "@mui/material";
import {
  Download as ExportIcon,
  Search as SearchIcon,
  Printer as PrintIcon,
  Trash2 as DeleteIcon,
  Check as CheckIcon,
} from "react-feather";
import moment from "moment";

import LightTooltip from "../../../components/LightTooltip";
import { DateRangePicker } from "../../../components/DateRangePicker";
import PicPreview, { ReactImageGalleryItem } from "../../../components/PicPreview";
import { formatDateISO } from "../../../utils";
import API from "../../../utils/API";
import { TableProps, TableColumn, ColumnConfig, ExportDataConfig } from "../../../components/table";
import TableDetail from "./TableDetailLocalDelivery";
import { useApp } from "../../../contexts/AppContext";
import PreviewDocument from "../../../components/PreviewDocument";

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 100%;
`;

const Search = styled.div`
  border-radius: 4px;
  background-color: ${(props) => props.theme.header.background};
  display: none;
  position: relative;
  width: 200px;
  margin-right: 8px;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;

const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)};
    padding-right: ${(props) => props.theme.spacing(2.5)};
    padding-bottom: ${(props) => props.theme.spacing(2.5)};
    padding-left: ${(props) => props.theme.spacing(12)};
    width: 160px;
  }
`;

const ButtonAction = styled(Button)`
  /* background-color: ${(props) => (props.color === "primary" ? props.theme.palette.primary.light : "")}; */
  margin: 0 2px;
  box-shadow: none;
  min-width: 0;
  padding: 5px;
`;

const printPDF = async (orderId: string[]) => {
  //由于Firefox的CORS政策不允许网页和iframe的域名不同，
  //所以打印功能暂时不支持Firefox和类似Mozilla内核的浏览器。
  //未来也许可以用NextJS等方案解决。
  const response = await API.postraw(`/DeliveryOrder/BatchOrderPrint`, orderId);
  const resBlob = await response.blob();
  var frame = document.getElementById("ff_printme");
  if (frame !== null && frame.parentNode != null) frame.parentNode.removeChild(frame);
  var printFrame = document.createElement("iframe");
  printFrame.id = "ff_printme";
  printFrame.style.visibility = "hidden";
  printFrame.style.position = "fixed";
  printFrame.style.right = "0";
  printFrame.style.bottom = "0";
  printFrame.style.width = "0";
  printFrame.style.height = "0";
  printFrame.src = window.URL.createObjectURL(resBlob);
  printFrame.onload = () => {
    const ff = document.getElementById("ff_printme") as HTMLIFrameElement;
    if (ff != null && ff.contentWindow != null) {
      ff.contentWindow.document.execCommand("print", false) || ff.contentWindow.print();
    }
  };
  document.body.appendChild(printFrame);
};

const printDeliveryNotePDF = async (orderId: string[]) => {
  //由于Firefox的CORS政策不允许网页和iframe的域名不同，
  //所以打印功能暂时不支持Firefox和类似Mozilla内核的浏览器。
  //未来也许可以用NextJS等方案解决。
  const response = await API.postraw(`/DeliveryOrder/BatchDeliveryNotePrint`, orderId);
  const resBlob = await response.blob();
  var frame = document.getElementById("ff_printme");
  if (frame !== null && frame.parentNode != null) frame.parentNode.removeChild(frame);
  var printFrame = document.createElement("iframe");
  printFrame.id = "ff_printme";
  printFrame.style.visibility = "hidden";
  printFrame.style.position = "fixed";
  printFrame.style.right = "0";
  printFrame.style.bottom = "0";
  printFrame.style.width = "0";
  printFrame.style.height = "0";
  printFrame.src = window.URL.createObjectURL(resBlob);
  printFrame.onload = () => {
    const ff = document.getElementById("ff_printme") as HTMLIFrameElement;
    if (ff != null && ff.contentWindow != null) {
      ff.contentWindow.document.execCommand("print", false) || ff.contentWindow.print();
    }
  };
  document.body.appendChild(printFrame);
};

export const getCellContent = (row: Record<string, any>, column: TableColumn) => {
  var obj: any;
  if (column.dataIndex) {
    if (typeof column.dataIndex === "string") {
      obj = row[column.dataIndex];
    } else {
      obj = row;
      for (let i = 0; i < column.dataIndex.length; i++) {
        if (!obj) {
          obj = "";
          break;
        }
        obj = obj[column.dataIndex[i]];
      }
    }
  } else {
    obj = row[column.id];
  }

  if (column.render) {
    return column.render(obj, row);
  } else {
    return obj;
  }
};

let showActionColumnGlobal = true;
let previewDocumentSrcGlobal: string | null = null;

interface TableLDProps extends TableProps {
  setData: Dispatch<SetStateAction<Record<string, any>[]>>;
  params: any;
  setParams: any;
  showActionColumn?: boolean;
}
const TableLocalDelivery: React.FC<TableLDProps> = ({
  data,
  setData,
  columnConfig,
  subTableColumnConfig,
  exportData,
  exportMultipleFormat,
  title,
  loading,
  searchKeywords,
  toolButtons,
  onKeywordsChanged,
  dateRangeChanged,
  params,
  setParams,
  showActionColumn = true,
}) => {
  showActionColumnGlobal = showActionColumn;
  const [exportAnchorEl, setExportAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [openDate, setOpenDate] = useState<boolean>(false);
  const [selecting, setSelecting] = useState<boolean>(false);
  const [printing, setPrinting] = useState<boolean>(false);
  const [selectedOrders, setSelectedOrders] = useState<string[]>([]);
  const [dateRange, setDateRange] = useState<{ startDate: Date | null; endDate: Date | null; label?: string }>({
    startDate: null,
    endDate: null,
    label: "",
  });
  const XLSX = window.XLSX;

  const closeExportMenu = () => {
    setExportAnchorEl(null);
  };

  const [openedRowId, setOpenedRowId] = useState(0);
  const [podList, setPodList] = useState<ReactImageGalleryItem[]>([]);
  const [previewDocumentOpen, setPreviewDocumentOpen] = useState(false);

  const { setMessageBox } = useApp();

  const getDisplayColumn = (columnConfig: ColumnConfig) => {
    let setting =
      columnConfig.columnSetting && columnConfig.columnSetting.length > 0
        ? columnConfig.columnSetting
        : columnConfig.defaultSetting;
    let resColumn = columnConfig.availableColumn.filter((col) => col.id && setting.includes(col.id));

    return resColumn;
  };

  const displayedColumn: TableColumn[] = useMemo(() => getDisplayColumn(columnConfig), [columnConfig.columnSetting]);
  const handleExportClick: MouseEventHandler = (e: MouseEvent<HTMLButtonElement>) => {
    if (exportData) {
      ExportXLSX(exportData, Boolean(subTableColumnConfig), title || "");
    } else if (exportMultipleFormat) {
      setExportAnchorEl(e.currentTarget);
    }
  };

  const handleExportMenuClick = (expConf: ExportDataConfig) => () => {
    ExportXLSX(expConf.getData, expConf.includeSubTableColumn, title + " " + expConf.menuTitle);
    setExportAnchorEl(null);
  };

  const ExportXLSX = (
    getData: () => Promise<Record<string, any>[]>,
    includeSubTableColumn: boolean,
    filename: string
  ) => {
    if (!getData) {
      return;
    }
    getData()
      .then((dataList) => {
        const xlsxData: string[][] = [];

        const doIncludeSubTable = includeSubTableColumn && subTableColumnConfig;

        // sheet column headers
        var xlsxRow: string[] = [];
        columnConfig.availableColumn.forEach((column) => {
          let xlsxHeader = typeof column.header === "string" ? column.header : column.id;
          xlsxRow.push(xlsxHeader);
        });
        if (doIncludeSubTable) {
          subTableColumnConfig.availableColumn.forEach((column) => {
            let xlsxHeader = typeof column.header === "string" ? column.header : column.id;
            xlsxRow.push(xlsxHeader);
          });
        }
        xlsxData.push(xlsxRow);

        // sheet content
        dataList.forEach((obj) => {
          const xlsxRow: string[] = [];
          columnConfig.availableColumn.forEach((column) => {
            let cellContent = column.exportFn ? column.exportFn(obj) : getCellContent(obj, column);
            xlsxRow.push(cellContent);
          });

          if (doIncludeSubTable) {
            (obj[subTableColumnConfig.subDataIndex] as Record<string, any>[]).forEach((Detailobj) => {
              let xlsxDetailRow = [...xlsxRow];
              subTableColumnConfig.availableColumn.forEach((column) => {
                let cellContent = column.exportFn ? column.exportFn(Detailobj) : getCellContent(Detailobj, column);
                xlsxDetailRow.push(cellContent);
              });
              xlsxData.push(xlsxDetailRow);
            });
          }

          if (!doIncludeSubTable) {
            xlsxData.push(xlsxRow);
          }
        });
        var worksheet = XLSX.utils.aoa_to_sheet(xlsxData);
        var new_workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(new_workbook, worksheet, "sheet1");
        XLSX.writeFile(new_workbook, filename + " " + moment().format("YYYYMMDD") + ".xlsx");
      })
      .catch((e) => {
        alert("An error has occured.");
      });
  };

  const debouncedChangeHandler = useCallback(
    debounce((e) => {
      onKeywordsChanged && onKeywordsChanged(e.target.value);
    }, 300),
    [onKeywordsChanged]
  );

  return (
    <Box p={2}>
      {(exportData || exportMultipleFormat || columnConfig.handleColumnSettingChanged) && (
        <Toolbar>
          <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
            <Search>
              <SearchIconWrapper>
                <SearchIcon style={{ color: "#9e9e9e" }} />
              </SearchIconWrapper>
              <Input placeholder={searchKeywords?.join(", ")} onChange={debouncedChangeHandler} />
            </Search>
          </div>

          {dateRangeChanged && (
            <div>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => setOpenDate(true)}
                sx={{ minWidth: "210px", margin: 2 }}
              >
                {dateRange && dateRange.label ? (
                  dateRange.label
                ) : dateRange.startDate && dateRange.endDate ? (
                  <Grid container justifyContent="space-between">
                    <Grid item>{formatDateISO(dateRange.startDate)}</Grid>
                    <Grid item>to</Grid>
                    <Grid item>{formatDateISO(dateRange.endDate)}</Grid>
                  </Grid>
                ) : (
                  "All Dates"
                )}
              </Button>
              <DateRangePicker
                open={openDate}
                toggle={() => setOpenDate(!openDate)}
                onChange={(range) => {
                  dateRangeChanged(range);
                  setDateRange(range);
                  setOpenDate(false);
                }}
              />
            </div>
          )}

          {toolButtons &&
            toolButtons.map((button, index) => (
              <Button
                key={`button_${button.label.replace(/\s/, "")}_${index}}`}
                sx={{ minWidth: "130px", mx: 2 }}
                variant="contained"
                startIcon={button.icon}
                onClick={button.onClick}
              >
                {button.label}
              </Button>
            ))}

          <Spacer />

          {(exportData || exportMultipleFormat) && (
            <React.Fragment>
              <LightTooltip title="Export" placement="top">
                <IconButton onClick={handleExportClick}>
                  <ExportIcon />
                </IconButton>
              </LightTooltip>
              <Menu anchorEl={exportAnchorEl} open={Boolean(exportAnchorEl)} onClose={closeExportMenu}>
                <MenuList>
                  {exportMultipleFormat &&
                    exportMultipleFormat.map((expConfig) => (
                      <MenuItem key={expConfig.menuTitle} onClick={handleExportMenuClick(expConfig)}>
                        {expConfig.menuTitle}
                      </MenuItem>
                    ))}
                </MenuList>
              </Menu>
            </React.Fragment>
          )}

          <Spacer />

          {selecting ? (
            <>
              <Button
                variant="contained"
                disabled={selectedOrders.length === 0 || printing}
                startIcon={<PrintIcon />}
                sx={{ minWidth: "130px", marginRight: 1 }}
                onClick={async () => {
                  // if (selectedOrders.length >= 10) {
                  setMessageBox({
                    msg: `Confirm (if not confirmed) and print ${selectedOrders.length} orders. You cannot change them any more. It could take a long time if too many orders were selected. Continue?`,
                    title: "Warning",
                    open: true,
                    confirmText: "Yes",
                    cancelText: "No",
                    onConfirm: async () => {
                      setPrinting(true);
                      await printPDF(selectedOrders.sort((a, b) => a.localeCompare(b)));
                      setPrinting(false);
                      setSelecting(false);
                      setSelectedOrders([]);
                    },
                  });
                  // } else {
                  //   setPrinting(true);
                  //   await printPDF(selectedOrders.sort((a, b) => a.localeCompare(b)));
                  //   setPrinting(false);
                  //   setSelecting(false);
                  //   setSelectedOrders([]);
                  // }
                }}
              >
                {`Print (${selectedOrders.length})`}
              </Button>
              <Button
                variant="outlined"
                disabled={printing}
                onClick={() => {
                  setSelecting(false);
                  setSelectedOrders([]);
                }}
              >
                Cancel
              </Button>
            </>
          ) : (
            <Button
              variant="contained"
              startIcon={<PrintIcon />}
              sx={{ minWidth: "190px" }}
              onClick={() => {
                setSelecting(true);
                setOpenedRowId(0);
              }}
            >
              Confirm/Print batch
            </Button>
          )}
        </Toolbar>
      )}
      {loading ? (
        <LoaderContainer>
          <CircularProgress />
        </LoaderContainer>
      ) : (
        <TableContainer>
          <MuiTable>
            <TableHead>
              <TableRow>
                {displayedColumn.map((column) => (
                  <TableCell key={column.id} align={column.dataAlign}>
                    {column.header || column.dataIndex}
                  </TableCell>
                ))}

                {showActionColumn && (
                  <TableCell>
                    {selecting ? (
                      <Checkbox
                        color="primary"
                        sx={{ padding: 0, "& .MuiSvgIcon-root": { fontSize: "1.625rem" } }}
                        checked={data.filter((o) => selectedOrders.includes(o.OrderId)).length === data.length}
                        disabled={printing}
                        onClick={(e) => {
                          const dataThisPage = data.map((o) => o.OrderId);
                          if (data.filter((o) => selectedOrders.includes(o.OrderId)).length === data.length) {
                            setSelectedOrders((orders) => orders.filter((o) => !dataThisPage.includes(o)));
                          } else {
                            const unSelected = dataThisPage.filter((o) => !selectedOrders.includes(o));
                            setSelectedOrders((orders) => [...orders, ...unSelected]);
                          }
                        }}
                      />
                    ) : (
                      "Action"
                    )}
                  </TableCell>
                )}
              </TableRow>
            </TableHead>

            <TableBody>
              {data.map((row, index) => (
                <TableRowLocalDelivery
                  key={`row_localdelivery_${row.id}`}
                  row={row}
                  displayedColumn={displayedColumn}
                  openedRowId={openedRowId}
                  setOpenedRowId={setOpenedRowId}
                  setData={setData}
                  selecting={selecting}
                  selected={selectedOrders.includes(row.OrderId)}
                  setSelectedOrders={setSelectedOrders}
                  printing={printing}
                  setPodList={setPodList}
                  setPreviewDocumentOpen={setPreviewDocumentOpen}
                />
              ))}
            </TableBody>
          </MuiTable>
        </TableContainer>
      )}
      {/* <PicPreview pics={podList} open={podList.length > 0} onClose={() => setPodList([])} /> */}
      <PreviewDocument
        src={previewDocumentSrcGlobal}
        open={previewDocumentOpen}
        onClose={() => {
          setPreviewDocumentOpen(false);
          previewDocumentSrcGlobal = null;
        }}
        buttons={[
          {
            text: "Close",
            onClick: () => {
              setPreviewDocumentOpen(false);
              previewDocumentSrcGlobal = null;
            },
          },
        ]}
      />
    </Box>
  );
};

const TableRowLocalDelivery = ({
  row,
  setData,
  displayedColumn,
  openedRowId,
  setOpenedRowId,
  setPodList,
  selecting,
  selected,
  setSelectedOrders,
  printing,
  setPreviewDocumentOpen,
}: {
  row: Record<string, any>;
  setData: Dispatch<SetStateAction<Record<string, any>[]>>;
  displayedColumn: TableColumn[];
  openedRowId: number;
  setOpenedRowId: Dispatch<SetStateAction<number>>;
  setPodList: Dispatch<SetStateAction<ReactImageGalleryItem[]>>;
  selecting: boolean;
  selected: boolean;
  setSelectedOrders: Dispatch<SetStateAction<string[]>>;
  printing: boolean;
  setPreviewDocumentOpen: any;
}) => {
  const { setMessageBox } = useApp();

  const clickHandler = () => {
    if (selecting) {
      setSelectedOrders((orders) => (selected ? orders.filter((o) => o !== row.OrderId) : [...orders, row.OrderId]));
    } else {
      setOpenedRowId(openedRowId === row.id ? 0 : row.id);
    }
  };

  return (
    <>
      <TableRow hover onClick={clickHandler}>
        {displayedColumn.map((column) => (
          <TableCell key={column.id} align={column.dataAlign} sx={{ ...column.style }}>
            {getCellContent(row, column)}
          </TableCell>
        ))}

        {showActionColumnGlobal && (
          <TableCell sx={{ minWidth: 126 }}>
            {selecting ? (
              <Checkbox
                color="primary"
                sx={{ padding: 0, "& .MuiSvgIcon-root": { fontSize: "1.625rem" } }}
                checked={selected}
                disabled={printing}
              />
            ) : (
              <>
                {row.id_Status !== 2 && ( //Need Review status cannot print
                  <LightTooltip title="Confirm Order and Print Label">
                    <ButtonAction
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={async (ev) => {
                        ev.stopPropagation();
                        if (row.Status.toLowerCase() === "initialized") {
                          setMessageBox({
                            msg: `Confirm and print ${row.OrderId}. You cannot change it any more. Continue?`,
                            title: "Warning",
                            open: true,
                            confirmText: "Yes",
                            cancelText: "No",
                            onConfirm: async () => {
                              await printPDF([row.OrderId]);
                            },
                          });
                        } else {
                          await printPDF([row.OrderId]);
                        }
                      }}
                    >
                      <PrintIcon size={16} />
                    </ButtonAction>
                  </LightTooltip>
                )}

                {row.id_Status >= 10 && ( // can only print after Confirmed
                  <LightTooltip title="Print Pickup Note">
                    <ButtonAction
                      variant="contained"
                      color="success"
                      size="small"
                      onClick={async (ev) => {
                        ev.stopPropagation();
                        await printDeliveryNotePDF([row.OrderId]);
                      }}
                    >
                      <PrintIcon size={16} />
                    </ButtonAction>
                  </LightTooltip>
                )}

                {(row.id_Status === 1 || row.id_Status === 2) && ( //Initialized & Need Review statuses are able to cancel
                  <LightTooltip title="Delete">
                    <ButtonAction
                      variant="contained"
                      color="error"
                      size="small"
                      onClick={(ev) => {
                        ev.stopPropagation();
                        setMessageBox({
                          msg: `Delete ${row.OrderId}?`,
                          title: "Warning",
                          open: true,
                          onConfirm: () => {
                            API.delete(`/DeliveryOrder/CancelOrder?OrderId=${row.OrderId}`).then((res) => {
                              if (res.data && res.data.Code === 0) {
                                setData((data) => data.filter((e) => e.id !== row.id));
                              } else {
                                // TODO Error handling
                              }
                            });
                          },
                          confirmText: "Yes",
                          cancelText: "No",
                        });
                      }}
                    >
                      <DeleteIcon size={16} />
                    </ButtonAction>
                  </LightTooltip>
                )}
                {row.PODList.length > 0 && (
                  <LightTooltip title="Proof Of Delivery">
                    <ButtonAction
                      variant="contained"
                      color="primary"
                      size="small"
                      sx={{ height: 26, width: 30 }}
                      onClick={async (ev) => {
                        ev.stopPropagation();
                        setPodList(
                          row.PODList.map((e: any) => ({
                            original: e.PODSignedUrl,
                            thumbnail: e.PODThumbSignedUrl,
                            originalWidth: 100,
                          }))
                        );
                        setPreviewDocumentOpen(true);
                        previewDocumentSrcGlobal = row.PODList[0].PODSignedUrl;
                      }}
                    >
                      POD
                    </ButtonAction>
                  </LightTooltip>
                )}
              </>
            )}
          </TableCell>
        )}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={displayedColumn.length + 1}>
          <Collapse in={openedRowId === row.id} timeout="auto" mountOnEnter unmountOnExit>
            {/* <TableDetail row={row} setPodList={setPodList} /> */}
            <TableDetail row={row} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default TableLocalDelivery;
