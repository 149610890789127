import { MouseEvent, ReactNode } from "react";
import { DateRangeSelectedRange } from "../DateRangePicker";

import Table from "./Table";

console.log("inside components/table/index.ts");

export type TableColumn = {
  id: string;
  dataIndex?: TableColumnDataIndex;
  header?: Element | string;
  render?: (obj: any, row: Record<string, any>) => JSX.Element | string;
  exportFn?: (obj: any, row?: Record<string, any>) => string;
  dataAlign?: "inherit" | "left" | "center" | "right" | "justify" | undefined;
  width?: string;
  style?: Record<string, string | number>;
};

export type TableColumnDataIndex = string | string[];

export type TableRowExpand = {
  subDataIndex: string;
  subTableColumn: TableColumn[];
};

export type TableExpandProps = {
  row: Record<string, any>;
};

export type ColumnConfig = {
  availableColumn: TableColumn[];
  postFixedColumn?: TableColumn[];
  columnSetting?: string[];
  defaultSetting: TableColumnDataIndex[];
  handleColumnSettingChanged?: (setting: string[]) => void;
};

export type ExportDataConfig = {
  menuTitle: string;
  getData: () => Promise<Record<string, any>[]>;
  includeSubTableColumn: boolean;
};

export type TableToolButton = {
  label: string;
  icon?: ReactNode;
  onClick: (event: MouseEvent<HTMLElement>) => void;
};

export type TableProps = {
  data: Record<string, any>[];
  columnConfig: ColumnConfig;
  subTableColumnConfig?: ColumnConfig & { subDataIndex: string };
  exportData?: () => Promise<Record<string, any>[]>;
  exportMultipleFormat?: ExportDataConfig[];
  title?: string;
  loading?: boolean;
  expand?: React.FC<TableExpandProps>;
  size?: "small" | "medium";
  hover?: boolean;
  dateRangeChanged?: (range: DateRangeSelectedRange) => void;
  searchKeywords?: string[];
  onKeywordsChanged?: (keyword: string) => void;
  toolButtons?: TableToolButton[];
};

export type QueryConditionField =
  | {
      id: string;
      label: string;
      type: "text" | "date" | "checkbox" | "daterange";
    }
  | {
      id: string;
      label: string;
      type: "selection" | "ratio";
      selection: { label: string; value: string }[];
    };

export type QueryParams = {
  PageSize: string;
  PageNum: string;
  keyword?: string;
  StartDate?: string;
  EndDate?: string;
  id_Status?: string;
};

export interface QueryParamsASN extends QueryParams {
  ASNNo?: string;
}
export interface QueryParamsSOH extends QueryParams {
  InStockOnly?: boolean;
  basicProduct: boolean;
}

export default Table;
