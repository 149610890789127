import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import { Grid, Divider as MuiDivider, Typography as MuiTypography } from "@mui/material";
import { spacing } from "@mui/system";
import { green, red } from "@mui/material/colors";

import DoughnutChart from "./DoughnutChart";
import Stats from "./Stats";
import { BoundAmt, MonthlyOutboundByType, ProductState, TopProduct } from "./types";

import BarChart from "./BarChart";
import BarChart2 from "./BarChart2";
import API from "../../../utils/API";
import DoughnutChart4States from "./DoughnutChart4States";

const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);

function Dashboard() {
  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState<Record<string, any>>({});
  const [monthlyOutboundByType, setMonthlyOutboundByType] = useState<MonthlyOutboundByType[]>([]);
  const [inboundAmt, setInboundAmt] = useState<BoundAmt[]>([]);
  const [outboundAmt, setOutboundAmt] = useState<BoundAmt[]>([]);

  const [outboundTopProduct, setOutboundTopProduct] = useState<TopProduct[]>([]); // one month
  const [outboundTopProduct12M, setOutboundTopProduct12M] = useState<TopProduct[]>([]);

  const [outbountProductState, setOutbountProductState] = useState<ProductState[]>([]); // one month
  const [outbountProductState12M, setOutbountProductState12M] = useState<ProductState[]>([]);

  const getStat = () => {
    setLoading(true);
    API.get("/Statistics/CustomerDashboard", null).then((res) => {
      setLoading(false);
      if (res && res.data && res.data.Code === 0) {
        setMonthlyOutboundByType(res.data.MonthlyOutboundByType);
        setStats(res.data);
        setInboundAmt(res.data.MonthlyInboundAmt);
        setOutboundAmt(res.data.MonthlyOutboundAmt);
        setOutboundTopProduct(res.data.OutboundTopProduct);
        setOutboundTopProduct12M(res.data.OutboundTopProduct12M);
        setOutbountProductState(res.data.OutbountProductState);
        setOutbountProductState12M(res.data.OutbountProductState12M);
      }
    });
  };

  useEffect(getStat, []);

  return (
    <React.Fragment>
      <Helmet title="Dashboard" />
      <Grid justifyContent="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h3" gutterBottom>
            Dashboard
          </Typography>
        </Grid>

        <Grid item></Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="Inbound Orders"
            amount={stats.PendingInboundThisMonth?.toString()}
            chip="This Month"
            percentagetext="Handling"
            percentagecolor={red[500]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="Inbound Orders"
            amount={stats.CompletedInboundThisMonth?.toString()}
            chip="This Month"
            percentagetext="Completed"
            percentagecolor={green[500]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="Outbound Orders"
            amount={stats.PendingOutboundThisMonth?.toString()}
            chip="This Month"
            percentagetext="Handling"
            percentagecolor={red[500]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="Outbound Orders"
            amount={stats.CompletedOutboundThisMonth?.toString()}
            chip="This Month"
            percentagetext="Completed"
            percentagecolor={green[500]}
          />
        </Grid>
      </Grid>

      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} alignSelf="stretch">
          <BarChart outboundData={monthlyOutboundByType} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DoughnutChart
            title="Outbound Top Products"
            _1M={outboundTopProduct}
            _12M={outboundTopProduct12M}
            loading={loading}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DoughnutChart4States
            title="Outbound Product States"
            _1M={outbountProductState}
            _12M={outbountProductState12M}
            loading={loading}
          />
        </Grid>
        <Grid item xs={12} sm={6} alignSelf="stretch">
          <BarChart2 title="ASN Piece/Set/Carton" boundData={inboundAmt} />
        </Grid>
        <Grid item xs={12} sm={6} alignSelf="stretch">
          <BarChart2 title="Sales Order Piece/Set/Carton" boundData={outboundAmt} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Dashboard;
