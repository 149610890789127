import { ReactNode, useMemo } from "react";
import React from "react";
import useAuth from "../hooks/useAuth";
import axiosInstance from "../utils/axios";

export const WithAxios = ({ children }: { children: React.ReactNode }) => {
  const { signOut } = useAuth();

  useMemo(() => {
    axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          signOut();
          return Promise.reject("Login expired");
        }

        return Promise.reject(
          (error.response && error.response.data && error.response.data.Message) || "Something went wrong"
        );
      }
    );
  }, []); // Ignore the warning. Don't add signOut here. Otherwise interceptors would by added multiple times.

  return <React.Fragment>{children}</React.Fragment>;
};
