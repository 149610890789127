export const formatDateISO = (date: string | Date | null | undefined, pre: string = "D"): string => {
  if (date === null || date === undefined || date === "") date = new Date();
  else if (typeof date === "string") date = new Date(date);
  else if (date.getFullYear() === 1) return "";
  let ret =
    date.getFullYear() + "-" + ("00" + (date.getMonth() + 1)).slice(-2) + "-" + ("00" + date.getDate()).slice(-2);

  if (pre === "m") {
    ret += " " + ("00" + date.getHours()).slice(-2) + ":" + ("00" + date.getMinutes()).slice(-2);
  }

  if (pre === "s") {
    ret +=
      " " +
      ("00" + date.getHours()).slice(-2) +
      ":" +
      ("00" + date.getMinutes()).slice(-2) +
      ":" +
      ("00" + date.getSeconds()).slice(-2);
  }

  return ret;
};

const MONTHS = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const formatDateTimeShort = (date: string | Date | null | undefined, pre: string = "D"): string => {
  if (date === null || date === undefined || date === "") date = new Date();
  else if (typeof date === "string") date = new Date(date);
  else if (date.getFullYear() === 1) return "";
  let ret = +("00" + date.getDate()).slice(-2) + " " + MONTHS[date.getMonth()];

  if (pre === "m") {
    ret += " " + ("00" + date.getHours()).slice(-2) + ":" + ("00" + date.getMinutes()).slice(-2);
  }

  if (pre === "s") {
    ret +=
      " " +
      ("00" + date.getHours()).slice(-2) +
      ":" +
      ("00" + date.getMinutes()).slice(-2) +
      ":" +
      ("00" + date.getSeconds()).slice(-2);
  }

  return ret;
};

export const formatCurrency = (amount: string, decimalCount = 2, decimal = ".", thousands = ",") => {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = Number(amount) < 0 ? "-" : "";

    let i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substring(0, j) + thousands : "") +
      i.substring(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(Number(amount) - Number(i))
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch (e) {
    console.error(e);
  }
};
