import React, { useEffect, useState } from "react";
import moment from "moment";
import { Helmet } from "react-helmet-async";
import styled from "@emotion/styled";
import { styled as styled2, alpha } from "@mui/material/styles";
import { spacing } from "@mui/system";
import {
  Divider as MuiDivider,
  Grid,
  Paper as MuiPaper,
  TablePagination,
  Typography,
  MenuItem,
  MenuProps,
  Menu,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Upload as UploadIcon, Plus as AddIcon } from "react-feather";
import axiosInstance from "../../../utils/axios";
import useAuth from "../../../hooks/useAuth";
import Table, { TableExpandProps } from "../../../components/table";
import { DateRangeSelectedRange } from "../../../components/DateRangePicker";
import {
  AVAILABLE_COLUMN_OUT_REQ,
  DEFAULT_COLUMN_SETTING_OUT_REQ,
  AVAILABLE_SUBTABLE_COLUMN_OUT_REQ,
  DEFAULT_SUBTABLE_COLUMN_SETTING_OUT_REQ,
} from "./tableColumn";
import ModalUpload from "./ModalUpload";
import ModalCreate from "./ModalCreate";
import { QueryParams } from "../../../components/table";
import { formatDateISO } from "../../../utils";
import TableSalesOrder from "./TableSalesOrder";
import { SOType, StatusObj } from "../../../types/types";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 135,
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

const Expand: React.FC<TableExpandProps> = ({ row }) => {
  const { uiSetting } = useAuth();

  return (
    <Table
      data={row.Details}
      size="small"
      columnConfig={{
        availableColumn: AVAILABLE_SUBTABLE_COLUMN_OUT_REQ,
        defaultSetting: DEFAULT_SUBTABLE_COLUMN_SETTING_OUT_REQ,
        columnSetting: uiSetting.columnOutReqDetails,
      }}
    ></Table>
  );
};

function OutboundRequest() {
  const { uiSetting, setUISetting } = useAuth();
  const [dataList, setDataList] = useState<Record<string, any>[]>([]);
  const [totalCnt, setTotalCnt] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [uploadOpen, setUploadOpen] = useState<boolean>(false);
  const [createOpen, setCreateOpen] = useState(false);
  const [params, setParams] = useState<QueryParams>({
    PageSize: "20",
    PageNum: "1",
  });
  const [SOTypes, setSOTypes] = useState<SOType[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClickTypes = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseTypes = (ev: any, typeId?: number | null) => {
    setAnchorEl(null);
    if (typeId === null) return;
    setParams((param) => {
      return {
        ...param,
        PageNum: "",
        id_Type: typeId,
      };
    });
  };

  const [statusList, setStatusList] = useState<StatusObj[]>([]);
  const [anchorElStatus, setAnchorElStatus] = React.useState<null | HTMLElement>(null);
  const openStatus = Boolean(anchorElStatus);
  const handleClickStatus = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElStatus(event.currentTarget);
  };
  const handleCloseStatus = (e: any, statusId: number | null) => {
    setAnchorElStatus(null);
    if (statusId === null) return;
    setParams((param) => {
      return {
        ...param,
        PageNum: "",
        id_Status: statusId.toString(),
      };
    });
  };

  const getDataList = () => {
    setLoading(true);
    axiosInstance
      .post("/SalesOrder/QuerySalesOrder", params)
      .then((res) => {
        setDataList(res.data.SalesOrderList);
        setTotalCnt(res.data.Total);
      })
      .finally(() => setLoading(false));
  };

  const exportData = (includeDetails: boolean) =>
    axiosInstance
      .post("/SalesOrder/QuerySalesOrder", {
        ...params,
        IncludeDetails: includeDetails,
        PageSize: 100000,
        PageNum: 1,
      })
      .then((res) => {
        return res.data.SalesOrderList as Record<string, any>[];
      });

  const dateRangeChanged = (range: DateRangeSelectedRange) => {
    if (range.endDate && range.startDate) {
      setParams((params) => {
        return {
          ...params,
          PageNum: "",
          StartDate: formatDateISO(range.startDate),
          EndDate: formatDateISO(moment(range.endDate).add(1, "day").toDate()),
        };
      });
    } else {
      setParams((params) => {
        return {
          ...params,
          PageNum: "",
          StartDate: undefined,
          EndDate: undefined,
        };
      });
    }
  };

  useEffect(() => {
    axiosInstance.get("/SalesOrder/QuerySalesOrderType").then((res) => {
      setSOTypes(res.data.TypeList);
    });
    axiosInstance.get("/Status/Query?statusType=2").then((res) => {
      setStatusList(res.data.StatusList);
    });
  }, []);

  useEffect(getDataList, [params]);

  return (
    <React.Fragment>
      <Helmet title="Sales Order Mng" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Sales Order Mng
          </Typography>
        </Grid>
        <Grid item>
          <div></div>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item sm={12}>
          <Paper>
            <TableSalesOrder
              hover
              data={dataList}
              setData={setDataList}
              columnConfig={{
                availableColumn: AVAILABLE_COLUMN_OUT_REQ,
                columnSetting: uiSetting.columnOutReq,
                defaultSetting: DEFAULT_COLUMN_SETTING_OUT_REQ,
                handleColumnSettingChanged: (setting) => {
                  setUISetting({ columnOutReq: setting });
                },
              }}
              subTableColumnConfig={{
                availableColumn: AVAILABLE_SUBTABLE_COLUMN_OUT_REQ,
                columnSetting: uiSetting.columnOutReqDetails,
                defaultSetting: DEFAULT_SUBTABLE_COLUMN_SETTING_OUT_REQ,
                handleColumnSettingChanged: (setting) => {
                  setUISetting({ columnOutReqDetails: setting });
                },
                subDataIndex: "Details",
              }}
              title="Sales Order Mng"
              loading={loading}
              exportMultipleFormat={[
                {
                  menuTitle: "Header",
                  getData: () => exportData(false),
                  includeSubTableColumn: false,
                },
                {
                  menuTitle: "Items",
                  getData: () => exportData(true),
                  includeSubTableColumn: true,
                },
              ]}
              expand={Expand}
              dateRangeChanged={dateRangeChanged}
              searchKeywords={["Sale", "Shipment Order"]}
              onKeywordsChanged={(keyword) => {
                keyword = keyword.trim();
                if (keyword.length > 0) {
                  setParams((param) => {
                    return {
                      ...param,
                      PageNum: "",
                      keyword,
                    };
                  });
                }
              }}
              toolButtons={[
                {
                  label: "Status",
                  icon: <KeyboardArrowDownIcon />,
                  onClick: handleClickStatus,
                },
                {
                  label: "Type",
                  icon: <KeyboardArrowDownIcon />,
                  onClick: handleClickTypes,
                },
                {
                  label: "Upload",
                  icon: <UploadIcon />,
                  onClick: (ev) => setUploadOpen(true),
                },
                {
                  label: "Sales Order",
                  icon: <AddIcon />,
                  onClick: (ev) => setCreateOpen(true),
                },
              ]}
            />
            <TablePagination
              rowsPerPageOptions={[20, 50, 100, 250]}
              component="div"
              count={totalCnt}
              rowsPerPage={parseInt(params["PageSize"] || "10")}
              page={parseInt(params["PageNum"] || "1") - 1}
              onPageChange={(event, page) =>
                setParams((param) => {
                  return {
                    ...param,
                    PageNum: (page + 1).toString(),
                  };
                })
              }
              onRowsPerPageChange={(event) =>
                setParams((param) => {
                  return {
                    ...param,
                    PageSize: event.target.value,
                    PageNum: "1",
                  };
                })
              }
            />
          </Paper>
        </Grid>
      </Grid>
      <ModalUpload
        open={uploadOpen}
        onClose={(refresh: boolean = false) => {
          setUploadOpen(false);
          if (refresh) getDataList();
        }}
      />
      <ModalCreate
        open={createOpen}
        onClose={(refresh: boolean = false) => {
          setCreateOpen(false);
          if (refresh) getDataList();
        }}
      />
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={(e) => handleCloseTypes(e, null)}
      >
        <MenuItem onClick={(e) => handleCloseTypes(e, 0)} key={0} disableRipple>
          All
        </MenuItem>
        {SOTypes.map((item) => {
          return (
            <MenuItem onClick={(e) => handleCloseTypes(e, item.id)} key={item.id} disableRipple>
              {item.SOType}
            </MenuItem>
          );
        })}
      </StyledMenu>

      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorElStatus}
        open={openStatus}
        onClose={(e) => handleCloseStatus(e, null)}
      >
        <MenuItem onClick={(e) => handleCloseStatus(e, 0)} key={0}>
          All
        </MenuItem>
        {statusList.map((item) => {
          return (
            <MenuItem onClick={(e) => handleCloseStatus(e, item.id)} key={item.id}>
              {item.Status}
            </MenuItem>
          );
        })}
      </StyledMenu>
    </React.Fragment>
  );
}

export default OutboundRequest;
